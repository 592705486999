import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import Banner from 'layouts/Site/Layouts/Banner'
import Grid from 'components/Grid/Grid'
import {
  clearStateCampaign,
  publicScrollPaginationCampaignRequest,
  loadSearchPaginationCampaignRequest
} from './campaignPagination.redux'
import ScrollGallery from 'components/ScrollGallery/ScrollGallery'
import Caption from 'components/Grid/Caption'
import { Link } from 'react-router-dom'

export const CampaingsGallery = props => {
  const { listCampaign, search, loading } = props

  useEffect(() => {
    if (search === true && listCampaign.length >= 1) {
      props.clearStateCampaign()
      props.publicScrollPaginationCampaignRequest()
    }
    if (search === false) {
      props.clearStateCampaign()
      props.publicScrollPaginationCampaignRequest()
    }
  }, [])

  return (
    <Banner admin={false}>
      <section className='ftco-section'>
        {listCampaign.length === 0 && loading === false ? (
          <div className='alert-empty'>
            <div className='alert grid-alert' role='alert'>
              <h4 className='alert-heading'>No se encontraron resultados</h4>
              <p className='mb-0'>
                Te invitamos a ver nuestras{' '}
                <Link
                  style={{ fontWeight: 'bold', textDecoration: 'underline' }}
                  to='/campaigns#content'
                  onClick={e => {
                    e.preventDefault()
                    props.publicScrollPaginationCampaignRequest()
                    document.getElementById('input1').value = ''
                  }}>
                  campañas
                </Link>
              </p>
            </div>
          </div>
        ) : (
          <ScrollGallery data={listCampaign} component={Caption} wrapper={Grid} />
        )}
      </section>
    </Banner>
  )
}

const mapStateToProps = ({ campaignPagination }) => ({
  listCampaign: campaignPagination.listCampaign,
  search: campaignPagination.search,
  loading: campaignPagination.loading
})

const mapDispatchToProps = {
  clearStateCampaign,
  publicScrollPaginationCampaignRequest,
  loadSearchPaginationCampaignRequest
}

export default connect(mapStateToProps, mapDispatchToProps)(CampaingsGallery)
