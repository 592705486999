import React, { Component } from 'react'
import { connect } from 'react-redux'
import DataTable from 'components/Table/Table'
import Banner from 'layouts/Site/Layouts/Banner'
import TableContainer from 'components/Container/TableContainer'
import { columns, columnsToExport } from './pages.helpers'
import { pagesActions } from './pages.redux'

class PagesList extends Component {
  componentDidMount() {
    this.props.loadAllPages()
  }

  render() {
    const rowEvents = {
      onClick: (e, row) => {
        const url = `/dashboard/pages/edit/${row._id}`
        this.props.history.push(url)
      }
    }

    return (
      <Banner>
        <TableContainer
          showButtons={false}
          title='Páginas'
          subtitle='Lista'
          route='/dashboard/pages/create'>
          <DataTable
            rowEvents={rowEvents}
            data={this.props.pagess}
            structure={columns}
            tableName='Páginas'
            columnsToExport={columnsToExport}
            canDownload={false}
          />
        </TableContainer>
      </Banner>
    )
  }
}

const mapStateToProps = ({ pages }) => ({ pagess: pages.list })
const mapDispatchToProps = {
  loadAllPages: pagesActions.loadAllPagesRequest,
  togglePages: pagesActions.togglePagesRequest,
  loadPagesOptions: pagesActions.loadPagesOptionsRequest
}

export default connect(mapStateToProps, mapDispatchToProps)(PagesList)
