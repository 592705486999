import React from 'react'
import FormWrapper from '../FormWrapper'
import { Button, Row } from 'reactstrap'
import { reduxForm } from 'redux-form'
import { RFInput } from 'components/Inputs/RFFields'
import { substring } from 'util/form'

const limit40 = substring(0, 40)
const limit200 = substring(0, 200)

function FormInstitution({ handleSubmit, previousPage, label }) {
  return (
    <FormWrapper title={label}>
      <Row style={{ minHeight: 287 }}>
        <RFInput
          name='institution.name'
          label='Nombre de la institución'
          normalize={limit40}
        />
        <RFInput
          type='textarea'
          name='institution.description'
          label='Descripción de la institución'
          normalize={limit200}
        />
      </Row>
      <Button color='secondary' id='prev-button' onClick={previousPage}>
        Atrás
      </Button>
      <Button color='primary' id='next-button' onClick={handleSubmit}>
        Guardar
      </Button>
    </FormWrapper>
  )
}

export default reduxForm({
  form: 'initial-configuration',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true
})(FormInstitution)
