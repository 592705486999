import { generateActiveIcon } from 'components/Table/helpers'
import { mdy } from './../../util/dates'

export const columns = [
  { dataField: 'title', text: 'Título', sort: true },
  { dataField: 'updatedAt', text: 'Última modificación', sort: true, formatter: mdy },
  { dataField: 'createdAt', text: 'Creado', sort: true, formatter: mdy },
  {
    dataField: 'active',
    text: 'Activo',
    formatter: cell => generateActiveIcon(cell),
    sort: true
  }
]

export const columnsToExport = [
  { title: 'Título', value: 'title' },
  { title: 'Descripción', value: 'description' },
  { title: 'Última modificación', value: row => mdy(row.updatedAt) },
  { title: 'Creado', value: row => mdy(row.createdAt) },
  { title: 'Estatus', value: row => (row.active ? 'Activo' : 'No activo') }
]
