import { createActions, createReducer } from 'reduxsauce'
import * as handlers from './../../redux/helpers/handlers'

const { Types, Creators } = createActions(
  {
    loadAllCategoryRequest: ['payload'],
    loadAllCategorySuccess: ['payload'],
    loadAllCategoryFailure: ['payload'],

    loadCategoryRequest: ['payload'],
    loadCategorySuccess: ['payload'],
    loadCategoryFailure: ['payload'],

    createCategoryRequest: ['payload'],
    createCategorySuccess: ['payload'],
    createCategoryFailure: ['payload'],

    editCategoryRequest: ['payload'],
    editCategorySuccess: ['payload'],
    editCategoryFailure: ['payload'],

    updateCategoryRequest: ['payload'],
    updateCategorySuccess: ['payload'],
    updateCategoryFailure: ['payload'],

    toggleCategoryRequest: ['payload'],
    toggleCategorySuccess: ['payload'],
    toggleCategoryFailure: ['payload'],

    deleteCategoryRequest: ['payload'],
    deleteCategorySuccess: ['payload'],
    deleteCategoryFailure: ['payload']
  },
  {}
)

const initialState = {
  list: [],
  view: {},
  edit: {}
}

export const HANDLERS = {
  [Types.LOAD_ALL_CATEGORY_SUCCESS]: handlers.loadAllSuccess,
  [Types.LOAD_CATEGORY_SUCCESS]: handlers.loadSuccess,
  [Types.CREATE_CATEGORY_SUCCESS]: handlers.createSuccess,
  [Types.EDIT_CATEGORY_SUCCESS]: handlers.editSuccess,
  [Types.UPDATE_CATEGORY_SUCCESS]: handlers.updateSuccess,
  [Types.TOGGLE_CATEGORY_SUCCESS]: handlers.toggleSuccess,
  [Types.DELETE_CATEGORY_SUCCESS]: handlers.deleteSuccess
}

export const categoryTypes = Types
export const categoryActions = Creators
export const category = createReducer(initialState, HANDLERS)
