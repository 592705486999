import React from 'react'
import { Line } from 'react-chartjs-2'

export default function DonationCharts({ line, pie, anonymous }) {
  return (
    <div className='row px-3 mt-2'>
      <DonationLineChart data={line} />
    </div>
  )
}

export function DonationLineChart({ data }) {
  return (
    <div className='col-12'>
      {/* <!-- Area Chart --> */}
      <DonationCardLayout title='Histórico de Donaciones'>
        <div className='chart-area'>
          <Line
            data={data}
            width={800}
            height={300}
            options={{
              maintainAspectRatio: false,
              responsive: true
            }}
          />
        </div>
      </DonationCardLayout>
    </div>
  )
}

export function DonationCardLayout({ title, children }) {
  return (
    <div className='card shadow mb-4'>
      <div className='card-header py-3 d-flex flex-row align-items-center justify-content-between'>
        <h6 className='m-0 font-weight-bold text-primary'>{title}</h6>
      </div>
      <div className='card-body'>{children}</div>
    </div>
  )
}
