import React, { Component } from 'react'
import { connect } from 'react-redux'
import { categoryActions } from './category.redux'
import { Banner } from 'layouts/Site/Layouts/Banner'
import { Row, Col } from 'reactstrap'
import { mdy } from './../../util/dates'
import { isEmpty } from './../../util/crud'
import TableContainer from 'components/Container/TableContainer'
import { ListItem } from 'components/Information/ListItem'

class CategoryView extends Component {
  componentDidMount() {
    const { id } = this.props.match.params
    this.props.loadCategoryRequest({ id })
  }

  componentWillUnmount() {
    this.props.loadCategorySuccess()
  }

  render() {
    const { view } = this.props.category
    return (
      <Banner>
        <TableContainer
          title='Categoría'
          subtitle='Información del registro'
          toggle={this.props.toggleCategoryRequest}
          view={view}>
          {!isEmpty(view) && (
            <Row id='view-category'>
              <Col>
                <ul>
                  <ListItem label='Título' value={view.title} />
                  <ListItem label='Descripción' value={view.description} />
                  <ListItem label='Activo' value={view.active} />
                  <ListItem label='Creado' value={mdy(view.createdAt)} />
                </ul>
              </Col>
            </Row>
          )}
        </TableContainer>
      </Banner>
    )
  }
}

const mapStateToProps = ({ category }) => ({ category })
export default connect(mapStateToProps, categoryActions)(CategoryView)
