import axios from 'axios'

/**
 * @desc brief description
 * @param string , number , object, array, bool ...
 * @return bool - success or failure
 */
export const setAuthToken = token => {
  if (token) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
  } else {
    delete axios.defaults.headers.common['Authorization']
  }
}

// check if is better const currentTime = Date.now() / 1000
export const isExpire = exp => exp < Date.now() / 1000

export const removeCredentials = () => {
  // Remove token from localStorage
  localStorage.removeItem('token')
  // Remove the auth header for future requests
  setAuthToken(false)
}
