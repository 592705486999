import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { selectCE, selectGE } from './grid.helpers'
import './grid.css'
import './caption.css'

const Grid = ({ children, effect, captionEffect }) => {
  useEffect(() => {
    // new window.AnimOnScroll(document.getElementById('grid'), {
    //   minDuration: 0.4,
    //   maxDuration: 0.7,
    //   viewportFactor: 0.2
    // })
  }, [])

  return (
    <div className={`grid ${selectGE(effect)} ${selectCE(captionEffect)}`} id='grid'>
      {children}
    </div>
  )
}

Grid.propTypes = {
  gallerie: PropTypes.array.isRequired
}

export default Grid
