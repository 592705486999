import React from 'react'
import { Button, Modal, ModalHeader, ModalBody } from 'reactstrap'
import Img from 'react-cool-img'
import visa from 'assets/images/visa.png'
import americanExpress from 'assets/images/americanExpress.png'

export const ConektaDonationModal = ({ toggle, modal, children, amount, ...props }) => {
  return (
    <div>
      <Button
        onClick={toggle}
        className='credit-card-btn mt-2 p-2 btn-light btn-block'
        {...props}>
        <i className='ti-credit-card mr-2' />
        <Img
          alt='Credit card'
          src='https://media.graphcms.com/b3EqlINJRBGWMT5pnoU5'
          style={{ width: '30px', paddingRight: '5px' }}
        />
        <Img alt='Visa' src={visa} style={{ width: '30px', paddingRight: '5px' }} />
        <Img
          alt='American Express'
          src={americanExpress}
          style={{ width: '20px', paddingRight: '5px' }}
        />
        <span>Donar con Tarjeta de Crédito</span>
      </Button>
      <Modal size='md' isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>
          Donación de ${amount} MXN con Tarjeta de crédito
        </ModalHeader>
        <ModalBody>{children}</ModalBody>
      </Modal>
    </div>
  )
}
