/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import DataTable from 'components/Table/Table'
import Banner from 'layouts/Site/Layouts/Banner'
import TableContainer from 'components/Container/TableContainer'
import { columns } from './user.helpers'
import { userActions } from './user.redux'
import { useExportSelected } from 'hooks/useExportSelected'

function UserAdminList(props) {
  const [selectRow, handleExport] = useExportSelected('Usuarios', '/api/users/export')

  useEffect(() => {
    props.loadAllAdmin()
    props.loadUserOptions()
  }, [])

  const rowEvents = {
    onClick: (e, row) => {
      const url = `/dashboard/user/view/${row._id}`
      props.history.push(url)
    }
  }

  return (
    <Banner>
      <TableContainer title='Administradores' subtitle='Lista' route='/dashboard/user/create'>
        <DataTable
          rowEvents={rowEvents}
          data={props.users}
          structure={columns}
          onExport={handleExport}
          selectRow={selectRow}
          selected={selectRow.selected}
        />
      </TableContainer>
    </Banner>
  )
}

const mapStateToProps = ({ user }) => ({ users: user.list })
const mapDispatchToProps = {
  loadAllAdmin: userActions.loadAllAdminRequest,
  toggleUser: userActions.toggleUserRequest,
  loadUserOptions: userActions.loadUserOptionsRequest
}

export default connect(mapStateToProps, mapDispatchToProps)(UserAdminList)
