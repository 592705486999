/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { columns } from './category.helpers'
import { categoryActions } from './category.redux'
import Banner from 'layouts/Site/Layouts/Banner'
import TableContainer from 'components/Container/TableContainer'
import DataTable from 'components/Table/Table'
import { useExportSelected } from 'hooks/useExportSelected'

function CategoryList(props) {
  const [selectRow, handleExport] = useExportSelected('Categorías', '/api/categories/export')

  useEffect(() => {
    props.loadAllCategory()
  }, [])

  const rowEvents = {
    onClick: (e, row) => {
      props.history.push(`/dashboard/category/view/${row._id}`)
    }
  }

  return (
    <Banner>
      <TableContainer
        title='Categorías'
        subtitle='Lista'
        route='/dashboard/category/create'
        mbodule='Categorías'>
        <DataTable
          {...props}
          rowEvents={rowEvents}
          data={props.categories}
          structure={columns}
          onExport={handleExport}
          selectRow={selectRow}
          selected={selectRow.selected}
        />
      </TableContainer>
    </Banner>
  )
}

const mapStateToProps = ({ category }) => ({ categories: category.list })
const mapDispatchToProps = {
  loadAllCategory: categoryActions.loadAllCategoryRequest,
  toggleCategory: categoryActions.toggleCategoryRequest
}

export default connect(mapStateToProps, mapDispatchToProps)(CategoryList)
