import React, { Fragment } from 'react'
import {
  TwitterShareButton,
  TwitterIcon,
  WhatsappShareButton,
  WhatsappIcon,
  FacebookShareButton,
  FacebookIcon,
  LinkedinShareButton,
  LinkedinIcon
} from 'react-share'

export function SocialMediaShareBar({ shareUrl, message }) {
  return (
    <Fragment>
      <h6 className='text-center'>Compartir en redes sociales:</h6>
      <div className='d-flex justify-content-center social-media-share-bar mb-4'>
        <TwitterShareButton url={shareUrl} title={message}>
          <TwitterIcon size={40} round />
        </TwitterShareButton>
        <WhatsappShareButton url={shareUrl} title={message} separator=' '>
          <WhatsappIcon size={40} round />
        </WhatsappShareButton>
        <FacebookShareButton url={shareUrl} quote={message}>
          <FacebookIcon size={40} round />
        </FacebookShareButton>
        <LinkedinShareButton
          url={shareUrl}
          source={shareUrl}
          title={message}
          summary={message}>
          <LinkedinIcon size={40} round />
        </LinkedinShareButton>
      </div>
    </Fragment>
  )
}
