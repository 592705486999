import React from 'react'
import { Link } from 'react-router-dom'
import { Timeline, TimelineEvent } from 'react-event-timeline'
import picture from 'assets/images/thumbnail.jpg'
import { mdyhm } from 'util/dates'
import { isEmpty } from 'util/crud'

function VerticalTimeline({ values }) {
  if (isEmpty(values)) return <h5 className='text-center my-4'>Sin cambios registrados</h5>

  return (
    <Timeline>
      {values.map(item => {
        const user = item.createdBy
        return (
          <Link to={`/dashboard/${item.module}/view/${item.document}`}>
            <TimelineEvent
              title={`${item.label} ${item.title} - ${item.cause}`}
              createdAt={`${mdyhm(item.createdAt)} - ${user?.fullName}`}
              container='card'
              titleStyle={{ fontSize: 14.5 }}
              contentStyle={{
                fontSize: 15,
                fontWeight: '300',
                backgroundColor: 'white',
                paddingLeft: 19
              }}
              cardHeaderStyle={{
                backgroundColor: 'whitesmoke',
                color: '#2d2d2d',
                borderLeft: `4px solid ${item.color}`,
                paddingLeft: 14
              }}
              bubbleStyle={{ borderColor: 'whitesmoke' }}
              icon={
                <Link to={`/dashboard/user/view/${user._id}`}>
                  <img
                    className='object-fit-cover'
                    src={user?.thumbnail?.path || picture}
                    style={{ borderRadius: '50%', width: 30, height: 30 }}
                    alt={user.name}
                  />
                </Link>
              }>
              <p className='mb-0'>{item.description}</p>
            </TimelineEvent>
          </Link>
        )
      })}
    </Timeline>
  )
}

VerticalTimeline.defaultProps = {
  values: []
}

export default VerticalTimeline
