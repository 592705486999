import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

export function ScrollToTop() {
  const { pathname } = useLocation()
  useEffect(() => {
    const dontScrollRoutes = [
      '/about',
      '/campaigns',
      '/notice-of-privacy',
      '/terms-and-conditions',
      '/login'
    ]
    if (!dontScrollRoutes.includes(pathname)) {
      window.scrollTo({ top: 0, behavior: 'smooth' })
    }
  }, [pathname])

  return null
}
