import React, { Component } from 'react'
import { connect } from 'react-redux'
import Banner from 'layouts/Site/Layouts/Banner'
import { isEmpty } from './../../util/crud'
import { pagesActions } from './pages.redux'
import PagesForm from './PagesForm'

class PagesEdit extends Component {
  componentDidMount() {
    const { id } = this.props.match.params
    this.props.editPagesRequest({ id })
  }

  componentWillUnmount() {
    this.props.editPagesSuccess()
  }

  onCancel = () => {
    this.props.history.push('/dashboard/pages/list')
  }

  submit = values => {
    const { id } = this.props.match.params
    this.props.updatePages({ id, values })
  }

  render() {
    const values = this.props.pages.edit

    return (
      <Banner>
        {!isEmpty(values) && (
          <PagesForm
            {...this.props}
            label='Actualizar'
            button='edit'
            onCancel={this.onCancel}
            onSubmit={this.submit}
            initialValues={values}
          />
        )}
      </Banner>
    )
  }
}

const mapStateToProps = ({ pages }) => ({ pages })
const mapDispatchToProps = {
  updatePages: pagesActions.updatePagesRequest,
  editPagesRequest: pagesActions.editPagesRequest,
  editPagesSuccess: pagesActions.editPagesSuccess
}

export default connect(mapStateToProps, mapDispatchToProps)(PagesEdit)
