import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { donationCardGetOneRequest } from './card.redux'
import { Banner } from 'layouts/Site/Layouts/Banner'
import { Row, Col } from 'reactstrap'
import { mdy } from './../../util/dates'
import { isEmpty } from './../../util/crud'
import TableContainer from 'components/Container/TableContainer'
import { ListItem } from 'components/Information/ListItem'
import { useParams } from 'react-router-dom'

function CardDonationView(props) {
  const { view } = props
  const location = useParams()

  useEffect(() => {
    props.donationCardGetOneRequest(location.id)
  }, [])

  const customer = view.conekta?.customer_info

  return (
    <Banner>
      <TableContainer
        showButtons={false}
        title='Donación'
        subtitle='Información del registro'
        view={view}>
        {!isEmpty(view) && (
          <Row id='view-donation'>
            <Col>
              <ul>
                <ListItem label='Nombre' value={`${view.conekta.customer_info.name}`} />
                <ListItem label='Correo' value={`${view.conekta.customer_info.email}`} />
                <ListItem label='Telefono' value={`${view.conekta.customer_info.phone}`} />
                <ListItem label='Monto' value={`${view.amount} ${view.currency}`} />
                <ListItem label='Campaña' value={view.campaign.title} />
                <ListItem label='Fecha' value={mdy(view.createdAt)} />
              </ul>
            </Col>
            {view.type === 'failed' && (
              <Col>
                <ul>
                  <ListItem label='Nombre del donador' value={customer.name} />
                  <ListItem label='Email' value={customer.email} />
                  <ListItem label='Teléfono' value={customer.phone} />
                  <ListItem label='Problema' value={view.error.message} />
                </ul>
              </Col>
            )}
          </Row>
        )}
      </TableContainer>
    </Banner>
  )
}

const mapStateToProps = ({ card }) => ({ view: card.view })

const mapDispatchToProps = {
  donationCardGetOneRequest
}

export default connect(mapStateToProps, mapDispatchToProps)(CardDonationView)
