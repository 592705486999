export const initConekta = client => {
  window.Conekta.setPublicKey(client)
}

export const getCardBrand = cardNumber => {
  return window.Conekta.card.getBrand(cardNumber)
}

export const validateCardNumber = cardNumber => {
  return window.Conekta.card.validateNumber(cardNumber)
}

export const validateCvc = cvc => {
  return window.Conekta.card.validateCVC(cvc)
}

export const validateExpirationDate = (expiryMonth, expiryYear) => {
  return window.Conekta.card.validateExpirationDate(expiryMonth, `20${expiryYear}`)
}

export const tokenize = (
  { number, name, exp_month, exp_year, cvc },
  successCallback,
  errorCallback
) => {
  const tokenParams = {
    card: { number, name, exp_year, exp_month, cvc }
  }
  window.Conekta.Token.create(tokenParams, successCallback, errorCallback)
}
