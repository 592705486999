import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { Col } from 'reactstrap'
import Banner from 'layouts/Site/Layouts/Banner'
import LoginForm from './LoginForm'
import OAuthBar from './OAuth/OAuthBar'
import { Link } from 'react-router-dom'

function Login({ auth, history }) {
  useEffect(() => {
    if (auth.isAuthenticated) {
      history.push('/')
    }
  })

  return (
    <Banner admin={false}>
      <Col md='7' className='p-5'>
        <LoginForm />
        <div className='text-center p-3'>
          <Link to='/forgot-password'>Olvidé mi contraseña</Link>
        </div>
      </Col>
      <Col md='5' className='py-5'>
        <OAuthBar />
      </Col>
    </Banner>
  )
}

const mapStateToProps = ({ auth }) => ({ auth })
const withConnect = connect(mapStateToProps)

export default withConnect(Login)
