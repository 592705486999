import axios from 'axios'
import { all, takeLatest, call, put, take, select } from 'redux-saga/effects'
import { sliderActions, sliderTypes } from './slider.redux'

/**
 * Display the campaigns public gallery
 * @returns {iterator}
 */
export function* publicLoadSlider() {
  try {
    const url = '/api/public/campaigns/slider'
    const { data: campaigns } = yield call(axios.get, url)
    yield put(sliderActions.publicLoadSliderSuccess(campaigns))
  } catch (error) {
    yield put(sliderActions.publicLoadSliderFailure(error))
    console.log(error)
  }
}

export function* sliderSagas() {
  yield all([takeLatest(sliderTypes.PUBLIC_LOAD_SLIDER_REQUEST, publicLoadSlider)])
}
