import React, { Component } from 'react'
import { connect } from 'react-redux'
import Banner from 'layouts/Site/Layouts/Banner'
import { userActions } from './user.redux'
import UserCard from 'components/Cards/UserCard/UserCard'

class UserList extends Component {
  componentDidMount() {
    this.props.loadAllUser()
    this.props.loadUserOptions()
  }

  render() {
    return (
      <Banner>
        <div className='col-12 mt-5 pt-5 mb-4'>
          <div className='header-h2'>
            <h2 className='mb-3 text-left'>Usuarios</h2>
          </div>
          <UserCard />
        </div>
      </Banner>
    )
  }
}

const mapStateToProps = ({ user }) => ({ users: user.list })
const mapDispatchToProps = {
  loadAllUser: userActions.loadAllUserRequest,
  toggleUser: userActions.toggleUserRequest,
  loadUserOptions: userActions.loadUserOptionsRequest
}

export default connect(mapStateToProps, mapDispatchToProps)(UserList)
