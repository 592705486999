import InstitutionCreate from './InstitutionCreate'
import InstitutionList from './InstitutionList'
import InstitutionEdit from './InstitutionEdit'
import InstitutionView from './InstitutionView'

const routes = [
  { path: '/dashboard/institution/list', component: InstitutionList },
  { path: '/dashboard/institution/create', component: InstitutionCreate },
  { path: '/dashboard/institution/edit/:id', component: InstitutionEdit },
  { path: '/dashboard/institution/view/:id', component: InstitutionView }
]

export default routes
