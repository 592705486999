import React from 'react'
import { connect } from 'react-redux'
import { Button, Col } from 'reactstrap'
import { reduxForm, Form } from 'redux-form'
import { actions } from './auth.redux'
import { required } from '../../util/form'
import { RFInput } from 'components/Inputs/RFFields'
import { Banner } from 'layouts/Site/Layouts/Banner'

function ForgotPassword(props) {
  // Submit the form
  const submit = values => {
    const { email } = values
    const payload = { email }
    props.requestRecoverPassword(payload)
  }

  return (
    <Banner admin={false}>
      <Col md={{ size: 6, offset: 3 }}>
        <div className='py-5'>
          <div className='text-center'>
            <h1 className='h4 text-gray-900 mb-4'>Recuperar contraseña</h1>
          </div>
          <Form onSubmit={props.handleSubmit(submit)}>
            <RFInput
              label='Correo electrónico'
              name='email'
              type='email'
              validate={[required]}
            />

            <Button
              block
              color='primary'
              type='submit'
              disabled={props.submitting || props.pristine || props.loading}>
              Enviar
            </Button>
          </Form>
        </div>
      </Col>
    </Banner>
  )
}

const ForgotPasswordForm = reduxForm({
  form: 'forgot-password',
  enableReinitialize: true
})(ForgotPassword)

const mapDispatchToProps = {
  requestRecoverPassword: actions.requestRecoverPasswordRequest
}
export default connect(
  ({ auth }) => ({ loading: auth.loading }),
  mapDispatchToProps
)(ForgotPasswordForm)
