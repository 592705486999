import { mdy } from 'util/dates'

const getFullName = user => `${user.name} ${user.lastName}`

export const columns = [
  { dataField: 'module', text: 'Módulo', sort: true },
  { dataField: 'cause', text: 'Causa', sort: true },
  { dataField: 'createdBy', text: 'Autor', sort: true, formatter: getFullName },
  { dataField: 'createdAt', text: 'Fecha', sort: true, formatter: mdy }
]

export function decorateHistorical(historical) {
  const changes = {
    campaign: ['Campaña', 'icon-home'],
    institution: ['Institución', 'icon-home'],
    user: ['Usuario', 'icon-group'],
    configuration: ['Configuración', 'icon-cog'],
    page: ['Páginas', 'icon-edit'],
    category: ['Categoría', 'icon-check']
  }

  const getColor = change => {
    switch (change) {
      case 'Activación':
        return '#06d6a0'
      case 'Desactivación':
        return '#ef476f'
      default:
        return '#fb8500'
    }
  }

  return historical.map(item => {
    const [label, icon] = changes[item.module]
    const color = getColor(item.cause)
    return { ...item, label, icon, color }
  })
}
