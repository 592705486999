import axios from 'axios'
import { push } from 'connected-react-router'
import { all, takeLatest, call, put, take } from 'redux-saga/effects'
import { alertActions, alertTypes } from '././../Alert/alert.redux'
import { pagesActions, pagesTypes } from './pages.redux'
import { isEmpty } from './../../util/crud'

/**
 * Display the pages list
 * @returns {iterator}
 */
export function* loadAllPages() {
  try {
    const url = '/api/pages/'
    const { data: pages } = yield call(axios.get, url)
    if (isEmpty(pages)) {
      yield put(alertActions.alertMessageEmptyGoBack('pages', '/dashboard/pages/create'))
    }
    yield put(pagesActions.loadAllPagesSuccess(pages))
  } catch (error) {
    yield put(pagesActions.loadAllPagesFailure(error))
    console.log(error)
  }
}

/**
 * Display a single pages record
 * @param   {object}   action.name Name of the requested pages
 * @returns {iterator}
 */
export function* publicLoadPage({ name }) {
  try {
    const url = `/api/public/pages/${name}`
    const { data: page } = yield call(axios.get, url)
    yield put(pagesActions.publicLoadPageSuccess(page))
  } catch (error) {
    yield put(pagesActions.publicLoadPageFailure(error))
    console.log(error)
  }
}

/**
 * Load the information of a single pages record to edit it
 * @param   {object}   action.payload Data to take the id of the requested pages
 * @returns {iterator}
 */
export function* editPages({ payload } = {}) {
  try {
    const { id } = payload
    const url = `/api/pages/${id}`
    const { data: pages } = yield call(axios.get, url)
    yield put(pagesActions.editPagesSuccess(pages))
  } catch (error) {
    yield put(pagesActions.editPagesFailure(error))
    console.log(error)
  }
}

/**
 * Update an pages record
 * @param   {object}   action.payload Data to update an pages record
 * @returns {iterator}
 */
export function* updatePages({ payload }) {
  try {
    yield put(alertActions.alertPromptShow())
    const prompt = yield take(alertTypes.ALERT_PROMPT_HIDE)

    if (isEmpty(prompt.payload)) return

    const { id, values } = payload
    const url = `/api/pages/${id}`

    const historical = { cause: 'Actualización', description: prompt.payload }
    const data = { payload: values, historical }
    const { data: updatedPages } = yield call(axios.put, url, data)
    yield put(pagesActions.updatePagesSuccess(updatedPages))
    // Success notification and return the pages to the list
    yield put(alertActions.alertMessageSuccess('Registro actualizado'))
    yield put(push('/dashboard/pages/list'))
  } catch (error) {
    yield put(pagesActions.updatePagesFailure(error))
    console.log(error)
  }
}

/**
 * Validate an pages record
 * @param     values
 * @returns
 */
export async function validatePages(values) {
  const url = '/api/pages/validate'
  const { data: result } = await axios.post(url, values)
  if (!isEmpty(result.errors)) throw result.errors
}

export function* pagesSagas() {
  yield all([
    takeLatest(pagesTypes.LOAD_ALL_PAGES_REQUEST, loadAllPages),
    takeLatest(pagesTypes.PUBLIC_LOAD_PAGE_REQUEST, publicLoadPage),
    takeLatest(pagesTypes.EDIT_PAGES_REQUEST, editPages),
    takeLatest(pagesTypes.UPDATE_PAGES_REQUEST, updatePages)
  ])
}
