import React from 'react'
import { connect } from 'react-redux'
import { reduxForm } from 'redux-form'
import { Form, Button } from 'reactstrap'
import FormContainer from 'components/Container/FormContainer'
import { withForm } from 'util/withForm'
import { RFCheckbox, RFInput } from 'components/Inputs/RFFields'
import { substring } from 'util/form'

const limit500 = substring(0, 500)

function CampaignSettingsForm({ button, handleSubmit, previousPage, config, ...props }) {
  const canDonate = config.providers.conekta || config.providers.paypal

  return (
    <FormContainer {...props}>
      <Form>
        {canDonate && (
          <RFInput
            type='textarea'
            name='gratitude'
            label='Mensaje de agradecimiento por donación (Opcional)'
            normalize={limit500}
          />
        )}
        <div className='d-flex justify-content-around'>
          {canDonate && <RFCheckbox name='visibility.counter' label='Mostrar contador' />}
          {canDonate && (
            <RFCheckbox name='visibility.countdown' label='Mostrar cuenta regresiva' />
          )}
          <RFCheckbox name='visibility.comments' label='Mostrar comentarios' />
        </div>
      </Form>
      <Button onClick={previousPage}>Anterior</Button>
      <Button color='primary' className='float-right' onClick={handleSubmit}>
        Siguiente
      </Button>
    </FormContainer>
  )
}

const mapStateToProps = ({ configuration }) => ({ config: configuration.public })
const withConnect = connect(mapStateToProps)

export default withConnect(
  withForm(
    reduxForm({
      form: 'campaign',
      destroyOnUnmount: false,
      forceUnregisterOnUnmount: true
    })(CampaignSettingsForm)
  )
)
