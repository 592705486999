import axios from 'axios'
import { push } from 'connected-react-router'
import { all, takeLatest, call, put, take } from 'redux-saga/effects'
import { alertActions, alertTypes } from '././../Alert/alert.redux'
import { categoryActions, categoryTypes } from './category.redux'
import { isEmpty } from './../../util/crud'

/**
 * Display the categories list
 * @returns {iterator}
 */
export function* loadAllCategory() {
  try {
    const url = '/api/categories/'
    const { data: categories } = yield call(axios.get, url)
    if (isEmpty(categories)) {
      yield put(alertActions.alertMessageEmpty('category', '/dashboard/category/create'))
    }
    yield put(categoryActions.loadAllCategorySuccess(categories))
  } catch (error) {
    yield put(categoryActions.loadAllCategoryFailure(error))
    console.log(error)
  }
}

/**
 * Display a single category record
 * @param   {object}   action.payload Data to take the id of the requested category
 * @returns {iterator}
 */
export function* loadCategory({ payload } = {}) {
  try {
    const { id } = payload
    const url = `/api/categories/${id}`
    const { data: category } = yield call(axios.get, url)
    yield put(categoryActions.loadCategorySuccess(category))
  } catch (error) {
    yield put(categoryActions.loadCategoryFailure(error))
    console.log(error)
  }
}

/**
 * Create an category record
 * @param   {object}   action.payload Data to create an category record
 * @returns {iterator}
 */
export function* createCategory({ payload }) {
  try {
    const url = '/api/categories/'
    // Make the POST request
    const { data } = yield call(axios.post, url, payload)
    // Add new document to the list
    yield put(categoryActions.createCategorySuccess(data))
    // Show notification
    yield put(alertActions.alertMessageSuccess('Registro guardado'))
    // Return the user to the list
    yield put(push('/dashboard/category/list'))
  } catch (error) {
    yield put(categoryActions.createCategoryFailure(error))
    console.log(error)
  }
}

/**
 * Load the information of a single category record to edit it
 * @param   {object}   action.payload Data to take the id of the requested category
 * @returns {iterator}
 */
export function* editCategory({ payload } = {}) {
  try {
    const { id } = payload
    const url = `/api/categories/${id}`
    const { data: category } = yield call(axios.get, url)
    yield put(categoryActions.editCategorySuccess(category))
  } catch (error) {
    yield put(categoryActions.editCategoryFailure(error))
    console.log(error)
  }
}

/**
 * Update an category record
 * @param   {object}   action.payload Data to update an category record
 * @returns {iterator}
 */
export function* updateCategory({ payload }) {
  try {
    yield put(alertActions.alertPromptShow())
    const prompt = yield take(alertTypes.ALERT_PROMPT_HIDE)

    if (isEmpty(prompt.payload)) return

    const { id, values } = payload
    const url = `/api/categories/${id}`

    const historical = { cause: 'Actualización', description: prompt.payload }
    const data = { payload: values, historical }
    const { data: updatedCategory } = yield call(axios.put, url, data)
    yield put(categoryActions.updateCategorySuccess(updatedCategory))
    // Success notification and return the user to the list
    yield put(alertActions.alertMessageSuccess('Registro actualizado'))
    yield put(push('/dashboard/category/list'))
  } catch (error) {
    yield put(categoryActions.updateCategoryFailure(error))
    console.log(error)
  }
}

/**
 * Toggle the active property of an category record
 * @param   {object}   action.payload Data to update an category record
 * @returns {iterator}
 */
export function* toggleCategory({ payload }) {
  try {
    yield put(alertActions.alertPromptShow())
    const prompt = yield take(alertTypes.ALERT_PROMPT_HIDE)
    const description = prompt.payload

    // The prompt was closed, stop the flow
    if (isEmpty(description)) return

    const { _id, active } = payload
    const url = `/api/categories/${_id}`

    // Make the PUT request
    const historical = { cause: active ? 'Desactivación' : 'Activación', description }
    const { data: updatedCategory } = yield call(axios.put, url, {
      payload: { active: !active },
      historical
    })
    yield put(categoryActions.toggleCategorySuccess(updatedCategory))

    // Show success notification
    const result = updatedCategory.active ? 'activado' : 'desactivado'
    yield put(alertActions.alertMessageSuccess(`Registro ${result}`))
    yield put(push('/dashboard/category/list'))
  } catch (error) {
    yield put(categoryActions.toggleCategoryFailure(error))
    console.log(error)
  }
}

/**
 * Validate an category record
 * @param     values
 * @returns
 */
export async function validateCategory(values) {
  const url = '/api/categories/validate'
  const { data: result } = await axios.post(url, values)
  if (!isEmpty(result.errors)) throw result.errors
}

export function* categorySagas() {
  yield all([
    takeLatest(categoryTypes.LOAD_ALL_CATEGORY_REQUEST, loadAllCategory),
    takeLatest(categoryTypes.LOAD_CATEGORY_REQUEST, loadCategory),
    takeLatest(categoryTypes.CREATE_CATEGORY_REQUEST, createCategory),
    takeLatest(categoryTypes.EDIT_CATEGORY_REQUEST, editCategory),
    takeLatest(categoryTypes.UPDATE_CATEGORY_REQUEST, updateCategory),
    takeLatest(categoryTypes.TOGGLE_CATEGORY_REQUEST, toggleCategory)
  ])
}
