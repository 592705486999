import React from 'react'
import FormWrapper from '../FormWrapper'
import { Button, Row } from 'reactstrap'
import { reduxForm } from 'redux-form'
import { RFInput } from 'components/Inputs/RFFields'
import { required } from 'util/form'

function FormContact({ handleSubmit, previousPage, label }) {
  return (
    <FormWrapper title={label}>
      <Row style={{ minHeight: 287 }}>
        <RFInput name='settings.name' label='Nombre de la institución' validate={[required]} />
        <RFInput name='contact.address' label='Dirección' validate={[required]} />
        <RFInput name='contact.phone' label='Teléfono de contacto' validate={[required]} />
        <RFInput
          type='email'
          name='contact.email'
          label='Correo electrónico de contacto'
          validate={[required]}
        />
        <RFInput
          type='textarea'
          name='settings.gratitudeMessage'
          label='Mensaje de agradecimiento al donador (por defecto)'
          validate={[required]}
        />
      </Row>
      <Button color='secondary' id='prev-button' onClick={previousPage}>
        Atrás
      </Button>
      <Button color='primary' id='next-button' onClick={handleSubmit}>
        Continuar
      </Button>
    </FormWrapper>
  )
}

export default reduxForm({
  form: 'initial-configuration',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true
})(FormContact)
