import React from 'react'
import Counters from './../../modules/Counter/Counters'
import Information from 'layouts/Site/Information/Information'
import Banner from 'layouts/Site/Layouts/Banner'
import { connect } from 'react-redux'

export const Home = ({ showCounters }) => {
  return (
    <Banner fluid admin={false}>
      {showCounters && <Counters />}
      <Information />
    </Banner>
  )
}

const mapStateToProps = ({ configuration }) => ({
  showCounters: configuration.public.home?.showCounters
})
export default connect(mapStateToProps)(Home)
