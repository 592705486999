import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

export const ProgressBar = ({ progressBar }) => {
  return <Fragment>{progressBar && <div>Progress Bar</div>}</Fragment>
}

ProgressBar.propTypes = {
  prop: PropTypes.object
}

const mapStateToProps = ({ progressBar }) => ({ progressBar })
export default connect(mapStateToProps)(ProgressBar)
