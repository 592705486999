import React, { useEffect } from 'react'
import Slick from 'react-slick'
import sliderConfig from './slider.config'
import { connect } from 'react-redux'
import { sliderActions } from './slider.redux'
import CardSm from './../../layouts/Site/Cards/CardSm'

function Slider({ title, items, children, slider = [], ...props }) {
  useEffect(() => {
    props.loadCampaigns()
  }, [])
  const configuration = sliderConfig(slider.length)

  return (
    <div className='container' {...props}>
      <header className='section-header'>{title}</header>
      <Slick {...configuration}>
        {slider.map((campaign, i) => (
          <CardSm key={`campaign-${i}`} {...campaign} url='/campaigns' />
        ))}
      </Slick>
    </div>
  )
}

const mapStateToProps = ({ slider }) => ({
  slider
})

const mapDispatchToProps = {
  loadCampaigns: sliderActions.publicLoadSliderRequest
}

export default connect(mapStateToProps, mapDispatchToProps)(Slider)
