import React from 'react'
import PropTypes from 'prop-types'

export const capitalize = string => string.charAt(0).toUpperCase() + string.slice(1)

const LoginButton = ({ onClick, provider }) => {
  const label = `Iniciar sesión con ${capitalize(provider)}`
  return (
    <div className='login-button' onClick={onClick} onKeyPress={() => {}} role='presentation'>
      <span className={provider}>
        <i className={`icon-${provider}`} aria-hidden='true'></i>
        <span className='ml-3'>{label}</span>
      </span>
    </div>
  )
}

LoginButton.propTypes = {
  provider: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired
}

export default LoginButton
