/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import Banner from 'layouts/Site/Layouts/Banner'
import { pagesActions } from 'modules/Pages/pages.redux'

function TermsAndConditions({ page, publicLoadPage }) {
  useEffect(() => {
    publicLoadPage('terms-and-conditions')
  }, [])

  return (
    <Banner admin={false}>
      <section className='ftco-section testimony-section'>
        <div className='container'>
          <div className='mb-2 header-h1'>
            <h2 className='mb-3 text-left'>{page.title}</h2>
          </div>
          <div
            className='campaign-content'
            dangerouslySetInnerHTML={{ __html: page.description }}
          />
        </div>
      </section>
    </Banner>
  )
}

const mapStateToProps = ({ pages }) => ({ page: pages.page })
const mapDispatchToProps = {
  publicLoadPage: pagesActions.publicLoadPageRequest
}

export default connect(mapStateToProps, mapDispatchToProps)(TermsAndConditions)
