import React from 'react'
import Comment from './Comment'
import CommentBox from './CommentBox'
import { connect } from 'react-redux'

function CommentsList({ auth, comments }) {
  const canComment = auth.isAuthenticated && auth.user.role === 2

  return (
    <div className='mt-5'>
      <h3 className='mb-5 font-weight-bold'>Comentarios</h3>
      <ul
        className='comment-list overflow-box'
        style={{ maxHeight: 550, paddingRight: 15, overflowY: 'auto' }}>
        {comments.map(({ createdBy = {}, ...comment }, i) => (
          <Comment
            {...comment}
            key={`comment-${i}`}
            picture={createdBy.picture}
            author={`${createdBy.name} ${createdBy.lastName}`}
          />
        ))}
      </ul>
      {canComment && <CommentBox />}
    </div>
  )
}

const withConnect = connect(({ auth, campaign }) => ({
  auth,
  comments: campaign.campaign.comments
}))
export default withConnect(CommentsList)

CommentsList.defaultProps = {
  comments: []
}
