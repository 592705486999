import React from 'react'
import thumbnail from 'assets/images/user-thumbnail.png'
import moment from 'moment'
import { connect } from 'react-redux'
import { campaignActions } from 'modules/Campaign/campaign.redux'
import Acl from 'components/Acl/Acl'
import Img from 'react-cool-img'

function Comment({ _id, picture, author, createdAt, comment, deletComment, user }) {
  return (
    <li className='comment'>
      <div className='vcard bio'>
        <Img src={picture} alt={author} />
      </div>
      <div className='comment-body'>
        <Acl module='campaign' action='update'>
          <i
            className='icon-times'
            style={{ float: 'right', color: '#ef6462', cursor: 'pointer' }}
            onClick={() => deletComment(_id)}
            onKeyPress={() => {}}
            role='presentation'
          />
        </Acl>
        <h3>{author}</h3>
        <div className='meta'>{moment(createdAt).fromNow()}</div>
        <p style={{ wordBreak: 'break-word' }}>{comment}</p>
      </div>
    </li>
  )
}

const mapStateToProps = ({ auth }) => ({ user: auth.user })

const mapDispatchToProps = {
  deletComment: campaignActions.deleteCampaignCommentRequest
}

export default connect(mapStateToProps, mapDispatchToProps)(Comment)

Comment.defaultProps = {
  picture: thumbnail
}
