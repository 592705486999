import { createActions, createReducer } from 'reduxsauce'
import * as handlers from './../../redux/helpers/handlers'

const { Types, Creators } = createActions(
  {
    loadAllDonationRequest: ['payload'],
    loadAllDonationSuccess: ['payload'],
    loadAllDonationFailure: ['payload'],

    loadDonationRequest: ['payload'],
    loadDonationSuccess: ['payload'],
    loadDonationFailure: ['payload'],

    loadPaypalDonationRequest: ['payload'],
    loadPaypalDonationSuccess: ['payload'],
    loadPaypalDonationFailure: ['payload'],

    createPaypalDonationRequest: ['payload'],
    createPaypalDonationSuccess: ['payload'],
    createPaypalDonationFailure: ['payload'],

    createConektaDonationRequest: ['payload', 'cb'],
    createConektaDonationSuccess: ['payload'],
    createConektaDonationFailure: ['payload'],

    requestOxxoPayDonationRequest: ['payload', 'cb'],
    requestOxxoPayDonationSuccess: ['payload'],
    requestOxxoPayDonationFailure: ['payload'],

    filterDonationsByType: ['payload']
  },
  {}
)

const initialState = {
  list: [],
  donations: [],
  view: {},
  oxxopay: {},
  filter: 'all'
}

export const HANDLERS = {
  [Types.LOAD_ALL_DONATION_SUCCESS]: function (state, action) {
    return { ...state, list: [...action.payload], donations: [...action.payload] }
  },
  [Types.LOAD_DONATION_SUCCESS]: handlers.loadSuccess,
  [Types.LOAD_PAYPAL_DONATION_SUCCESS]: handlers.loadSuccess,
  [Types.REQUEST_OXXO_PAY_DONATION_SUCCESS]: function (state, action) {
    return { ...state, oxxopay: { ...action.payload } }
  },
  [Types.FILTER_DONATIONS_BY_TYPE]: function (state, action) {
    const filter = action.payload
    const { donations } = state
    if (filter !== 'all')
      return { ...state, filter, list: donations.filter(item => item.type === filter) }
    else return { ...state, filter, list: [...donations] }
  }
}

export const donationTypes = Types
export const donationActions = Creators
export const donation = createReducer(initialState, HANDLERS)
