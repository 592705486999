import React, { useEffect, useState } from 'react'
import { Form, reduxForm } from 'redux-form'
import { connect } from 'react-redux'
import { Button, Col } from 'reactstrap'
import { actions } from './auth.redux'
import { parseQuery } from 'util/url'
import { required, minLength, isMatch, validPassword, trim } from 'util/form'
import { RFInput } from 'components/Inputs/RFFields'
import { Banner } from 'layouts/Site/Layouts/Banner'

const minLength8 = minLength(8)
const matchPassword = isMatch('password', 'Las contraseñas')

function RecoverPassword(props) {
  const query = props.history.location.search
  const [state, setState] = useState({ email: '', token: '' })

  useEffect(() => {
    const { email, token } = parseQuery(query)
    setState({ email, token })
  }, [query])

  const submit = ({ password }) => {
    const payload = { password, email: state.email, token: state.token }
    props.recoverPassword(payload)
  }

  return (
    <Banner admin>
      <Col md={{ size: 6, offset: 3 }}>
        <div className='py-5 mt-5'>
          <div className='text-center'>
            <h1 className='h4 text-gray-900 mb-4'>Recuperar contraseña</h1>
          </div>
          <Form onSubmit={props.handleSubmit(submit)}>
            <RFInput
              name='password'
              type='password'
              label='Nueva contraseña'
              normalize={trim}
              validate={[required, minLength8, validPassword]}
            />

            {/* Confirm new password (string) */}
            <RFInput
              name='confirm_password'
              type='password'
              label='Confirmar nueva contraseña'
              normalize={trim}
              validate={[required, matchPassword]}
            />

            <Button
              block
              color='primary'
              type='submit'
              disabled={props.submitting || props.pristine}>
              Enviar
            </Button>
          </Form>
        </div>
      </Col>
    </Banner>
  )
}

const RecoverPasswordForm = reduxForm({
  form: 'recover-password',
  enableReinitialize: true
})(RecoverPassword)

const mapDispatchToProps = {
  recoverPassword: actions.recoverPasswordRequest
}
export default connect(
  null,
  mapDispatchToProps
)(RecoverPasswordForm)
