import React from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'
import { mdy } from './../../../util/dates'

function Header({ thumbnail, description, title, subtitle, createdAt }) {
  return (
    <div
      className='hero-wrap hero-wrap-2 background-hero'
      data-stellar-background-ratio='0.5'
      style={{ backgroundImage: `url(${thumbnail.path})` }}>
      <div className='overlay' />
      <div className='container'>
        <div className='row no-gutters slider-text align-items-end justify-content-start'>
          <div className='col-md-8 text-center text-md-left mb-5'>
            <span className='subheading'>{subtitle}</span>
            <h1 className='mb-3'>
              <span>{title}</span>
            </h1>
            <small style={{ fontSize: 14 }}>
              {mdy(createdAt)} - {moment(createdAt).fromNow()}
            </small>
            <p style={{ fontSize: 16 }}>{description}</p>
          </div>
        </div>
      </div>
    </div>
  )
}

Header.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  description: PropTypes.string,
  thumbnail: PropTypes.object
}

Header.defaultProps = {
  thumbnail: {}
}

export default Header
