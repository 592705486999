import React from 'react'
import { generateActiveIcon } from 'components/Table/helpers'
import { mdy } from './../../util/dates'
import thumbnail from './../../assets/images/user-thumbnail.png'
import Img from 'react-cool-img'

const roles = ['Administrador total', 'Administrador restringido', 'Usuario']

export const columns = [
  {
    dataField: 'thumbnail.path',
    formatter: (cell, row) => (
      <Img
        src={cell || thumbnail}
        alt=''
        style={{
          width: 50,
          height: 50,
          borderRadius: '50%',
          objectFit: 'cover'
        }}
      />
    )
  },
  { dataField: 'fullName', text: 'Nombre', sort: true },
  { dataField: 'email', text: 'Correo', sort: true },
  { dataField: 'role', text: 'Rol', formatter: cell => roles[cell], sort: true },
  { dataField: 'updatedAt', text: 'Última modificación', sort: true, formatter: mdy },
  { dataField: 'createdAt', text: 'Creado', sort: true, formatter: mdy },
  {
    dataField: 'active',
    text: 'Activo',
    formatter: cell => generateActiveIcon(cell),
    sort: true
  }
]
