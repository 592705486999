import React from 'react'
import FormWrapper from '../FormWrapper'
import { Button, Row } from 'reactstrap'
import { reduxForm } from 'redux-form'
import { RFHTMLEditor } from 'components/Inputs/RFFields'
import { required } from 'util/form'

function FormNotice({ handleSubmit, previousPage, label }) {
  return (
    <FormWrapper title={label} style={{ width: 900 }}>
      <Row style={{ minHeight: 287 }}>
        <RFHTMLEditor name='pages.notice' validate={[required]} />
      </Row>
      <Button color='secondary' id='prev-button' onClick={previousPage}>
        Atrás
      </Button>
      <Button color='primary' id='next-button' onClick={handleSubmit}>
        Continuar
      </Button>
    </FormWrapper>
  )
}

export default reduxForm({
  form: 'initial-configuration',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true
})(FormNotice)
