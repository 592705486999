import { createActions, createReducer } from 'reduxsauce'
import * as handlers from './../../redux/helpers/handlers'

const { Types, Creators } = createActions(
  {
    publicLoadSliderRequest: ['payload'],
    publicLoadSliderSuccess: ['payload'],
    publicLoadSliderFailure: ['payload']
  },
  {}
)

const initialState = []

export function publicLoadSliderSuccess(state, action) {
  return [...action.payload]
}

export const HANDLERS = {
  [Types.PUBLIC_LOAD_SLIDER_SUCCESS]: publicLoadSliderSuccess
}

export const sliderTypes = Types
export const sliderActions = Creators
export const slider = createReducer(initialState, HANDLERS)
