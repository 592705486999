import React from 'react'

const CollapsibleCard = ({ label, id, className, children, icon }) => {
  return (
    <div className={className}>
      <div className='card shadow mb-2'>
        <a
          href={`#${id}`}
          className='d-block card-header py-3'
          data-toggle='collapse'
          role='button'
          aria-expanded='true'
          aria-controls={id}>
          <h6 className='m-0 font-weight-bold text-secondary'>
            <i className={icon} style={{ marginRight: 10 }} />
            <span>{label} </span>
          </h6>
        </a>
        <div className='collapse' id={id}>
          <div className='card-body'>{children}</div>
        </div>
      </div>
    </div>
  )
}

export default CollapsibleCard
