import CategoryCreate from './CategoryCreate'
import CategoryList from './CategoryList'
import CategoryEdit from './CategoryEdit'
import CategoryView from './CategoryView'

const routes = [
  { path: '/dashboard/category/list', component: CategoryList },
  { path: '/dashboard/category/create', component: CategoryCreate },
  { path: '/dashboard/category/edit/:id', component: CategoryEdit },
  { path: '/dashboard/category/view/:id', component: CategoryView }
]

export default routes
