import React from 'react'
import { connect } from 'react-redux'
import { useLocation } from 'react-router-dom'

function Acl({ user, action, children, module }) {
  const { pathname } = useLocation()
  const moduleName = module || pathname.split('/')[2]
  const can =
    user && user.permissions.find(p => p.module && p.module.name === moduleName && p[action])
  return can || (user && user.role === 0) ? <>{children}</> : null
}

Acl.defaultProps = {
  action: 'read'
}

const mapStateToProps = ({ auth }) => ({ user: auth.user })
export default connect(mapStateToProps)(Acl)
