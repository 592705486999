import { createActions, createReducer } from 'reduxsauce'
import * as handlers from './../../redux/helpers/handlers'

const { Types, Creators } = createActions(
  {
    publicLoadAllCampaignRequest: ['payload'],
    publicLoadAllCampaignSuccess: ['payload'],
    publicLoadAllCampaignFailure: ['payload'],

    publicLoadAllBannerCampaignRequest: ['payload'],
    publicLoadAllBannerCampaignSuccess: ['payload'],
    publicLoadAllBannerCampaignFailure: ['payload'],

    publicLoadCampaignRequest: ['payload'],
    publicLoadCampaignSuccess: ['payload'],
    publicLoadCampaignFailure: ['payload'],

    cleanCampaign: null,

    loadAllCampaignRequest: ['payload'],
    loadAllCampaignSuccess: ['payload'],
    loadAllCampaignFailure: ['payload'],

    loadCampaignRequest: ['payload'],
    loadCampaignSuccess: ['payload'],
    loadCampaignFailure: ['payload'],

    createCampaignRequest: ['payload'],
    createCampaignSuccess: ['payload'],
    createCampaignFailure: ['payload'],

    editCampaignRequest: ['payload'],
    editCampaignSuccess: ['payload'],
    editCampaignFailure: ['payload'],

    selectCampaingRequest: ['payload'],
    selectCampaingSuccess: ['payload'],
    selectCampaingFailure: ['payload'],

    updateCampaignRequest: ['payload'],
    updateCampaignSuccess: ['payload'],
    updateCampaignFailure: ['payload'],

    toggleCampaignRequest: ['payload'],
    toggleCampaignSuccess: ['payload'],
    toggleCampaignFailure: ['payload'],

    deleteCampaignRequest: ['payload'],
    deleteCampaignSuccess: ['payload'],
    deleteCampaignFailure: ['payload'],

    loadCampaignOptionsRequest: ['payload'],
    loadCampaignOptionsSuccess: ['payload'],
    loadCampaignOptionsFailure: ['payload'],

    //* CAMPAING'S COMMENTS *

    publicLoadAllCampaignCommentsRequest: ['payload'],
    publicLoadAllCampaignCommentsSuccess: ['payload'],
    publicLoadAllCampaignCommentsFailure: ['payload'],

    createCampaignCommentRequest: ['payload'],
    createCampaignCommentSuccess: ['payload'],
    createCampaignCommentFailure: ['payload'],

    deleteCampaignCommentRequest: ['payload'],
    deleteCampaignCommentSuccess: ['payload'],
    deleteCampaignCommentFailure: ['payload'],

    incrementCampaignVisitsRequest: ['slug'],
    incrementCampaignVisitsSuccess: ['visits'],
    incrementCampaignVisitsFailure: ['payload'],

    incrementCampaignLikesRequest: ['slug'],
    incrementCampaignLikesSuccess: ['likes'],
    incrementCampaignLikesFailure: ['payload']
  },
  {}
)

const initialState = {
  list: [],
  view: {},
  edit: {},
  options: {
    categories: [],
    institutions: []
  },
  gallery: [],
  banner: [],
  campaign: {
    comments: []
  }
}

export function selectCampaing(state, action) {
  const campaign = { ...state.gallery.find(campaign => campaign.slug === action.payload) }
  return { ...state, campaign }
}

export function publicLoadAllCampaign(state, action) {
  return { ...state, gallery: action.payload }
}

export function publicLoadAllBannerCampaign(state, action) {
  return { ...state, banner: action.payload }
}

export function loadAllCampaign(state, action) {
  return { ...state, list: [...action.payload] }
}

export function publicLoadAllCampaignComments(state, action) {
  const campaign = { ...state.campaign, comments: [...action.payload] }
  return { ...state, campaign }
}

export function loadCampaignOptions(state, action) {
  return { ...state, options: { ...action.payload } }
}

export function publicLoadCampaign(state, action) {
  const campaign = { ...state.campaign, ...action.payload }
  return { ...state, campaign }
}

export const HANDLERS = {
  [Types.PUBLIC_LOAD_ALL_CAMPAIGN_SUCCESS]: publicLoadAllCampaign,
  [Types.PUBLIC_LOAD_ALL_BANNER_CAMPAIGN_SUCCESS]: publicLoadAllBannerCampaign,
  [Types.PUBLIC_LOAD_CAMPAIGN_SUCCESS]: publicLoadCampaign,
  [Types.LOAD_ALL_CAMPAIGN_SUCCESS]: loadAllCampaign,
  [Types.LOAD_CAMPAIGN_SUCCESS]: handlers.loadSuccess,
  [Types.CREATE_CAMPAIGN_SUCCESS]: handlers.createSuccess,
  [Types.EDIT_CAMPAIGN_SUCCESS]: handlers.editSuccess,
  [Types.UPDATE_CAMPAIGN_SUCCESS]: handlers.updateSuccess,
  [Types.TOGGLE_CAMPAIGN_SUCCESS]: handlers.toggleSuccess,
  [Types.DELETE_CAMPAIGN_SUCCESS]: handlers.deleteSuccess,
  [Types.SELECT_CAMPAING_SUCCESS]: selectCampaing,
  [Types.LOAD_CAMPAIGN_OPTIONS_SUCCESS]: loadCampaignOptions,
  [Types.PUBLIC_LOAD_ALL_CAMPAIGN_COMMENTS_SUCCESS]: publicLoadAllCampaignComments,
  [Types.CLEAN_CAMPAIGN]: state => ({ ...state, campaign: {} }),
  [Types.INCREMENT_CAMPAIGN_VISITS_SUCCESS]: (state, action) => ({
    ...state,
    campaign: { ...state.campaign, visits: action.visits }
  }),
  [Types.INCREMENT_CAMPAIGN_LIKES_SUCCESS]: (state, action) => ({
    ...state,
    campaign: { ...state.campaign, likes: action.likes }
  })
}

export const campaignTypes = Types
export const campaignActions = Creators
export const campaign = createReducer(initialState, HANDLERS)
