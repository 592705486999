import React, { Fragment, useState } from 'react'
import CampaignInformationForm from './Forms/CampaignInformationForm'
import CampaignContentForm from './Forms/CampaignContentForm'
import CampaignSettingsForm from './Forms/CampaignSettingsForm'
import Stepper from 'react-stepper-horizontal'
import CampaignPreviewForm from './Forms/CampaignPreviewForm'

function WizardForm({ onSubmit, ...props }) {
  const [page, setPage] = useState(0)

  const nextPage = () => {
    setPage(page + 1)
  }

  const previousPage = () => {
    setPage(page - 1)
  }

  const setFormPage = (page, title) => ({
    title,
    onClick: () => {
      setPage(page)
    }
  })

  return (
    <Fragment>
      <div className='col-12' style={{ marginBottom: -70, paddingTop: 40 }}>
        <Stepper
          steps={[
            setFormPage(0, 'Información general'),
            setFormPage(1, 'Contenido'),
            setFormPage(2, 'Opciones'),
            setFormPage(3, 'Vista previa')
          ]}
          activeStep={page}
        />
      </div>
      {page === 0 && (
        <CampaignInformationForm {...props} onSubmit={nextPage} label='Alta de campaña' />
      )}
      {page === 1 && (
        <CampaignContentForm
          {...props}
          previousPage={previousPage}
          onSubmit={nextPage}
          label='Alta de campaña'
        />
      )}
      {page === 2 && (
        <CampaignSettingsForm
          {...props}
          previousPage={previousPage}
          onSubmit={nextPage}
          label='Alta de campaña'
        />
      )}
      {page === 3 && (
        <CampaignPreviewForm
          {...props}
          previousPage={previousPage}
          onSubmit={onSubmit}
          label='Alta de campaña'
        />
      )}
    </Fragment>
  )
}

export default WizardForm
