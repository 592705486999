// Type's
export const CLEAR_STATE_DONATION_PAYPAL = 'CLEAR_STATE_DONATION_PAYPAL'

export const LOAD_COUNTERS_REQUEST = 'LOAD_COUNTERS_REQUEST'
export const LOAD_COUNTERS_SUCCESS = 'LOAD_COUNTERS_SUCCESS'
export const LOAD_COUNTERS_FAILURE = 'LOAD_COUNTERS_FAILURE'

export const LOAD_ALL_DONATION_PAYPAL_PAGINATION_REQUEST =
  'LOAD_ALL_DONATION_PAYPAL_PAGINATION_REQUEST'
export const LOAD_ALL_DONATION_PAYPAL_PAGINATION_SUCCESS =
  'LOAD_ALL_DONATION_PAYPAL_PAGINATION_SUCCESS'
export const LOAD_ALL_DONATION_PAYPAL_PAGINATION_FAILURE =
  'LOAD_ALL_DONATION_PAYPAL_PAGINATION_FAILURE'

export const LOAD_ONE_DONATION_PAYPAL_REQUEST = 'LOAD_ONE_DONATION_PAYPAL_REQUEST'
export const LOAD_ONE_DONATION_PAYPAL_SUCCESS = 'LOAD_ONE_DONATION_PAYPAL_SUCCESS'
export const LOAD_ONE_DONATION_PAYPAL_FAILURE = 'LOAD_ONE_DONATION_PAYPAL_FAILURE'

export const LOAD_GRAPH_DONATION_PAYPAL_REQUEST = 'LOAD_GRAPH_DONATION_PAYPAL_REQUEST'
export const LOAD_GRAPH_DONATION_PAYPAL_SUCCESS = 'LOAD_GRAPH_DONATION_PAYPAL_SUCCESS'
export const LOAD_GRAPH_DONATION_PAYPAL_FAILURE = 'LOAD_GRAPH_DONATION_PAYPAL_FAILURE'

// Action's

export const graphDonationPaypalRequest = payload => ({
  type: LOAD_GRAPH_DONATION_PAYPAL_REQUEST,
  payload
})
export const graphDonationPaypalSuccess = payload => ({
  type: LOAD_GRAPH_DONATION_PAYPAL_SUCCESS,
  payload
})
export const graphDonationPaypalFailure = error => ({
  type: LOAD_GRAPH_DONATION_PAYPAL_FAILURE,
  error
})

export const clearStatePaypal = payload => ({ type: CLEAR_STATE_DONATION_PAYPAL, payload })

export const loadCountersPaypalRequest = (payload = 1) => ({
  type: LOAD_COUNTERS_REQUEST,
  payload
})
export const loadCountersPaypalSuccess = payload => ({
  type: LOAD_COUNTERS_SUCCESS,
  payload
})
export const loadCountersPaypalFailure = error => ({
  type: LOAD_COUNTERS_FAILURE,
  error
})

export const loadAllPaginationPaypalRequest = filters => ({
  type: LOAD_ALL_DONATION_PAYPAL_PAGINATION_REQUEST,
  filters
})
export const loadAllPaginationPaypalSuccess = payload => ({
  type: LOAD_ALL_DONATION_PAYPAL_PAGINATION_SUCCESS,
  payload
})
export const loadAllPaginationPaypalFailure = error => ({
  type: LOAD_ALL_DONATION_PAYPAL_PAGINATION_FAILURE,
  error
})

export const donationPaypalGetOneRequest = id => ({
  type: LOAD_ONE_DONATION_PAYPAL_REQUEST,
  id
})
export const donationPaypalGetOneSuccess = payload => ({
  type: LOAD_ONE_DONATION_PAYPAL_SUCCESS,
  payload
})
export const donationPaypalGetOneFailure = error => ({
  type: LOAD_ONE_DONATION_PAYPAL_FAILURE,
  error
})

// Reducer
const initialState = {
  list: [],
  graph: [],
  info: [],
  view: {},
  filters: {},
  loading: false,
  error: null
}

export function paypal(state = initialState, action) {
  switch (action.type) {
    case LOAD_GRAPH_DONATION_PAYPAL_SUCCESS:
      return { ...state, error: null, loading: false, graph: [...action.payload] }
    case LOAD_ALL_DONATION_PAYPAL_PAGINATION_SUCCESS:
      return { ...state, error: null, loading: false, list: { ...action.payload } }
    case LOAD_COUNTERS_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        info: { ...action.payload }
      }
    case LOAD_ONE_DONATION_PAYPAL_SUCCESS:
      return { ...state, error: null, loading: true, view: { ...action.payload } }
    case LOAD_COUNTERS_FAILURE:
    case LOAD_ALL_DONATION_PAYPAL_PAGINATION_FAILURE:
    case LOAD_ONE_DONATION_PAYPAL_FAILURE:
    case LOAD_GRAPH_DONATION_PAYPAL_FAILURE:
      return { ...state, loading: false, error: action.error }
    case LOAD_COUNTERS_REQUEST:
    case LOAD_ALL_DONATION_PAYPAL_PAGINATION_REQUEST:
    case LOAD_ONE_DONATION_PAYPAL_REQUEST:
    case LOAD_GRAPH_DONATION_PAYPAL_REQUEST:
      return { ...state, loading: true, error: null }
    case CLEAR_STATE_DONATION_PAYPAL:
      return initialState
    default:
      return state
  }
}
