import { useState } from 'react'
import axios from 'axios'
import { mdyhm } from 'util/dates'
import { downloadFile } from 'util/helpers'

export function useExportSelected(fileName, url) {
  const [selected, setSelected] = useState([])

  const handleOnSelect = (row, isSelect) => {
    isSelect
      ? setSelected([...selected, row._id])
      : setSelected(selected.filter(id => id !== row._id))
  }

  const handleOnSelectAll = (isSelect, rows) => {
    const ids = isSelect ? rows.map(row => row.id) : []
    setSelected(ids)
  }

  const handleExport = () => {
    axios({ url, method: 'POST', data: { selection: selected }, responseType: 'blob' })
      .then(downloadFile(`${fileName}_${mdyhm()}.xlsx`))
      .catch(console.error)
  }

  const selectRow = {
    mode: 'checkbox',
    clickToSelect: false,
    selected,
    onSelect: handleOnSelect,
    onSelectAll: handleOnSelectAll,
    hideSelectAll: true
  }

  return [selectRow, handleExport]
}
