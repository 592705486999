import React, { Component } from 'react'

export default class CarrouselIndicators extends Component {
  // Method to obtain a range of numbers
  //   [...Array(5).keys()];
  //  => [0, 1, 2, 3, 4]
  getRange = number => [...Array(number).keys()]

  render() {
    const { slides, target } = this.props

    return (
      <ol className='carousel-indicators'>
        {this.getRange(slides).map((slide, i) => (
          <li key={`carrouselSlide-${i}`} data-target={`#${target}`} data-slide-to={slide} />
        ))}
      </ol>
    )
  }
}
