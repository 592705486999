import React from 'react'
import FormWrapper from '../FormWrapper'
import { Button, Row } from 'reactstrap'
import { reduxForm } from 'redux-form'
import { RFInput } from 'components/Inputs/RFFields'
import { required } from 'util/form'

function FormMailer({ handleSubmit, previousPage, label }) {
  return (
    <FormWrapper title={label}>
      <Row style={{ minHeight: 287 }}>
        <RFInput name='mailer.auth.user' label='Correo' validate={[required]} />
        <RFInput
          name='mailer.auth.pass'
          type='password'
          label='Contraseña'
          validate={[required]}
        />
        <RFInput col='6' name='mailer.host' label='Host' validate={[required]} />
        <RFInput
          col='6'
          name='mailer.port'
          label='Puerto'
          type='number'
          validate={[required]}
        />
      </Row>
      <Button color='secondary' id='prev-button' onClick={previousPage}>
        Atrás
      </Button>
      <Button color='primary' id='next-button' onClick={handleSubmit}>
        Continuar
      </Button>
    </FormWrapper>
  )
}

export default reduxForm({
  form: 'initial-configuration',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true
})(FormMailer)
