import React, { Component } from 'react'
import { Container } from 'reactstrap'
import { connect } from 'react-redux'
import Navbar from './../Navbar/Navbar'
import Header from './../Header/Header'
import Footer from './../Footer/Footer'
import ProgressBar from 'components/ProgressBar/ProgressBar'
import Loading from 'components/Loading/Loading'
import Slider from 'components/Slider/Slider'

class Base extends Component {
  render() {
    const { children } = this.props
    return (
      <Container fluid className='px-0'>
        <Loading />
        <Navbar />
        <ProgressBar />
        <Header {...this.props.campaign} />
        {children}
        <section className='ftco-section py-5 bg-light campaign-slider'>
          <Slider />
        </section>
        <Footer />
      </Container>
    )
  }
}

const mapStateToProps = state => ({ campaigns: state.campaign.gallery })

export default connect(mapStateToProps)(Base)
