import { useEffect } from 'react'
import { connect } from 'react-redux'
import { alertActions } from './../Alert/alert.redux'
import * as a from './alert.helpers'
import { useHistory } from 'react-router-dom'

export function Alert({ alert, ...props }) {
  const history = useHistory()
  useEffect(() => {}, [alert])

  if (alert.success) {
    a.toast
      .fire(a.successConfig(alert.success))
      .then(() => props.alertClear())
      .catch(error => console.log(error))
  } else if (alert.warning) {
    a.toast
      .fire(a.warningConfig(alert.warning))
      .then(() => props.alertClear())
      .catch(error => console.log(error))
  } else if (alert.error) {
    a.toast
      .fire(a.errorConfig(alert.error))
      .then(() => props.alertClear())
      .catch(error => console.log(error))
  } else if (alert.prompt) {
    a.prompt()
      .then(historical => props.alertPromptHide(historical.value))
      .catch(error => console.log(error))
  } else if (alert.isEmpty) {
    a.isEmpty()
      .then(confirm => {
        if (confirm.isConfirmed === true) {
          history.push('./create')
        } else {
          history.goBack()
        }
      })
      .catch(error => console.log(error))
  } else if (alert.isEmptyGoBack) {
    a.isEmptyGoBack()
      .then(confirm => {
        if (confirm.isConfirmed === true) {
          history.goBack()
        }
      })
      .catch(error => console.log(error))
  }

  return null
}

const mapStateToProps = ({ alert }) => ({ alert })
const mapDispatchToProps = {
  alertClear: alertActions.alertClear,
  alertPromptHide: alertActions.alertPromptHide
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Alert)
