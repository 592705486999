import PagesList from './PagesList'
import PagesEdit from './PagesEdit'
import PagesView from './PagesView'

const routes = [
  { path: '/dashboard/pages/list', component: PagesList },
  { path: '/dashboard/pages/edit/:id', component: PagesEdit },
  { path: '/dashboard/pages/view/:id', component: PagesView }
]

export default routes
