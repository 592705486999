// @param  {string} base  the base string being concatenated
// @param  {string} param the param that will be concatenated to the base
// @return {string} concatenated string resulting
// @author [Jonathan Rodriguez]
const concatURL = (base, param) => (base += `${param}/`)
/**
 * Concat the url prefix with the passe args
 * Example:
 *    concatURL('/api/book')('bestsellers', 'genre', 'fantasy')
 *     => '/api/book/bestsellers/genre/fantasy'
 * ?  Reduce the amount of repeated url on sagas
 * @param   {string}     prefix Base URL string
 * @returns {function}   Function to pass the URL args
 */
export const baseURL = prefix => (...args) => args.reduce(concatURL, prefix)

/**
 * Summary. Parse the queryString to an object
 * @param  {string} queryString Query string
 * @return {object}  Query params object
 */
export const parseQuery = queryString => {
  let query = {}
  let pairs = (queryString[0] === '?' ? queryString.substr(1) : queryString).split('&')
  for (let i = 0; i < pairs.length; i++) {
    let pair = pairs[i].split('=')
    query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '')
  }
  return query
}
