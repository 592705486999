import { generateActiveIcon } from 'components/Table/helpers'
import { mdy } from './../../util/dates'

export const columns = [
  {
    dataField: 'name',
    text: 'Nombre',
    sort: true
  },
  {
    dataField: 'description',
    text: 'Descripción',
    sort: true
  },
  {
    dataField: 'updatedAt',
    text: 'Última modificación',
    sort: true,
    formatter: mdy
  },
  {
    dataField: 'createdAt',
    text: 'Creado',
    sort: true,
    formatter: mdy
  },
  {
    dataField: 'active',
    text: 'Activo',
    formatter: cell => generateActiveIcon(cell),
    sort: true
  }
]
