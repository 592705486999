import React from 'react'
import PropTypes from 'prop-types'

export const Checkbox = ({ input, ...props }) => {
  return <input type='checkbox' checked={input.value} {...props} {...input} />
}

Checkbox.propTypes = {
  name: PropTypes.string.isRequired,
  input: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired
  })
}
