/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import { Form, reduxForm } from 'redux-form'
import { connect } from 'react-redux'
import { Button } from 'reactstrap'
import { actions } from './auth.redux'
import { required, minLength, isMatch, validPassword, trim } from 'util/form'
import { RFInput } from 'components/Inputs/RFFields'
import { Banner } from 'layouts/Site/Layouts/Banner'

const minLength8 = minLength(8)
const matchPassword = isMatch('newPassword', 'Las contraseñas')

function ResetPassword(props) {
  useEffect(() => {
    if (props.user.changePassword === false) {
      props.history.push('/')
    }
  }, [])

  const submit = ({ password, newPassword }) => {
    const payload = { password, newPassword }
    props.resetPassword(payload)
  }

  return (
    <Banner admin>
      <div className='d-flex justify-content-center'>
        <div className='p-5 mt-5' style={{ width: 700, background: 'white' }}>
          <div className='text-center'>
            <h1 className='h4 text-gray-900 mb-4'>Cambiar contraseña provisional</h1>
          </div>
          <Form onSubmit={props.handleSubmit(submit)}>
            {/* Provisional password (string) */}
            <RFInput
              name='password'
              type='password'
              label='Contraseña provisional'
              validate={[required]}
              normalize={trim}
            />

            <RFInput
              name='newPassword'
              type='password'
              label='Nueva contraseña'
              text='Mínimo 8 carácteres con caracteres alfanumericos y carácteres especiales'
              validate={[required, minLength8, validPassword]}
              normalize={trim}
            />

            {/* Confirm new password (string) */}
            <RFInput
              name='confirm_newPassword'
              type='password'
              label='Confirmar nueva contraseña'
              validate={[required, matchPassword]}
              normalize={trim}
            />

            <Button
              block
              color='primary'
              type='submit'
              disabled={props.submitting || props.pristine}>
              Enviar
            </Button>
          </Form>
        </div>
      </div>
    </Banner>
  )
}

const ResetPasswordForm = reduxForm({
  form: 'reset-password',
  enableReinitialize: true
})(ResetPassword)

const mapStateToProps = ({ auth }) => ({ user: auth.user })
const mapDispatchToProps = {
  resetPassword: actions.resetPasswordRequest
}

export default connect(mapStateToProps, mapDispatchToProps)(ResetPasswordForm)
