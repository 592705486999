import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import moment from 'moment'
import Img from 'react-cool-img'

const Caption = ({ thumbnail, title, createdAt, slug }) => {
  return (
    <li>
      <figure>
        <Img src={thumbnail.path} alt={title} />
        <figcaption>
          <h3 className='pt-4'>{title}</h3>
          <span>{moment(createdAt).fromNow()}</span>
          <Link className='mt-3' to={`/campaigns/${slug}`}>
            Ver campaña
          </Link>
        </figcaption>
      </figure>
    </li>
  )
}

Caption.propTypes = {
  thumbnail: PropTypes.object
}

export default Caption
