// Type's
export const CLEAR_STATE_DONATION_OXXO_PAY = 'CLEAR_STATE_DONATION_OXXO_PAY'

export const LOAD_COUNTERS_OXXO_PAY_REQUEST = 'LOAD_COUNTERS_OXXO_PAY_REQUEST'
export const LOAD_COUNTERS_OXXO_PAY_SUCCESS = 'LOAD_COUNTERS_OXXO_PAY_SUCCESS'
export const LOAD_COUNTERS_OXXO_PAY_FAILURE = 'LOAD_COUNTERS_OXXO_PAY_FAILURE'

export const LOAD_ALL_DONATION_OXXO_PAY_PAGINATION_REQUEST =
  'LOAD_ALL_DONATION_OXXO_PAY_PAGINATION_REQUEST'
export const LOAD_ALL_DONATION_OXXO_PAY_PAGINATION_SUCCESS =
  'LOAD_ALL_DONATION_OXXO_PAY_PAGINATION_SUCCESS'
export const LOAD_ALL_DONATION_OXXO_PAY_PAGINATION_FAILURE =
  'LOAD_ALL_DONATION_OXXO_PAY_PAGINATION_FAILURE'

export const LOAD_ONE_DONATION_OXXO_PAY_REQUEST = 'LOAD_ONE_DONATION_OXXO_PAY_REQUEST'
export const LOAD_ONE_DONATION_OXXO_PAY_SUCCESS = 'LOAD_ONE_DONATION_OXXO_PAY_SUCCESS'
export const LOAD_ONE_DONATION_OXXO_PAY_FAILURE = 'LOAD_ONE_DONATION_OXXO_PAY_FAILURE'

// Action's
export const clearStateOxxoPay = payload => ({ type: CLEAR_STATE_DONATION_OXXO_PAY, payload })

export const loadCountersOxxoPayRequest = (payload = 1) => ({
  type: LOAD_COUNTERS_OXXO_PAY_REQUEST,
  payload
})
export const loadCountersOxxoPaySuccess = payload => ({
  type: LOAD_COUNTERS_OXXO_PAY_SUCCESS,
  payload
})
export const loadCountersOxxoPayFailure = error => ({
  type: LOAD_COUNTERS_OXXO_PAY_FAILURE,
  error
})

export const loadAllPaginationOxxoPayRequest = filters => ({
  type: LOAD_ALL_DONATION_OXXO_PAY_PAGINATION_REQUEST,
  filters
})
export const loadAllPaginationOxxoPaySuccess = payload => ({
  type: LOAD_ALL_DONATION_OXXO_PAY_PAGINATION_SUCCESS,
  payload
})
export const loadAllPaginationOxxoPayFailure = error => ({
  type: LOAD_ALL_DONATION_OXXO_PAY_PAGINATION_FAILURE,
  error
})

export const donationOxxoPayGetOneRequest = id => ({
  type: LOAD_ONE_DONATION_OXXO_PAY_REQUEST,
  id
})
export const donationOxxoPayGetOneSuccess = payload => ({
  type: LOAD_ONE_DONATION_OXXO_PAY_SUCCESS,
  payload
})
export const donationOxxoPayGetOneFailure = error => ({
  type: LOAD_ONE_DONATION_OXXO_PAY_FAILURE,
  error
})

// Reducer
const initialState = {
  list: [],
  info: [],
  view: {},
  filters: {},
  loading: false,
  error: null
}

export function oxxo(state = initialState, action) {
  switch (action.type) {
    case LOAD_ALL_DONATION_OXXO_PAY_PAGINATION_SUCCESS:
      return { ...state, error: null, loading: false, list: { ...action.payload } }
    case LOAD_COUNTERS_OXXO_PAY_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        info: { ...action.payload }
      }
    case LOAD_ONE_DONATION_OXXO_PAY_SUCCESS:
      return { ...state, error: null, loading: true, view: { ...action.payload } }
    case LOAD_COUNTERS_OXXO_PAY_FAILURE:
    case LOAD_ALL_DONATION_OXXO_PAY_PAGINATION_FAILURE:
    case LOAD_ONE_DONATION_OXXO_PAY_FAILURE:
      return { ...state, loading: false, error: action.error }
    case LOAD_COUNTERS_OXXO_PAY_REQUEST:
    case LOAD_ALL_DONATION_OXXO_PAY_PAGINATION_REQUEST:
    case LOAD_ONE_DONATION_OXXO_PAY_REQUEST:
      return { ...state, loading: true, error: null }
    case CLEAR_STATE_DONATION_OXXO_PAY:
      return initialState
    default:
      return state
  }
}
