import axios from 'axios'
import { push } from 'connected-react-router'
import { all, takeLatest, call, put, take } from 'redux-saga/effects'
import { alertActions, alertTypes } from '././../Alert/alert.redux'
import { institutionActions, institutionTypes } from './institution.redux'
import { isEmpty } from './../../util/crud'

/**
 * Display the institutions list
 * @returns {iterator}
 */
export function* loadAllInstitution() {
  try {
    const url = '/api/institutions/'
    const { data: institutions } = yield call(axios.get, url)
    if (isEmpty(institutions)) {
      yield put(alertActions.alertMessageEmpty('institution', '/dashboard/institution/create'))
    }
    yield put(institutionActions.loadAllInstitutionSuccess(institutions))
  } catch (error) {
    yield put(institutionActions.loadAllInstitutionFailure(error))
    console.log(error)
  }
}

/**
 * Display a single institution record
 * @param   {object}   action.payload Data to take the id of the requested institution
 * @returns {iterator}
 */
export function* loadInstitution({ payload } = {}) {
  try {
    const { id } = payload
    const url = `/api/institutions/${id}`
    const { data: institution } = yield call(axios.get, url)
    yield put(institutionActions.loadInstitutionSuccess(institution))
  } catch (error) {
    yield put(institutionActions.loadInstitutionFailure(error))
    console.log(error)
  }
}

/**
 * Create an institution record
 * @param   {object}   action.payload Data to create an institution record
 * @returns {iterator}
 */
export function* createInstitution({ payload }) {
  try {
    // yield put(requestActions.requestPending())
    const url = '/api/institutions/'
    // Make the POST request
    const { data } = yield call(axios.post, url, payload)
    // Add new document to the list
    yield put(institutionActions.createInstitutionSuccess(data))
    // Show notification
    yield put(alertActions.alertMessageSuccess('Registro guardado'))
    // Return the user to the list
    yield put(push('/dashboard/institution/list'))
  } catch (error) {
    yield put(institutionActions.createInstitutionFailure(error))
    console.log(error)
  }
}

/**
 * Load the information of a single institution record to edit it
 * @param   {object}   action.payload Data to take the id of the requested institution
 * @returns {iterator}
 */
export function* editInstitution({ payload } = {}) {
  try {
    const { id } = payload
    const url = `/api/institutions/${id}`
    const { data: institution } = yield call(axios.get, url)
    yield put(institutionActions.editInstitutionSuccess(institution))
  } catch (error) {
    yield put(institutionActions.editInstitutionFailure(error))
    console.log(error)
  }
}

/**
 * Update an institution record
 * @param   {object}   action.payload Data to update an institution record
 * @returns {iterator}
 */
export function* updateInstitution({ payload }) {
  try {
    yield put(alertActions.alertPromptShow())
    const prompt = yield take(alertTypes.ALERT_PROMPT_HIDE)

    if (isEmpty(prompt.payload)) return

    const { id, values } = payload
    const url = `/api/institutions/${id}`

    const historical = { cause: 'Actualización', description: prompt.payload }
    const data = { payload: values, historical }
    const { data: updatedInstitution } = yield call(axios.put, url, data)
    yield put(institutionActions.updateInstitutionSuccess(updatedInstitution))
    // Success notification and return the user to the list
    yield put(alertActions.alertMessageSuccess('Registro actualizado'))
    yield put(push('/dashboard/institution/list'))
  } catch (error) {
    yield put(institutionActions.updateInstitutionFailure(error))
    console.log(error)
  }
}

/**
 * Toggle the active property of an institution record
 * @param   {object}   action.payload Data to update an institution record
 * @returns {iterator}
 */
export function* toggleInstitution({ payload }) {
  try {
    yield put(alertActions.alertPromptShow())
    const prompt = yield take(alertTypes.ALERT_PROMPT_HIDE)
    const description = prompt.payload

    // The prompt was closed, stop the flow
    if (isEmpty(description)) return

    const { _id, active } = payload
    const url = `/api/institutions/${_id}`

    // Make the PUT request
    const historical = { cause: active ? 'Desactivación' : 'Activación', description }
    const { data: updatedInstitution } = yield call(axios.put, url, {
      payload: { active: !active },
      historical
    })
    yield put(institutionActions.toggleInstitutionSuccess(updatedInstitution))

    // Show success notification
    const result = updatedInstitution.active ? 'activado' : 'desactivado'
    yield put(alertActions.alertMessageSuccess(`Registro ${result}`))
    yield put(push('/dashboard/institution/list'))
  } catch (error) {
    yield put(institutionActions.toggleInstitutionFailure(error))
    console.log(error)
  }
}

/**
 * Validate an institution record
 * @param     values
 * @returns
 */
export async function validateInstitution(values) {
  const url = '/api/institutions/validate'
  const { data: result } = await axios.post(url, values)
  if (!isEmpty(result.errors)) throw result.errors
}

export function* institutionSagas() {
  yield all([
    takeLatest(institutionTypes.LOAD_ALL_INSTITUTION_REQUEST, loadAllInstitution),
    takeLatest(institutionTypes.LOAD_INSTITUTION_REQUEST, loadInstitution),
    takeLatest(institutionTypes.CREATE_INSTITUTION_REQUEST, createInstitution),
    takeLatest(institutionTypes.EDIT_INSTITUTION_REQUEST, editInstitution),
    takeLatest(institutionTypes.UPDATE_INSTITUTION_REQUEST, updateInstitution),
    takeLatest(institutionTypes.TOGGLE_INSTITUTION_REQUEST, toggleInstitution)
  ])
}
