// Type's
export const CLEAR_STATE_CAMPAIGN = 'CLEAR_STATE_CAMPAIGN'

export const LOAD_SEARCH_CAMPAIGN_PAGINATION_REQUEST =
  'LOAD_SEARCH_CAMPAIGN_PAGINATION_REQUEST'
export const LOAD_SEARCH_CAMPAIGN_PAGINATION_SUCCESS =
  'LOAD_SEARCH_CAMPAIGN_PAGINATION_SUCCESS'
export const LOAD_SEARCH_CAMPAIGN_PAGINATION_FAILURE =
  'LOAD_SEARCH_CAMPAIGN_PAGINATION_FAILURE'
export const LOAD_SEARCH_CAMPAIGN_PAGINATION_INFORMATION_SUCCESS =
  'LOAD_SEARCH_CAMPAIGN_PAGINATION_INFORMATION_SUCCESS'

export const LOAD_ALL_CAMPAIGN_PAGINATION_REQUEST = 'LOAD_ALL_CAMPAIGN_PAGINATION_REQUEST'
export const LOAD_ALL_CAMPAIGN_PAGINATION_SUCCESS = 'LOAD_ALL_CAMPAIGN_PAGINATION_SUCCESS'
export const LOAD_ALL_CAMPAIGN_PAGINATION_FAILURE = 'LOAD_ALL_CAMPAIGN_PAGINATION_FAILURE'
export const LOAD_ALLL_CAMPAIGN_PAGINATION_INFORMATION_SUCCESS =
  'LOAD_ALL_HISTORICAL_CAMPAIGN_PAGINATION_INFORMATION_SUCCESS'

// Action's
export const clearStateCampaign = payload => ({ type: CLEAR_STATE_CAMPAIGN, payload })

export const loadSearchPaginationCampaignRequest = (payload = 1, query) => ({
  type: LOAD_SEARCH_CAMPAIGN_PAGINATION_REQUEST,
  payload,
  query
})
export const loadSearchPaginationCampaignSuccess = payload => ({
  type: LOAD_SEARCH_CAMPAIGN_PAGINATION_SUCCESS,
  payload
})
export const loadSearchPaginationCampaignInformationSuccess = payload => ({
  type: LOAD_SEARCH_CAMPAIGN_PAGINATION_INFORMATION_SUCCESS,
  payload
})
export const loadSearchPaginationCampaignFailure = error => ({
  type: LOAD_SEARCH_CAMPAIGN_PAGINATION_FAILURE,
  error
})

export const publicScrollPaginationCampaignRequest = (payload = 1) => ({
  type: LOAD_ALL_CAMPAIGN_PAGINATION_REQUEST,
  payload
})
export const publicScrollPaginationCampaignSuccess = payload => ({
  type: LOAD_ALL_CAMPAIGN_PAGINATION_SUCCESS,
  payload
})
export const publicScrollPaginationCampaignInformationSuccess = payload => ({
  type: LOAD_ALLL_CAMPAIGN_PAGINATION_INFORMATION_SUCCESS,
  payload
})
export const publicScrollPaginationCampaignFailure = error => ({
  type: LOAD_ALL_CAMPAIGN_PAGINATION_FAILURE,
  error
})

// Reducer
const initialState = {
  listCampaign: [],
  infoCampaign: [],
  search: false,
  loading: false,
  error: null
}

export function campaign(state = initialState, action) {
  switch (action.type) {
    case LOAD_SEARCH_CAMPAIGN_PAGINATION_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        search: true,
        listCampaign: [...state.listCampaign, ...action.payload.docs.data]
      }
    case LOAD_ALL_CAMPAIGN_PAGINATION_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        search: false,
        listCampaign: [...state.listCampaign, ...action.payload.docs.data]
      }
    case LOAD_SEARCH_CAMPAIGN_PAGINATION_INFORMATION_SUCCESS:
    case LOAD_ALLL_CAMPAIGN_PAGINATION_INFORMATION_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        infoCampaign: { ...action.payload }
      }
    case LOAD_SEARCH_CAMPAIGN_PAGINATION_FAILURE:
    case LOAD_ALL_CAMPAIGN_PAGINATION_FAILURE:
      return { ...state, loading: false, search: false, error: action.error }
    case LOAD_SEARCH_CAMPAIGN_PAGINATION_REQUEST:
      return { ...state, loading: true, search: true, error: null }
    case LOAD_ALL_CAMPAIGN_PAGINATION_REQUEST:
      return { ...state, loading: true, search: false, error: null }
    case CLEAR_STATE_CAMPAIGN:
      return initialState
    default:
      return state
  }
}
