/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import moment from 'moment'

import { DonationPanel } from 'components/Donation/DonationPanel'
import CommentsList from 'components/Comments/CommentsList'
import { campaignActions } from './campaign.redux'
import Base from 'layouts/Site/Layouts/Base'
import { isEmpty } from './../../util/helpers'
import LikeButton from 'components/Buttons/LikeButton'
import ViewButton from 'components/Buttons/ViewsButton'
import { SocialMediaShareBar } from 'components/SocialMedia/SocialMediaShareBar'

export const Campaing = ({ campaign, campaigns, user, ...props }) => {
  useEffect(() => {
    const { slug } = props.match.params
    props.incrementVisits(slug)
    props.selectCampaign(slug)
    props.loadCampaign(slug)
    props.loadCampaignComments(slug)
    return () => {
      props.cleanCampaign()
    }
  }, [props.match.params.slug])

  const endDate = moment(campaign.date).add('days', campaign.duration)
  const hours = moment(endDate).diff(new Date(), 'hours')
  const days = moment(endDate).diff(new Date(), 'days')

  const message = `Amigos, apoyemos el proyecto "${campaign.title}" desde esta página, solo quedan ${days} días para que finalice.`
  const shareUrl = window.location.href

  if (isEmpty(campaign.visibility)) return null

  return (
    <Base campaign={campaign}>
      <section id='campaign-individual' className='ftco-section'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-7'>
              <div
                className='campaign-content'
                dangerouslySetInnerHTML={{ __html: campaign.content }}
              />
              <div className='mt-5' style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <LikeButton />
                <ViewButton count={campaign.visits} />
              </div>
              {campaign.visibility?.comments && <CommentsList />}
            </div>
            <div className='col-lg-4 col-md-5 offset-lg-1 sidebar'>
              {campaign.goal > 0 && <DonationPanel {...campaign} hours={hours} days={days} />}
              <SocialMediaShareBar shareUrl={shareUrl} message={message} />
              <CampaingTags tags={campaign.category} />
            </div>
          </div>
        </div>
      </section>
    </Base>
  )
}

const CampaingTag = ({ title }) => (
  <Link to='/campaigns' className='tag-cloud-link'>
    {title}
  </Link>
)

const CampaingTags = ({ tags = [] }) => (
  <div className='tag-widget post-tag-container mb-5'>
    <div className='tagcloud'>
      {tags.map((item, i) => (
        <CampaingTag key={`campaign-tag-${i}`} {...item} />
      ))}
    </div>
  </div>
)

Campaing.propTypes = {
  campaign: PropTypes.object
}

const mapStateToProps = ({ campaign, auth }) => ({
  campaign: campaign.campaign,
  campaigns: campaign.gallery,
  user: auth.user
})

const mapDispatchToProps = {
  selectCampaign: campaignActions.selectCampaingSuccess,
  loadCampaign: campaignActions.publicLoadCampaignRequest,
  loadCampaignComments: campaignActions.publicLoadAllCampaignCommentsRequest,
  cleanCampaign: campaignActions.cleanCampaign,
  incrementVisits: campaignActions.incrementCampaignVisitsRequest
}

export default connect(mapStateToProps, mapDispatchToProps)(Campaing)
