import { createActions, createReducer } from 'reduxsauce'
import * as handlers from './../../redux/helpers/handlers'

const { Types, Creators } = createActions(
  {
    loadAllPagesRequest: ['payload'],
    loadAllPagesSuccess: ['payload'],
    loadAllPagesFailure: ['payload'],

    publicLoadPageRequest: ['name'],
    publicLoadPageSuccess: ['payload'],
    publicLoadPageFailure: ['payload'],

    editPagesRequest: ['payload'],
    editPagesSuccess: ['payload'],
    editPagesFailure: ['payload'],

    updatePagesRequest: ['payload'],
    updatePagesSuccess: ['payload'],
    updatePagesFailure: ['payload']
  },
  {}
)

const initialState = {
  list: [],
  edit: {},
  page: {}
}

export const HANDLERS = {
  [Types.LOAD_ALL_PAGES_SUCCESS]: handlers.loadAllSuccess,
  [Types.EDIT_PAGES_SUCCESS]: handlers.editSuccess,
  [Types.UPDATE_PAGES_SUCCESS]: handlers.updateSuccess,
  [Types.PUBLIC_LOAD_PAGE_SUCCESS]: function (state, action) {
    return { ...state, page: { ...action.payload } }
  }
}

export const pagesTypes = Types
export const pagesActions = Creators
export const pages = createReducer(initialState, HANDLERS)
