import moment from 'moment'
import { createActions, createReducer } from 'reduxsauce'

const { Types, Creators } = createActions(
  {
    loadAllHistoricalRequest: ['payload'],
    loadAllHistoricalSuccess: ['payload'],
    loadAllHistoricalFailure: ['payload'],

    loadHistoricalByUserRequest: ['payload'],
    loadHistoricalByUserSuccess: ['payload'],
    loadHistoricalByUserFailure: ['payload'],

    setDateHistorical: ['payload']
  },
  {}
)

const initialState = {
  view: {},
  list: [],
  user: {},
  filtered: [],
  filter: new Date()
}

function filterByDate(list, filter) {
  return list.filter(item => {
    const date = moment(item.createdAt)
    const isSameDay = moment(filter).isSame(date, 'day')
    return isSameDay
  })
}

export const HANDLERS = {
  [Types.LOAD_ALL_HISTORICAL_SUCCESS]: function (state, action) {
    return {
      ...state,
      list: [...action.payload],
      filtered: filterByDate(action.payload, state.filter)
    }
  },
  [Types.LOAD_HISTORICAL_BY_USER_SUCCESS]: function (state, action) {
    return {
      ...state,
      list: [...action.payload.list],
      filtered: [...action.payload.list],
      user: { ...action.payload.user }
    }
  },
  [Types.SET_DATE_HISTORICAL]: function (state, action) {
    return { ...state, filtered: filterByDate(state.list, action.payload) }
  }
}

export const historicalTypes = Types
export const historicalActions = Creators
export const historical = createReducer(initialState, HANDLERS)
