import { createReducer, createActions } from 'reduxsauce'

const { Creators, Types } = createActions(
  {
    alertMessageSuccess: ['payload'],
    alertMessageWarning: ['payload'],
    alertMessageError: ['payload'],
    alertMessageEmpty: ['payload'],
    alertMessageEmptyGoBack: ['payload'],
    alertPromptShow: ['payload'],
    alertPromptHide: ['payload'],
    alertClear: ['payload']
  },
  {}
)

const initialState = {
  justification: '',
  success: '',
  warning: '',
  error: '',
  prompt: false
}

export const HANDLERS = {
  [Types.ALERT_MESSAGE_SUCCESS]: (state, action) => ({
    ...initialState,
    success: action.payload
  }),
  [Types.ALERT_MESSAGE_WARNING]: (state, action) => ({
    ...initialState,
    warning: action.payload
  }),
  [Types.ALERT_MESSAGE_ERROR]: (state, action) => ({
    ...initialState,
    error: action.payload
  }),
  [Types.ALERT_MESSAGE_EMPTY]: (state, action) => ({
    ...initialState,
    isEmpty: action.payload
  }),
  [Types.ALERT_MESSAGE_EMPTY_GO_BACK]: (state, action) => ({
    ...initialState,
    isEmptyGoBack: action.payload
  }),
  [Types.ALERT_PROMPT_SHOW]: (state, action) => ({
    ...initialState,
    prompt: true
  }),
  [Types.ALERT_PROMPT_HIDE]: (state, action) => ({
    ...initialState,
    prompt: false,
    justification: action.payload
  }),
  [Types.ALERT_CLEAR]: (state, action) => ({ ...initialState })
}

export const alertTypes = Types
export const alertActions = Creators
export const alert = createReducer(initialState, HANDLERS)
