import React from 'react'
import FormWrapper from '../FormWrapper'
import { Button, Row } from 'reactstrap'
import { reduxForm } from 'redux-form'
import { RFInput } from 'components/Inputs/RFFields'

function FormProviders({ handleSubmit, previousPage, label, provider }) {
  return (
    <FormWrapper title={label}>
      <Row style={{ minHeight: 287 }}>
        <RFInput name={`keys.${provider}.client`} label='Key' />
        <RFInput name={`keys.${provider}.secret`} label='Secret Key' />
        <div className='px-4'>
          <strong>Importante</strong>
          <p>
            Al no proporcionar las credenciales de este servicio quedará inhabilitado por lo
            que no aparecerán botones o funcionalidades relacionados a este servicio, en caso
            de no contar con esta información. Una vez que que cuente con ella puede activarla
            en la sección de configuración.
          </p>
        </div>
      </Row>
      <Button color='secondary' id='prev-button' onClick={previousPage}>
        Atrás
      </Button>
      <Button color='primary' id='next-button' onClick={handleSubmit}>
        Continuar
      </Button>
    </FormWrapper>
  )
}

export default reduxForm({
  form: 'initial-configuration',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true
})(FormProviders)
