/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { columns } from './campaign.helpers'
import { campaignActions } from './campaign.redux'
import Banner from 'layouts/Site/Layouts/Banner'
import DataTable from 'components/Table/Table'
import TableContainer from 'components/Container/TableContainer'
import { useExportSelected } from 'hooks/useExportSelected'

function CampaignList(props) {
  const [selectRow, handleExport] = useExportSelected('Campañas', '/api/campaigns/export')

  useEffect(() => {
    props.loadAllCampaign()
  }, [])

  const rowEvents = {
    onClick: (e, row) => {
      props.history.push(`/dashboard/campaign/view/${row._id}`)
    }
  }

  const structure = columns.filter(item => item.dataField !== 'goal')
  const canDonate = props.config.providers.conekta || props.config.providers.paypal

  return (
    <Banner>
      <TableContainer title='Campañas' subtitle='Lista'>
        <DataTable
          {...props}
          rowEvents={rowEvents}
          data={props.campaigns}
          structure={canDonate ? columns : structure}
          onExport={handleExport}
          selectRow={selectRow}
          selected={selectRow.selected}
        />
      </TableContainer>
    </Banner>
  )
}

const mapStateToProps = ({ campaign, configuration }) => ({
  campaigns: campaign.list,
  config: configuration.public
})
const mapDispatchToProps = {
  loadAllCampaign: campaignActions.loadAllCampaignRequest,
  toggleCampaign: campaignActions.toggleCampaignRequest,
  exportCampaigns: campaignActions.exportCampaignsRequest
}

export default connect(mapStateToProps, mapDispatchToProps)(CampaignList)
