import React from 'react'
import { connect } from 'react-redux'
import {
  publicScrollPaginationCampaignRequest,
  loadSearchPaginationCampaignRequest
} from './../../modules/Campaign/campaignPagination.redux'

function ScrollGallery(props) {
  const { data, component, wrapper, search, infoCampaign } = props
  const currentData = data
  const Component = component
  const renderData = currentData.map(item => <Component key={item._id} {...item} />)
  const Wrapper = wrapper
  const nextPage = infoCampaign?.docs?.nextPage
  const title = infoCampaign?.docs?.searchValue

  const handleScroll = event => {
    const { target } = event
    if (target.scrollHeight - target.scrollTop <= target.clientHeight && nextPage) {
      if (search === true) {
        props.loadSearchPaginationCampaignRequest(nextPage, title)
      }
      if (search === false) {
        props.publicScrollPaginationCampaignRequest(nextPage)
      }
    }
  }

  return (
    <div>
      <div onScroll={handleScroll} className='row card-deck mx-auto mb-6 campaign-container'>
        <Wrapper>{renderData}</Wrapper>
      </div>
    </div>
  )
}

const mapStateToProps = ({ campaignPagination }) => ({
  infoCampaign: campaignPagination.infoCampaign,
  search: campaignPagination.search,
  SearchVale: campaignPagination.SearchVale
})
const mapDispatchToProps = {
  publicScrollPaginationCampaignRequest,
  loadSearchPaginationCampaignRequest
}

export default connect(mapStateToProps, mapDispatchToProps)(ScrollGallery)
