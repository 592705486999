import React from 'react'
import { Form, Container, Col, Row } from 'reactstrap'
import { reduxForm } from 'redux-form'
import { RFInput, RFCheckbox, RFUploader } from 'components/Inputs/RFFields'
import { withForm } from 'util/withForm'
import CollapsibleCard from 'components/Cards/CollapsibleCard'
import { substring, required, isEmail } from 'util/form'

const limit20 = substring(0, 20)
const limit35 = substring(0, 35)
const limit100 = substring(0, 100)
const limit500 = substring(0, 500)

function ConfigurationForm(props) {
  const { handleSubmit, onCancel, label, button, option, ...others } = props
  const { pristine, valid, submitting } = others

  return (
    <div className='col-12 my-5 pt-5'>
      <div className='header-h2'>
        <h2 className='mb-3 text-left'>Configuración</h2>
      </div>

      <Form>
        <CollapsibleCard label='Configuración general*' id='config-general' icon='icon-cog'>
          <Container className='my-2'>
            <Row>
              <RFInput
                type='text'
                name='settings.name'
                label='Nombre*'
                validate={[required]}
                normalize={limit35}
              />
              <RFUploader name='settings.logo' label='Logo' />
              <RFInput
                type='textarea'
                name='settings.gratitudeMessage'
                label='Mensaje de agradecimiento por donación'
                normalize={limit500}
              />
            </Row>
          </Container>
        </CollapsibleCard>
        <CollapsibleCard label='Página de inicio*' id='config-home' icon='icon-home'>
          <Container className='my-2'>
            <RFInput
              type='textarea'
              name='home.content'
              label='Nuestra misión'
              normalize={limit500}
            />
            <RFUploader name='home.image' label='Imagen lateral' />
            <RFCheckbox
              className='mx-3'
              name='home.showCounters'
              label='Mostrar contadores en página de inicio'
            />
          </Container>
        </CollapsibleCard>
        <CollapsibleCard
          label='Información de contacto*'
          id='config-contact'
          icon='icon-phone'>
          <Container className='my-2'>
            <Row>
              <Col md='6'>
                <RFInput
                  type='email'
                  name='contact.email'
                  label='Correo electrónico*'
                  validate={[required, isEmail]}
                  normalize={limit100}
                />
              </Col>
              <Col md='6'>
                <RFInput
                  type='text'
                  name='contact.phone'
                  label='Teléfono*'
                  validate={[required]}
                  normalize={limit20}
                />
              </Col>
              <Col md='12'>
                <RFInput
                  type='text'
                  name='contact.address'
                  label='Dirección*'
                  validate={[required]}
                  normalize={limit100}
                />
              </Col>
            </Row>
          </Container>
        </CollapsibleCard>
        <CollapsibleCard
          label='Configuración de correo electrónico'
          id='config-mailer'
          icon='icon-envelope'>
          <Container>
            <Row>
              <RFInput name='mailer.auth.user' label='Email' col='7' />
              <RFInput name='mailer.auth.pass' type='password' label='Contraseña' col='5' />
              <RFInput name='mailer.host' label='Host' col='7' />
              <RFInput name='mailer.port' type='number' label='Puerto' col='5' />
            </Row>
            <ConfigurationHelp>
              Configura esta sección para poder enviar correos a tus usuarios desde la
              plataforma.
            </ConfigurationHelp>
          </Container>
        </CollapsibleCard>
        <CollapsibleCard
          label='Configuración de redes sociales'
          id='config-social'
          icon='icon-comment'>
          <Container>
            <Row>
              <RFInput name='urls.facebook' label='Página de Facebook' col='6' />
              <RFInput name='urls.twitter' label='Página de Twitter' col='6' />
              <RFInput name='urls.instagram' label='Página de Instagram' col='6' />
            </Row>
            <ConfigurationHelp>
              Configura esta sección para que los usuarios puedan acceder a las páginas en
              redes sociales.
            </ConfigurationHelp>
          </Container>
        </CollapsibleCard>
        <CollapsibleCard
          label='Llaves de autorización de Facebook'
          id='config-fb'
          icon='icon-facebook'>
          <Container className='my-2'>
            <Row>
              <RFInput name='keys.facebook.client' label='Facebook client' col='6' />
              <RFInput name='keys.facebook.secret' label='Facebook secret' col='6' />
            </Row>
            <ConfigurationHelp>
              Configura esta sección para permitir el acceso a tus usuarios utilizando su
              cuenta de Facebook.
            </ConfigurationHelp>
          </Container>
        </CollapsibleCard>
        <CollapsibleCard
          label='Llaves de autorización de Twitter'
          id='config-tw'
          icon='icon-twitter'>
          <Container className='my-2'>
            <Row>
              <RFInput name='keys.twitter.client' label='Twitter client' col='6' />
              <RFInput name='keys.twitter.secret' label='Twitter secret' col='6' />
            </Row>
          </Container>
          <ConfigurationHelp>
            Configura esta sección para permitir el acceso a tus usuarios utilizando su cuenta
            de Twitter.
          </ConfigurationHelp>
        </CollapsibleCard>
        <CollapsibleCard
          label='Llaves de autorización de Google'
          id='config-go'
          icon='icon-google'>
          <Container className='my-2'>
            <Row>
              <RFInput name='keys.google.client' label='Google client' col='6' />
              <RFInput name='keys.google.secret' label='Google secret' col='6' />
            </Row>
            <ConfigurationHelp>
              Configura esta sección para permitir el acceso a tus usuarios utilizando su
              cuenta de Google.
            </ConfigurationHelp>
          </Container>
        </CollapsibleCard>
        <CollapsibleCard label='Llaves de PayPal' id='config-paypal' icon='icon-paypal'>
          <Container className='my-2'>
            <Row>
              <RFInput name='keys.paypal.client' label='PayPal client' col='6' />
              <RFInput name='keys.paypal.secret' label='PayPal secret' col='6' />
            </Row>
            <ConfigurationHelp>
              Configura esta sección para recibir donaciones mediante PayPal en tus campañas.
            </ConfigurationHelp>
          </Container>
        </CollapsibleCard>
        <CollapsibleCard label='Llaves de Conekta' id='config-conekta' icon='icon-key'>
          <Container className='my-2'>
            <Row>
              <RFInput name='keys.conekta.client' label='Conekta client' col='6' />
              <RFInput name='keys.conekta.secret' label='Conekta secret' col='6' />
            </Row>
            <ConfigurationHelp>
              Configura esta sección para recibir donaciones mediante Tarjetas de crédito u
              OXXO Pay.
            </ConfigurationHelp>
          </Container>
        </CollapsibleCard>
      </Form>
      <button
        className='btn btn-info p-2 px-3 mt-2 mb-4 float-right'
        onClick={handleSubmit}
        disabled={pristine || submitting || !valid}>
        Guardar cambios
      </button>
    </div>
  )
}

function ConfigurationHelp(props) {
  return (
    <div className='mt-1'>
      <i className='icon-help mr-3' />
      <span>{props.children}</span>
    </div>
  )
}

export default withForm(reduxForm({ form: 'configuration' })(ConfigurationForm))
