/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import moment from 'moment'
import { Link } from 'react-router-dom'
import BaseViewPreview from '../CampaignViewPreview/BaseViewPreview'
import { DonationPanelPreview } from './DonationPanelPreview'
import { SocialMediaShareBar } from 'components/SocialMedia/SocialMediaShareBar'

export const CampaingViewPreview = ({ campaign }) => {
  const endDate = moment(campaign.createdAt).add('days', campaign.duration)
  const hours = moment(endDate).diff(new Date(), 'hours')
  const days = moment(endDate).diff(new Date(), 'days')

  return (
    <BaseViewPreview campaign={campaign}>
      <section id='campaign-individual' className='ftco-section'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-7'>
              <div
                className='campaign-content'
                dangerouslySetInnerHTML={{ __html: campaign.content }}
              />
              <div className='mt-5' style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <LikeButtonPreview count='5' />
                <ViewButtonPreview count='2' />
              </div>
            </div>
            <div className='col-lg-4 col-md-5 offset-lg-1 sidebar'>
              {campaign.goal > 0 && (
                <DonationPanelPreview {...campaign} hours={hours} days={days} />
              )}
              <div className='pointer-events-none d-block mb-4'>
                <SocialMediaShareBar />
              </div>
              <CampaingTags tags={campaign.category} />
            </div>
          </div>
        </div>
      </section>
    </BaseViewPreview>
  )
}

const CampaingTag = ({ label }) => (
  <Link to='/campaigns' className='tag-cloud-link'>
    {label}
  </Link>
)

const CampaingTags = ({ tags = [] }) => (
  <div className='tag-widget post-tag-container'>
    <div className='tagcloud'>
      {tags.map((item, i) => (
        <CampaingTag key={`campaign-tag-${i}`} {...item} />
      ))}
    </div>
  </div>
)

const LikeButtonPreview = ({ count }) => {
  return (
    <div className='like__btn animated'>
      <i className='like__icon icon-heart' />
      <span className='like__number'>{count}</span>
    </div>
  )
}

const ViewButtonPreview = ({ count }) => {
  return (
    <div className='views__btn animated'>
      <i className='views__icon icon-eye'></i>
      <span className='views__number'>{count}</span>
    </div>
  )
}

export default CampaingViewPreview
