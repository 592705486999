import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { donationPaypalGetOneRequest } from './paypal.redux'
import { Banner } from 'layouts/Site/Layouts/Banner'
import { Row, Col } from 'reactstrap'
import { mdy } from './../../util/dates'
import { isEmpty } from './../../util/crud'
import TableContainer from 'components/Container/TableContainer'
import { ListItem } from 'components/Information/ListItem'
import { useParams } from 'react-router-dom'

function PaypalDonationView(props) {
  const { view } = props
  const location = useParams()

  useEffect(() => {
    props.donationPaypalGetOneRequest(location.id)
  }, [])

  return (
    <Banner>
      <TableContainer
        showButtons={false}
        title='Donación Paypal'
        subtitle='Información del registro'
        view={view}>
        {!isEmpty(view) && (
          <Row id='view-donation'>
            <Col>
              <ul>
                <ListItem label='Estatus' value={view.status} />
                <ListItem
                  label='Nombre'
                  value={`${view.purchase_units[0].shipping.name.full_name}`}
                />
                <ListItem
                  label='Correo'
                  value={`${view.purchase_units[0].payee.email_address}`}
                />
                <ListItem
                  label='Monto'
                  value={`${view.purchase_units[0].amount.value} ${view.purchase_units[0].amount.currency_code}`}
                />
                <ListItem
                  label='Cargo'
                  value={`${view.purchase_units[0].payments.captures[0].seller_receivable_breakdown.paypal_fee.value} ${view.purchase_units[0].amount.currency_code}`}
                />
                <ListItem label='Fecha' value={mdy(view.update_time)} />
              </ul>
            </Col>
          </Row>
        )}
      </TableContainer>
    </Banner>
  )
}

const mapStateToProps = ({ paypal }) => ({ view: paypal.view })

const mapDispatchToProps = {
  donationPaypalGetOneRequest
}

export default connect(mapStateToProps, mapDispatchToProps)(PaypalDonationView)
