import React from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import Img from 'react-cool-img'

function CardSm({ slug, url, title, thumbnail, description }) {
  return (
    <div className='card m-1 h-100'>
      <Link to={`${url}/${slug}`}>
        <Img
          className='card-img-top'
          src={thumbnail.path}
          style={{ objectFit: 'cover' }}
          alt=''
        />
      </Link>
      <div className='card-body'>
        <Link className='no-style' to={`${url}/${slug}`}>
          <span className='heading'>{title}</span>
          <div>
            <div style={{ fontSize: '13px', color: 'rgb(165 165 165)' }}>
              <span>{description}</span>
            </div>
          </div>
        </Link>
      </div>
    </div>
  )
}

CardSm.propTypes = {
  id: PropTypes.string,
  url: PropTypes.string,
  title: PropTypes.string,
  thumbnail: PropTypes.string,
  author: PropTypes.string,
  description: PropTypes.number
}

export default CardSm
