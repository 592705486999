import React, { useState, useEffect } from 'react'
import OxxoPayStub from 'components/OxxoPay/OxxoPayStub'
import oxxopay from 'assets/images/oxxopay.svg'
import { Button, Modal, ModalHeader, ModalBody } from 'reactstrap'
import { donationActions } from 'modules/Donation/donation.redux'
import { connect } from 'react-redux'
import { reduxForm, Form } from 'redux-form'
import { RFInput } from 'components/Inputs/RFFields'
import Img from 'react-cool-img'

function OxxoPay({ amount, handleSubmit, campaign, requestOxxoPay }) {
  const [modal, setModal] = useState(false)
  const [reference, setReference] = useState('')
  const [barcode, setBarcode] = useState('')

  useEffect(() => {
    setReference('')
  }, [amount])

  const toggle = () => {
    setModal(!modal)
  }

  const onSubmit = values => {
    requestOxxoPay({ ...values, campaign, amount }, order => {
      const newReference = order.charges.data[0].payment_method.reference
      const barcode = order.charges.data[0].payment_method.barcode_url
      setReference(newReference)
      setBarcode(barcode)
    })
  }

  return (
    <div>
      <Button block onClick={toggle} className='oxxo-pay-btn my-2 btn-primary btn-block'>
        <Img width='50' className='mr-2' src={oxxopay} alt='conekta' />
        <span>Donar con OXXO Pay</span>
      </Button>
      <Modal size='md' isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Donación de ${amount} MXN con OXXO Pay</ModalHeader>
        <ModalBody>
          {reference === '' ? (
            <Form onSubmit={handleSubmit(onSubmit)}>
              <div className='form-group'>
                <RFInput
                  name='name'
                  required
                  type='text'
                  label='Nombre(s)'
                  className='form-control'
                />
                <RFInput
                  name='lastName'
                  required
                  type='text'
                  label='Apellido(s)'
                  className='form-control'
                />
                <RFInput
                  required
                  name='email'
                  type='email'
                  label='Correo electrónico'
                  className='form-control'
                />
                <RFInput
                  name='phone'
                  minlength='10'
                  maxlength='10'
                  required
                  type='tel'
                  label='Teléfono'
                  className='form-control'
                />
              </div>
              <div className='form-group'>
                <input type='submit' value='Continuar' className='btn btn-primary py-3 px-5' />
              </div>
            </Form>
          ) : (
            <OxxoPayStub amount={amount} reference={reference} barcode={barcode} />
          )}
        </ModalBody>
      </Modal>
    </div>
  )
}

const withConnect = connect(null, {
  requestOxxoPay: donationActions.requestOxxoPayDonationRequest
})

export default reduxForm({ form: 'oxxo-pay' })(withConnect(OxxoPay))
