import React from 'react'
import logoDonadora from './../../assets/images/donadora.png'

function Donadora() {
  return (
    <div style={{ marginTop: '100px' }}>
      <div style={{ width: '100%', display: 'inline-block' }}>
        <h1 style={{ textAlign: 'center' }}>
          <span style={{ color: '#666ee8' }}>Tips para hacer donaciones</span>
          <br></br>
          Para las causas que quieres apoyar
        </h1>
      </div>
      <div>
        <img src={logoDonadora} height='280' alt='logoDonadora' id='logoDonadora' style={{ display: 'block', margin: 'auto' }} />
      </div>
      <div style={{ textAlign: 'justify', margin: '100px' }}>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Viverra ipsum nunc aliquet bibendum enim facilisis gravida neque convallis. Ipsum dolor sit amet consectetur adipiscing elit duis tristique sollicitudin. Viverra nam libero justo laoreet. Volutpat odio facilisis mauris sit amet massa vitae. Congue eu consequat ac felis donec et odio pellentesque diam. Placerat orci nulla pellentesque dignissim enim. At in tellus integer feugiat scelerisque. Enim praesent elementum facilisis leo vel fringilla est. Risus sed vulputate odio ut enim. Eleifend mi in nulla posuere sollicitudin aliquam ultrices sagittis orci. Enim diam vulputate ut pharetra sit amet aliquam. Accumsan tortor posuere ac ut consequat semper viverra nam. Quis ipsum suspendisse ultrices gravida dictum fusce ut placerat.
        </p>
        <p>
          Vitae turpis massa sed elementum tempus. Tristique risus nec feugiat in fermentum posuere urna nec. Pellentesque pulvinar pellentesque habitant morbi tristique. Dui sapien eget mi proin sed. Nulla malesuada pellentesque elit eget. Arcu non odio euismod lacinia at. Gravida dictum fusce ut placerat. Id aliquet risus feugiat in ante metus dictum at tempor. Tellus id interdum velit laoreet. Eget arcu dictum varius duis at consectetur lorem donec massa. Vel fringilla est ullamcorper eget. Id diam vel quam elementum. Euismod in pellentesque massa placerat duis. Molestie nunc non blandit massa enim nec dui nunc. Feugiat in ante metus dictum at tempor commodo. Volutpat sed cras ornare arcu dui. Ligula ullamcorper malesuada proin libero. Vel pretium lectus quam id leo. Amet mattis vulputate enim nulla aliquet porttitor lacus luctus.
        </p>
        <p>
          Sed viverra ipsum nunc aliquet. Nulla facilisi etiam dignissim diam quis enim. Dui accumsan sit amet nulla facilisi morbi tempus iaculis. Justo eget magna fermentum iaculis eu non diam. Ut pharetra sit amet aliquam id diam. Ut aliquam purus sit amet luctus venenatis lectus. Feugiat in fermentum posuere urna nec tincidunt praesent. Non consectetur a erat nam at lectus urna duis convallis. Et pharetra pharetra massa massa. Sem viverra aliquet eget sit amet tellus cras adipiscing. Molestie nunc non blandit massa. Enim sit amet venenatis urna cursus eget nunc. Proin nibh nisl condimentum id venenatis. Felis bibendum ut tristique et egestas. Faucibus pulvinar elementum integer enim neque volutpat ac tincidunt. Sit amet justo donec enim. Nisl pretium fusce id velit. Lectus proin nibh nisl condimentum id.
        </p>
        <p>
          Vitae proin sagittis nisl rhoncus mattis rhoncus urna. Praesent elementum facilisis leo vel fringilla est. Fermentum et sollicitudin ac orci phasellus egestas tellus rutrum. Luctus venenatis lectus magna fringilla urna porttitor rhoncus. In pellentesque massa placerat duis ultricies lacus sed turpis. Mauris sit amet massa vitae. Ut ornare lectus sit amet est placerat. Purus viverra accumsan in nisl nisi scelerisque eu. Vulputate mi sit amet mauris commodo. Arcu dictum varius duis at consectetur lorem donec massa sapien. Posuere morbi leo urna molestie at elementum eu facilisis. Suspendisse sed nisi lacus sed viverra tellus in hac. Augue mauris augue neque gravida in fermentum et. Nulla malesuada pellentesque elit eget gravida cum sociis natoque penatibus.
        </p>
        <p>
          Volutpat sed cras ornare arcu dui vivamus. Neque laoreet suspendisse interdum consectetur libero id faucibus nisl. Elementum nibh tellus molestie nunc non blandit massa enim nec. Magna eget est lorem ipsum. Ultrices in iaculis nunc sed augue lacus viverra vitae congue. Elementum facilisis leo vel fringilla est ullamcorper eget. Massa tincidunt nunc pulvinar sapien et ligula ullamcorper. Bibendum ut tristique et egestas quis. Feugiat pretium nibh ipsum consequat nisl vel pretium lectus. Quam nulla porttitor massa id. Sed lectus vestibulum mattis ullamcorper velit sed ullamcorper. Mattis molestie a iaculis at erat pellentesque adipiscing.
        </p>
        <center>
          <a
            style={{backgroundColor: '#9c27b0', border: '1px solid #b23cfd', color: '#ffffff'}}
            className='btn p-2 px-3 mt-2 mb-4'
            href='https://donadora.org/'>
            Ver más
          </a>
        </center>
      </div>
    </div>
  )
}

export default Donadora
