import React, { Component } from 'react'
import { connect } from 'react-redux'
import { institutionActions } from './institution.redux'
import { isEmpty } from './../../util/crud'
import InstitutionForm from './InstitutionForm'
import Banner from 'layouts/Site/Layouts/Banner'

class InstitutionEdit extends Component {
  componentDidMount() {
    const { id } = this.props.match.params
    this.props.editInstitutionRequest({ id })
  }

  componentWillUnmount() {
    this.props.editInstitutionSuccess()
  }

  onCancel = () => {
    this.props.history.push('/dashboard/institution/list')
  }

  submit = values => {
    const { id } = this.props.match.params
    this.props.updateInstitution({ id, values })
  }

  render() {
    const values = this.props.institution.edit

    return (
      <Banner>
        {!isEmpty(values) && (
          <InstitutionForm
            {...this.props}
            label='Actualizar'
            button='edit'
            onCancel={this.onCancel}
            onSubmit={this.submit}
            initialValues={values}
          />
        )}
      </Banner>
    )
  }
}

const mapStateToProps = ({ institution }) => ({ institution })
const mapDispatchToProps = {
  updateInstitution: institutionActions.updateInstitutionRequest,
  editInstitutionRequest: institutionActions.editInstitutionRequest,
  editInstitutionSuccess: institutionActions.editInstitutionSuccess
}

export default connect(mapStateToProps, mapDispatchToProps)(InstitutionEdit)
