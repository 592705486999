import Login from './Login'
import ForgotPassword from './ForgotPassword'
import RecoverPassword from './RecoverPassword'
import ResetPassword from './ResetPassword'

const routes = [
  { path: '/login', public: true, component: Login },
  { path: '/forgot-password', component: ForgotPassword, public: true },
  { path: '/recover-password', component: RecoverPassword, public: true },
  { path: '/reset-password', component: ResetPassword, bypass: true }
]

export default routes
