/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, Fragment } from 'react'
import { connect } from 'react-redux'
import Router from './components/Router/Router'
import { actions } from 'modules/Auth/auth.redux'
import { routes } from './routes'
import { counterActions } from 'modules/Counter/counter.redux'
import { configurationActions } from 'modules/Configuration/configuration.redux'
import { GoogleOAuthProvider } from '@react-oauth/google';

function App(props) {
  useEffect(() => {
    props.loadConfiguration()
    props.checkLogin()
    props.incrementCounter('visits')
  }, [])

  return (
    <GoogleOAuthProvider
      clientId={props.config?.clients?.google}>
      <Fragment>
        <Router routes={routes} />
      </Fragment>
    </GoogleOAuthProvider>
  )
}

const mapStateToProps = state => ({ campaigns: state.campaign.list, config: state.configuration.public })
const mapDispatchToProps = {
  checkLogin: actions.checkLoginRequest,
  incrementCounter: counterActions.incrementCounterRequest,
  loadConfiguration: configurationActions.publicLoadConfigurationRequest
}
const withConnect = connect(mapStateToProps, mapDispatchToProps)
export default withConnect(App)
