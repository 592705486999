import React from 'react'
import { reduxForm } from 'redux-form'
import { Form, Button } from 'reactstrap'
import { withForm } from 'util/withForm'
import { required } from './../../../util/form'
import { RFHTMLEditor } from 'components/Inputs/RFFields'

function ContentForm({ button, previousPage, ...props }) {
  return (
    <div className='w-100 mt-5 pt-4' {...props}>
      <Form>
        <RFHTMLEditor name='content' validate={[required]} />
      </Form>
      <div className='mb-5'>
        <Button onClick={previousPage}>Anterior</Button>
        <Button color='primary' className='float-right' onClick={props.handleSubmit}>
          Siguiente
        </Button>
      </div>
    </div>
  )
}

const CampaignContentForm = withForm(
  reduxForm({
    form: 'campaign',
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true
  })(ContentForm)
)

export default CampaignContentForm
