/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { columns, columns2, donationLineAndPieChart } from './paypal.helpers'
import { clearStatePaypal, loadAllPaginationPaypalRequest } from './paypal.redux'
import Banner from 'layouts/Site/Layouts/Banner'
import TableContainer from 'components/Container/TableContainer'
import DataTable from 'components/Table/TablePagination'
import SearchBar from 'components/SearchBar/SearchBar'
import PaypalCharts from './PaypalCharts'
import './paypal.css'

function PaypalList(props) {
  const { graph, listPaypal, info } = props

  const data = listPaypal?.docs || []
  const totalDocs = listPaypal?.totalDocs
  const pageAct = listPaypal?.page
  const limit = listPaypal?.limit

  useEffect(() => {
    if (props.filters.search) {
      props.filters.search = undefined
      props.clearStatePaypal()
    }
    props.clearStatePaypal()
    props.loadAllPaginationPaypalRequest(props.filters)
  }, [])

  const rowEvents = {
    onClick: (e, row) => {
      props.history.push(`/dashboard/donation/paypal/view/${row._id}`)
    }
  }

  const handleTableChange = (type, { page, sizePerPage, filter }) => {
    props.filters.page = page
    if (filter) {
      props.filters.search = filter
    }

    props.loadAllPaginationPaypalRequest(props.filters)
  }

  const chart = donationLineAndPieChart(graph, graph)

  return (
    <Banner admin>
      <div className='mx-3 mt-2 header-h2'>
        <h2 className='mb-3 text-left header-title'>Donaciones Paypal</h2>
      </div>
      <div className='mx-3 mt-5 pt-4 row'></div>
      <div className='box-container'>
        <div className='information-box'>
          <label className='information-label'>Total</label>
          <label>${info.total}</label>
        </div>
        <div className='information-box'>
          <label className='information-label'>Numero de donaciones</label>
          <label>{info.donors}</label>
        </div>
      </div>
      <PaypalCharts {...chart} />
      <SearchBar filter={props.filters} onTableChange={handleTableChange} />
      <TableContainer showButtons={false} subtitle='Lista' module='Paypal'>
        {props.filters?.search?.campaign?.length ||
        props.filters?.search?.amount?.length ||
        props.filters?.search?.email?.length ||
        props.filters?.search?.name?.length ||
        (props.filters?.search?.startDate?.length &&
          props.filters?.search?.endDate?.length >= 1) ? (
          <DataTable
            {...props}
            data={data}
            totalDocs={totalDocs}
            pageAct={pageAct}
            limit={limit}
            structure={columns2}
            rowEvents={rowEvents}
            filter={props.filters}
            onTableChange={handleTableChange}
          />
        ) : (
          <DataTable
            {...props}
            data={data}
            totalDocs={totalDocs}
            pageAct={pageAct}
            limit={limit}
            structure={columns}
            rowEvents={rowEvents}
            filter={props.filters}
            onTableChange={handleTableChange}
          />
        )}
      </TableContainer>
    </Banner>
  )
}

const mapStateToProps = ({ donation, paypal }) => ({
  listPaypal: paypal.list,
  info: paypal.info,
  filters: paypal.filters,
  graph: paypal.graph
})

const mapDispatchToProps = {
  clearStatePaypal,
  loadAllPaginationPaypalRequest
}

export default connect(mapStateToProps, mapDispatchToProps)(PaypalList)
