import React, { Component } from 'react'
import { connect } from 'react-redux'
import { categoryActions } from './category.redux'
import CategoryForm from './CategoryForm'
import Banner from 'layouts/Site/Layouts/Banner'

class CategoryCreate extends Component {
  submit = values => {
    this.props.createCategory(values)
  }

  render() {
    return (
      <Banner>
        <CategoryForm {...this.props} label='Agregar' button='add' onSubmit={this.submit} />
      </Banner>
    )
  }
}

const mapStateToProps = ({ category }) => ({ category })
const mapDispatchToProps = {
  createCategory: categoryActions.createCategoryRequest
}

export default connect(mapStateToProps, mapDispatchToProps)(CategoryCreate)
