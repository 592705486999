import CampaignCreate from './CampaignCreate'
import CampaignList from './CampaignList'
import CampaignEdit from './CampaignEdit'
import CampaignView from './CampaignView'
import CampaignsGallery from './CampaignsGallery'
import Campaign from './Campaign'

const routes = [
  { path: '/campaigns', public: true, component: CampaignsGallery },
  { path: '/campaigns/:slug', public: true, component: Campaign },
  { path: '/dashboard/campaign/list', component: CampaignList },
  { path: '/dashboard/campaign/create', component: CampaignCreate },
  { path: '/dashboard/campaign/edit/:id', component: CampaignEdit },
  { path: '/dashboard/campaign/view/:id', component: CampaignView }
]

export default routes
