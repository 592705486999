import React, { Component } from 'react'
import { connect } from 'react-redux'
import { institutionActions } from './institution.redux'
import InstitutionForm from './InstitutionForm'
import Banner from 'layouts/Site/Layouts/Banner'

class InstitutionCreate extends Component {
  submit = values => {
    this.props.createInstitution(values)
  }

  render() {
    return (
      <Banner>
        <InstitutionForm {...this.props} label='Agregar' button='add' onSubmit={this.submit} />
      </Banner>
    )
  }
}

const mapStateToProps = ({ institution }) => ({ institution })
const mapDispatchToProps = {
  createInstitution: institutionActions.createInstitutionRequest
}

export default connect(mapStateToProps, mapDispatchToProps)(InstitutionCreate)
