import React, { Fragment, useState } from 'react'
import { connect } from 'react-redux'
import PaypalButton from './PaypalButton'
import ConektaCreditCardPayment from 'components/Donation/ConektaCreditCardPayment'
import OxxoPay from './OxxoPay'
import { FormGroup, Label, Input } from 'reactstrap'
import { Link } from 'react-router-dom'
import { minimumValueIs } from 'util/form'

function DonationButtons({ campaign, config }) {
  const [amount, setAmount] = useState(100)
  const [check, setCheck] = useState(false)

  const onChangeAmount = ({ target }) => setAmount(target.value)
  const onChangeCheck = ({ target }) => setCheck(target.checked)

  return (
    <Fragment>
      <div>
        <div className='input-group mb-3'>
          <div className='input-group-prepend'>
            <span className='input-group-text'>$</span>
          </div>
          <input
            type='number'
            min='100'
            value={amount}
            className='form-control'
            onChange={onChangeAmount}
          />
          <div className='input-group-append'>
            <span className='input-group-text'>MXN</span>
          </div>
          {amount < 1 ? <div style={{"color": "red", "marginLeft":"15px"}}>La cantidad mínima de donación es 1 peso</div> : undefined }
        </div>
      </div>

      <FormGroup check>
        <Label check>
          <Input className='cursor-pointer' type='checkbox' onChange={onChangeCheck} />{' '}
          <span className='cursor-pointer'>
            Confirmo haber leído el <Link to='/notice-of-privacy'>Aviso de Privacidad</Link>
          </span>
        </Label>
      </FormGroup>

      <div className={`donation-buttons ${check && amount > 0 ? '' : 'pointer-events-none'}`}>
        {config.providers?.conekta && (
          <Fragment>
            <ConektaCreditCardPayment amount={amount} campaign={campaign} />
            <OxxoPay amount={amount} campaign={campaign} />
          </Fragment>
        )}
        {config.providers?.paypal && <PaypalButton amount={amount} campaign={campaign} />}
      </div>
    </Fragment>
  )
}

const mapStateToProps = ({ campaign, configuration }) => ({
  campaign: campaign.campaign._id,
  config: configuration.public
})
const withConnect = connect(mapStateToProps)
export default withConnect(DonationButtons)
