import React from 'react'
import { Container } from 'reactstrap'
import { connect } from 'react-redux'
import Loading from 'components/Loading/Loading'
import Slider from 'components/Slider/Slider'
import ProgressBar from 'components/ProgressBar/ProgressBar'
import HeaderSlide from './../Header/HeaderSlide'
import Navbar from './../Navbar/Navbar'
import Footer from './../Footer/Footer'
import CardSm from './../Cards/CardSm'

export function Banner({ children, campaigns, fluid, admin }) {
  if (admin) {
    return (
      <Layout admin>
        <div className='mt-5' />
        <Separator />
        <Container fluid={fluid}>{children}</Container>
      </Layout>
    )
  }

  return (
    <Layout admin={false}>
      <HeaderSlide />
      <Separator />
      <Container fluid={fluid}>
        <div className='row'>{children}</div>
      </Container>
      <section className='ftco-section py-5 bg-light campaign-slider'>
        <Slider />
      </section>
      <Footer />
    </Layout>
  )
}

function Layout({ children, admin }) {
  return (
    <Container fluid className='px-0'>
      <Loading />
      <Navbar admin={admin} />
      <ProgressBar />
      {children}
    </Container>
  )
}

const Separator = () => <div id='content' style={{ position: 'relative', top: -150 }} />

Banner.defaultProps = {
  admin: true,
  campaigns: []
}

const mapStateToProps = state => ({ campaigns: state.campaign.gallery })

export default connect(mapStateToProps)(Banner)
