import React, { Fragment } from 'react'
import './../../../components/Donation/DonationPanel.css'
import DonationButtonsPreview from './DonationButtonsPreview'

export function DonationPanelPreview(props) {
  return (
    <center className='donation-card'>
      {props.visibility?.counter === true ? (
        <DonationPanelProgress goal={props.goal} donations={props.donations} />
      ) : null}
      {props.hours > 0 ? (
        <DonationPanelContent hours={props.hours} days={props.days} {...props} />
      ) : (
        <GratitudeMessage />
      )}
    </center>
  )
}

function DonationPanelProgress({ donations = [], goal }) {
  const donated = donations.reduce((accum, donation) => accum + donation.amount, 0)
  const progress = (donated * 100) / goal
  return (
    <Fragment>
      <div className='donations-needed'>
        <p>
          <b>$0</b> recaudados de <b>${goal}</b>
        </p>
      </div>
      <div className='indicator-wrap'>
        <div id='indicator' style={{ width: `${progress}%` }} />
      </div>
    </Fragment>
  )
}

function DonationPanelContent({ days, hours, visibility }) {
  return (
    <div className='card'>
      {visibility?.countdown && days > 0 ? (
        <p className='text-center'>
          <span className='orange-text'>Quedan {days} días</span> para donar.
        </p>
      ) : null}
      {visibility?.countdown && days <= 0 && hours > 0 ? (
        <p className='text-center'>
          <span className='orange-text'>Quedan {hours} horas</span> para donar.
        </p>
      ) : null}
      <p>Únete a los otros donadores que ya han apoyado esta campaña.</p>
      <DonationButtonsPreview />
    </div>
  )
}

function GratitudeMessage() {
  return (
    <div className='card'>
      <strong>Esta campaña ha finalizado.</strong>
      <p className='mt-2'>
        Agradecemos de corazón a los donadores que nos brindaron su apoyo y confianza.
      </p>
    </div>
  )
}
