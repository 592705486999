import React, { Component } from 'react'
import { connect } from 'react-redux'
import { campaignActions } from './campaign.redux'
import { isEmpty } from './../../util/crud'
import CampaignForm from './CampaignForm'
import Banner from 'layouts/Site/Layouts/Banner'
import { initialize } from 'redux-form'

class CampaignEdit extends Component {
  componentDidMount() {
    const { id } = this.props.match.params
    this.props.editCampaignRequest({ id })
  }

  componentWillUnmount() {
    this.props.editCampaignSuccess({})
  }

  componentDidUpdate() {
    this.props.initialize('campaign', this.props.campaign.edit)
  }

  onCancel = () => {
    this.props.history.push('/dashboard/campaign/list')
  }

  submit = values => {
    const { id } = this.props.match.params
    this.props.updateCampaign({ id, values })
  }

  render() {
    const values = this.props.campaign.edit

    return (
      <Banner>
        {!isEmpty(values) && (
          <CampaignForm
            {...this.props}
            label='Actualizar'
            button='edit'
            onCancel={this.onCancel}
            onSubmit={this.submit}
            initialValues={values}
          />
        )}
      </Banner>
    )
  }
}

const mapStateToProps = ({ campaign }) => ({ campaign })
const mapDispatchToProps = {
  updateCampaign: campaignActions.updateCampaignRequest,
  editCampaignRequest: campaignActions.editCampaignRequest,
  editCampaignSuccess: campaignActions.editCampaignSuccess,
  initialize
}

export default connect(mapStateToProps, mapDispatchToProps)(CampaignEdit)
