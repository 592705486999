/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector, connect } from 'react-redux'
import FormAgree from './Forms/FormAgree'
import FormProductKey from './Forms/FormProductKey'
import FormContact from './Forms/FormContact'
import FormLogo from './Forms/FormLogo'
import FormTerms from './Forms/FormTerms'
import FormNotice from './Forms/FormNotice'
import FormUser from './Forms/FormUser'
import FormMailer from './Forms/FormMailer'
import FormProviders from './Forms/FormProviders'
import FormHome from './Forms/FormHome'
import FormCategory from './Forms/FormCategory'
import { configurationActions as actions } from './../Configuration/configuration.redux'
import FormInstitution from './Forms/FormInstitution'

function InitialConfigurationWizard({ createConfiguration, ...props }) {
  const history = useHistory()
  const [page, setPage] = useState(0)
  const isConfigured = useSelector(state => state.configuration.public.configured)

  useEffect(() => {
    if (isConfigured) history.push('/')
    document.body.style.background = 'whitesmoke'
    return () => {
      document.body.style.background = 'white'
    }
  }, [isConfigured])

  const nextPage = () => {
    setPage(page + 1)
  }

  const previousPage = () => {
    setPage(page - 1)
  }

  const onSubmit = values => {
    createConfiguration(values)
  }

  return (
    <Fragment>
      {page === 0 && (
        <FormAgree {...props} onSubmit={nextPage} label='Términos y Condiciones Softtek' />
      )}

      {page === 1 && (
        <FormProductKey
          previousPage={previousPage}
          onSubmit={nextPage}
          label='Configuración General'
        />
      )}

      {page === 2 && (
        <FormContact
          previousPage={previousPage}
          onSubmit={nextPage}
          label='Configuración General Institución'
        />
      )}

      {page === 3 && (
        <FormLogo
          previousPage={previousPage}
          onSubmit={nextPage}
          label='Configuración General Logo'
        />
      )}

      {page === 4 && (
        <FormHome previousPage={previousPage} onSubmit={nextPage} label='Misión' />
      )}

      {page === 5 && (
        <FormTerms
          previousPage={previousPage}
          onSubmit={nextPage}
          label='Términos y Condiciones Legales'
        />
      )}

      {page === 6 && (
        <FormNotice
          previousPage={previousPage}
          onSubmit={nextPage}
          label='Aviso de Privacidad'
        />
      )}

      {page === 7 && (
        <FormUser
          previousPage={previousPage}
          onSubmit={nextPage}
          label='Usuario Administrador Principal'
        />
      )}

      {page === 8 && (
        <FormMailer
          previousPage={previousPage}
          onSubmit={nextPage}
          label='Configuración de envío de correos electrónicos'
        />
      )}

      {page === 9 && (
        <FormProviders
          previousPage={previousPage}
          onSubmit={nextPage}
          label='Métodos de pago - Conekta (Opcional)'
          provider='conekta'
        />
      )}

      {page === 10 && (
        <FormProviders
          previousPage={previousPage}
          onSubmit={nextPage}
          label='Métodos de pago - PayPal (Opcional)'
          provider='paypal'
        />
      )}

      {page === 11 && (
        <FormProviders
          previousPage={previousPage}
          onSubmit={nextPage}
          label='Redes Sociales - Facebook (Opcional)'
          provider='facebook'
        />
      )}

      {page === 12 && (
        <FormProviders
          previousPage={previousPage}
          onSubmit={nextPage}
          label='Redes Sociales - Google (Opcional)'
          provider='google'
        />
      )}

      {page === 13 && (
        <FormProviders
          previousPage={previousPage}
          onSubmit={nextPage}
          label='Redes Sociales - Twitter (Opcional)'
          provider='twitter'
        />
      )}

      {page === 14 && (
        <FormCategory previousPage={previousPage} onSubmit={nextPage} label='Categoría' />
      )}

      {page === 15 && (
        <FormInstitution
          previousPage={previousPage}
          onSubmit={onSubmit}
          label='Institución (Opcional)'
        />
      )}
    </Fragment>
  )
}

const mapDispatchToProps = { createConfiguration: actions.createConfigurationRequest }
export default connect(null, mapDispatchToProps)(InitialConfigurationWizard)
