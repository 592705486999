import React from 'react'
import CarouselIndicators from './CarouselIndicators'

export default function Carousel({ numberOfSlides, target, children }) {
  return (
    <div id={target} className='carousel slide' data-ride='carousel'>
      <CarouselIndicators target={target} slides={numberOfSlides} />
      <div className='carousel-inner' style={{ height: '100vh' }} role='listbox'>
        {children}
      </div>
    </div>
  )
}
