import React from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import thumbnail from 'assets/images/voluntariado.jpg'

function Information() {
  const image = useSelector(state => state.configuration.public.home?.image)
  const content = useSelector(state => state.configuration.public.home?.content)

  return (
    <section className='ftco-section py-0'>
      <div className='container-fluid'>
        <div className='row d-md-flex'>
          <div
            className='col-md-6 img about-image'
            style={{
              backgroundImage: `url(${image?.path || thumbnail})`,
              backgroundPosition: 'rigth'
            }}
          />
          <div className='col-md-6 p-md-5'>
            <div className='row py-md-4'>
              <div className='col-md-12 d-flex align-items-center'>
                <div className='tab-content' id='v-pills-tabContent'>
                  <div
                    className='tab-pane fade show active'
                    id='v-pills-whatwedo'
                    role='tabpanel'
                    aria-labelledby='v-pills-whatwedo-tab'>
                    <div className='container'>
                      <h2 className='mb-4'>Nuestra misión</h2>
                      <p style={{ wordBreak: 'break-word' }}>{content}</p>
                      <p>
                        <Link to='/about' className='btn btn-primary p-3 mt-1'>
                          Más información
                        </Link>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Information
