import React from 'react'
import Calendar from 'react-calendar'
import 'react-calendar/dist/Calendar.css'

export default function CustomCalendar(props) {
  return (
    <div>
      <Calendar {...props} locale='es-MX' value={new Date()} />
    </div>
  )
}
