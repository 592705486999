import React from 'react'
import { connect } from 'react-redux'
import { reduxForm, Form } from 'redux-form'
import { RFInput } from 'components/Inputs/RFFields'
import { actions } from './auth.redux'
import { required, trim } from 'util/form'

function LoginForm({ handleSubmit, login }) {
  const onSubmit = handleSubmit(login)
  return (
    <Form onSubmit={onSubmit}>
      <div className='form-group'>
        <RFInput
          name='email'
          type='text'
          label='CORREO ELECTRÓNICO'
          normalize={trim}
          validate={[required]}
          className='form-control'
        />
      </div>
      <div className='form-group'>
        <RFInput
          name='password'
          type='password'
          label='CONTRASEÑA'
          normalize={trim}
          validate={[required]}
          className='form-control'
        />
      </div>
      <div className='form-group'>
        <input type='submit' value='Iniciar sesión' className='btn btn-primary py-3 px-5' />
      </div>
    </Form>
  )
}

const mapStateToProps = ({ auth }) => ({ auth })
const mapDispatchToProps = { login: actions.loginRequest }
const withConnect = connect(mapStateToProps, mapDispatchToProps)
const RFLoginForm = reduxForm({ form: 'login' })(LoginForm)

export default withConnect(RFLoginForm)
