import React, { useState } from 'react'
import './LikeButton.css'
import { connect } from 'react-redux'
import { campaignActions } from 'modules/Campaign/campaign.redux'

function LikeButton({ incrementLikes, campaign }) {
  const [disabled, setDisabled] = useState(false)

  const handleClick = () => {
    if (!disabled) {
      incrementLikes(campaign.slug)
      setDisabled(true)
    }
  }

  return (
    <button className='like__btn animated' onClick={handleClick} disabled={disabled}>
      <i className='like__icon icon-heart' />
      <span className='like__number'>{campaign.likes}</span>
    </button>
  )
}

LikeButton.defaultProps = {
  count: 0
}

const mapStateToProps = ({ campaign }) => ({ campaign: campaign.campaign })
const mapDispatchToProps = {
  incrementLikes: campaignActions.incrementCampaignLikesRequest
}

export default connect(mapStateToProps, mapDispatchToProps)(LikeButton)
