import React from 'react'
import { isEmpty } from '../../util/helpers'

function BasicCard({ header, children, button, ...props }) {
  return (
    <div {...props}>
      <div className='card h-100 shadow mb-2'>
        {!isEmpty(header) && (
          <div className='card-header py-3 d-flex flex-row align-items-center justify-content-between'>
            <h6 className='m-0 font-weight-bold text-primary-tb'>{header}</h6>
            {button}
          </div>
        )}
        <div className='card-body'>{children}</div>
      </div>
    </div>
  )
}

export default BasicCard
