import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { counterActions } from './counter.redux'
import CountUp from 'react-countup'

export const Counter = ({ label, count, ...props }) => {
  return (
    <div className='col-md-4 d-flex justify-content-center counter-wrap'>
      <div className='block-18 text-center'>
        <div className='text'>
          <strong className='number'>
            <CountUp end={count} duration={4} {...props} />
          </strong>
          <span>{label}</span>
        </div>
      </div>
    </div>
  )
}

export const Counters = ({ counter, loadAllCounter }) => {
  useEffect(() => {
    loadAllCounter()
  }, [loadAllCounter])

  return (
    <section className='ftco-section ftco-counter py-5' id='section-counter'>
      {counter.donations === 0 ? undefined : (
        <div className='container'>
          <div className='d-flex justify-content-center'>
            <Counter label='Visitas' count={counter.visits} />
            <Counter label='Donaciones' count={counter.donations} suffix=' MXN' />
            <Counter label='Donadores' count={counter.donors} />
          </div>
        </div>
      )}
    </section>
  )
}

const mapStateToProps = ({ counter }) => ({ counter })
const mapDispatchToProps = {
  loadAllCounter: counterActions.loadAllCounterRequest
}

export default connect(mapStateToProps, mapDispatchToProps)(Counters)
