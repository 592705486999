import React from 'react'
import { Button } from 'reactstrap'
import { connect } from 'react-redux'
import { reduxForm, formValueSelector } from 'redux-form'
import { RFCheckbox } from 'components/Inputs/RFFields'
import FormWrapper from '../FormWrapper'

function FormAgree({ handleSubmit, label, acceptTerms }) {
  return (
    <FormWrapper title={label}>
      <div style={{ height: 345 }}>
        <div style={{ overflowY: 'scroll', height: 255, padding: 5 }}>
          <p>
            <strong>Valores Corporativos Softtek S.A. de C.V.</strong>, sus filiales y/o
            subsidiarias (en adelante “Softtek”) pone a su disposición este aviso de privacidad
            para informarle de nuestros procedimientos y políticas con respecto al tratamiento
            de su información, así como los derechos y obligaciones que usted tiene en relación
            al procesamiento de la misma. A menos que sea especificado de forma distinta en el
            Aviso de Privacidad, los términos utilizados en este documento tienen el mismo
            significado determinado en nuestros{' '}
            <a
              href='https://www.softtek.com/es/terminos-de-uso#terminos'
              target='_blank'
              rel='noopener noreferrer'>
              Términos de Uso.
            </a>
          </p>
          <ol>
            <li>
              <strong>Recolección y procesamiento de información</strong>
              <br />
              <br />
              Al visitar nuestro sitio web, Softtek podrá recolectar, si usted acepta, cookies
              e información de análisis, las cuales nos permiten personalizar el contenido en
              su idioma, de acuerdo con a sus intereses y la región en que nos visita; además
              de saber la efectividad de nuestras campañas de marketing.
              <br />
              <br />
              La información de análisis (analíticas) proviene de tu dirección IP, de la
              información de tu sistema operativo, especificaciones de tu navegador, el tipo de
              dispositivo que utiliza para navegar y las configuraciones del lenguaje.
              <br />
              <br />
              Cuando usted descarga o solicita algún material de los sitios de Softtek, le
              pediremos nos provea de cierta información, misma que será almacenada y procesada
              de manera segura.
              <br />
              <br />
              Para poder proceder con la descarga, o para dar seguimiento a una solicitud de
              información o contestar una petición de contacto, solicitaremos la siguiente
              información (de manera enunciativa más no limitada a): nombre completo, email
              corporativo, teléfono(s) de contacto, compañía (empleador), puesto / título
              laboral, dirección del empleador, sitio web de la empresa, número de empleados,
              país, ciudad, código postal, listado de los servicios o soluciones de su interés
              y nombres de cuentas de redes sociales (“Información Personal”).
              <br />
              <br />
              Tenga en cuenta que los sitios y blogs de Softtek utilizan cookies y tecnologías
              similares para proveerle una mejor experiencia y para propósitos de analítica.
              Por favor lea nuestra{' '}
              <a
                href='https://www.softtek.com/cookie-policy'
                target='_blank'
                rel='noopener noreferrer'>
                Política de Cookies
              </a>{' '}
              para obtener mayor información. <br />
              <br />
            </li>
            <li>
              <strong>Uso de información personal</strong>
              <br />
              <br />
              Softtek puede utilizar su Información Personal en las siguientes actividades:
              <ul>
                <li>
                  Para contactarle y contestar cualquier solicitud que haya enviado a través de
                  los sitios de Softtek;
                </li>
                <li>
                  Para enviarle material de marketing sobre nuestros servicios y soluciones, de
                  acuerdo a sus intereses;
                </li>
                <li>Realizar encuestas de satisfacción a nuestros clientes;</li>
                <li>
                  Administrar y contestar los comentarios o solicitudes realizadas en nuestros
                  blogs, de los cuales usted puede participar;
                </li>
                <li>Desarrollar campañas de marketing;</li>
                <li>
                  Con propósitos de reclutamiento; y Para compilar estadísticas generales.
                </li>
              </ul>
              Softtek utiliza, si usted aceptó,{' '}
              <a
                href='https://www.softtek.com/cookie-policy'
                target='_blank'
                rel='noopener noreferrer'>
                cookies
              </a>{' '}
              que almacenan información general, no personal, para medir la cantidad de visitas
              en nuestros sitios, el promedio de tiempo invertido en el sitio, páginas
              visitadas e información similar; y para mejorar el contenido, así como asegurar
              su seguridad y protección.
              <br />
              <br />
              Softtek no cede, renta, asigna, transfiere ni proporciona su Información Personal
              a terceros sin su previo consentimiento por escrito, excepto cuando (i) es
              generalmente conocida por el público en el momento de la divulgación o se vuelve
              de dominio público a través de ningún acto ilícito por parte de Softtek; (ii)
              está en posesión de Softtek al momento de la divulgación, sin que Softtek
              incumpla ninguna obligación legal; (iii) se vuelva de conocimiento de Softtek por
              fuentes ajenas a la parte divulgadora, pero con el derecho legal de divulgar
              dicha Información Personal; (iv) deba ser divulgada por Softtek para cumplir con
              las leyes o regulaciones gubernamentales aplicables.
              <br />
              <br />
            </li>
            <li>
              <strong>Cuánto tiempo conservamos su información</strong>
              <br />
              <br />
              Si usted es actualmente un cliente, Softtek almacenará y protegerá si Información
              Personal conforme a lo estipulado en el acuerdo correspondiente. Las obligaciones
              de confidencialidad establecidas en el contrato permanecerán ejecutables durante
              la vigencia de la relación de negocios de las partes y después de la fecha en que
              cese dicha relación de negocios como se indica en el acuerdo regulador. <br />
              <br />
            </li>
            <li>
              <strong>Distribución de su información</strong>
              <br />
              <br />
              Softtek podrá compartir su Información Personal con sus afiliadas y subsidiarias,
              así como transferirla a otros países en el mundo en donde Softtek tiene
              servidores u operaciones. <br />
              <br />
              Softtek puede trabajar con proveedores externos y asociados para cumplir los
              propósitos antes mencionados; por consiguiente, cuando Softtek utilice terceros,
              se asegurará que tengan el nivel adecuado de protección de la Información
              Personal que procesen a nuestro nombre. Además, cuando dichos terceros se
              encuentren ubicados fuera de la Unión Europea, Softtek se asegurará que suscriba
              las Cláusulas Modelo adoptadas por la Comisión Europea además de cumplir con los
              acuerdos de confidencialidad existentes con Softtek.
              <br />
              <br />
            </li>
            <li>
              <strong>Seguridad e Integridad de la información</strong>
              <br />
              <br />
              La Información Personal, que pueda usted proveer, será almacenada y protegida de
              acuerdo con los estándares de la industria y la tecnología. A pesar de lo
              anterior, el internet no es un ambiente 100% seguro, por lo que Softtek no puede
              asegurar que las transmisiones en internet serán completamente privadas o
              seguras, y usted comprende que cualquier mensaje o información que envíe a
              Softtek puede ser leída o interceptada por terceras partes, aún y cuando la
              información esté encriptada.
              <br />
              <br />
            </li>
            <li>
              <strong>Sus derechos</strong>
              <br />
              <br />
              En cualquier momento, usted tiene el derecho de acceder y solicitar una copia de
              su Información Persona; solicitar que su Información Personal sea rectificada o
              revocada; y limitar su uso o divulgación. <br />
              <br />
              Usted puede hacerlo al:
              <br />
              <ul>
                <li>
                  Actualizar sus preferencias de email (a qué emails los recibe) al hacer clic
                  en el hipervínculo con la leyenda{' '}
                  <i>email preferences / preferencias de email</i> que se ubica en la parte
                  inferior de cada correo electrónico que le enviamos. Una vez que haya dado
                  clic podrá seleccionar qué emails prefiere recibir de Softtek. <br />
                  <br />
                  <center>
                    <img
                      src='https://es.softtek.co/hubfs/softtek.com/email-preferences-es.jpg'
                      alt='preferencias de email'
                      width='100%'
                      style={{ maxWidth: '670px' }}
                    />
                    <br />
                    <br />
                  </center>
                </li>
                <li>
                  Enviar un correo con su solicitud al{' '}
                  <span id='cloakc2a41ad417639925fbb658dbbc588145'>
                    Esta dirección de correo electrónico está siendo protegida contra los
                    robots de spam. Necesita tener JavaScript habilitado para poder verlo.
                  </span>
                  ; o
                </li>
                <li>
                  Enviarnos una carta o notificación a la siguiente dirección: <br />
                  Boulevard Constitución # 3098, Piso 6, Col. Santa María, Monterrey N.L., C.P.
                  64650.
                </li>
              </ul>
              <br />
              <br />
            </li>
            <li>
              <strong>Cláusulas generales</strong>
              <br />
              <br />
              Softtek se reserva el derecho de hacer cambios a este Aviso de Privacidad, y
              Softtek puede publicarlo a través de su sitio web:{' '}
              <a href='https://www.softtek.com/' target='_blank' rel='noopener noreferrer'>
                www.softtek.com
              </a>
              . De acuerdo con la regulación del Parlamento Europeo (EU) 2016/679 y del Consejo
              del 27 de abril de 2016 en acuerdo a la protección de personas naturales con
              respecto al procesamiento de la Información Personal y del libre movimiento de
              dicha información, y derogando la Directiva 95/46/EC (en adelante referida como
              “Reglamento General de Protección de Datos” o “GDPR”), Softtek asegura el
              cumplimento del GDPR. <br />
              <br />
              Al proveer su información usted está de acuerdo con los términos y condiciones
              aquí establecidos; por lo que, si usted desea que Softtek no recolecte su
              Información Personal, por favor absténgase de proveerla.
              <br />
              <br />
            </li>
          </ol>
        </div>

        <RFCheckbox
          name='acceptTerms'
          label='He leído y acepto los términos y condiciones'
          className='form-switch'
        />

        {acceptTerms && (
          <Button color='primary' id='next-button' onClick={handleSubmit}>
            Continuar
          </Button>
        )}
      </div>
    </FormWrapper>
  )
}

const selector = formValueSelector('initial-configuration')

export default connect(state => ({
  acceptTerms: selector(state, 'acceptTerms')
}))(
  reduxForm({
    form: 'initial-configuration',
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true
  })(FormAgree)
)
