import React from 'react'
import FormWrapper from '../FormWrapper'
import { Button, Row } from 'reactstrap'
import { reduxForm } from 'redux-form'
import { RFUploader, RFInput } from 'components/Inputs/RFFields'
import { required, substring } from 'util/form'

const limit700 = substring(0, 700)

function FormHome({ handleSubmit, previousPage, label }) {
  return (
    <FormWrapper title={label}>
      <Row style={{ minHeight: 287 }}>
        <RFUploader name='home.image' label='Imagen lateral' validate={[required]} />
        <RFInput
          rows={10}
          type='textarea'
          name='home.content'
          label='Nuestra misión'
          validate={[required]}
          normalize={limit700}
        />
      </Row>
      <Button color='secondary' id='prev-button' onClick={previousPage}>
        Atrás
      </Button>
      <Button color='primary' id='next-button' onClick={handleSubmit}>
        Continuar
      </Button>
    </FormWrapper>
  )
}

export default reduxForm({
  form: 'initial-configuration',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true
})(FormHome)
