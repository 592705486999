import React, { Fragment } from 'react'
import { FormGroup, Label, Input, Button } from 'reactstrap'
import { PayPalButton as PayPal } from 'react-paypal-button-v2'
import Img from 'react-cool-img'
import visa from 'assets/images/visa.png'
import oxxopay from 'assets/images/oxxopay.svg'
import americanExpress from 'assets/images/americanExpress.png'
import { Link } from 'react-router-dom'

function DonationButtonsPreview() {
  return (
    <Fragment>
      <div className='pointer-events-none'>
        <div className='input-group mb-3'>
          <div className='input-group-prepend'>
            <span className='input-group-text'>$</span>
          </div>
          <input type='number' min='100' value='100' className='form-control' />
          <div className='input-group-append'>
            <span className='input-group-text'>MXN</span>
          </div>
        </div>
      </div>

      <FormGroup check className='pointer-events-none'>
        <Label check>
          <Input className='cursor-pointer' type='checkbox' />{' '}
          <span className='cursor-pointer'>
            Confirmo haber leído el <Link>Aviso de Privacidad</Link>
          </span>
        </Label>
      </FormGroup>
      <div className='donation-buttons pointer-events-none'>
        <ConektaButton />
        <OxxoButton />
        <PaypalButton />
      </div>
    </Fragment>
  )
}

function OxxoButton() {
  return (
    <Button className='oxxo-pay-btn my-2 btn-primary btn-block'>
      <Img width='50' className='mr-2' alt='conekta' src={oxxopay} />
      <span>Donar con OXXO Pay</span>
    </Button>
  )
}

function ConektaButton() {
  return (
    <Button className='credit-card-btn mt-2 p-2 btn-light btn-block'>
      <i className='ti-credit-card mr-2' />
      <Img
        alt='Credit card'
        src='https://media.graphcms.com/b3EqlINJRBGWMT5pnoU5'
        style={{ width: '30px', paddingRight: '5px' }}
      />
      <Img alt='Visa' src={visa} style={{ width: '20px', paddingRight: '5px' }} />
      <Img
        alt='American Express'
        src={americanExpress}
        style={{ width: '20px', paddingRight: '5px' }}
      />
      <span>Donar con Tarjeta de Crédito</span>
    </Button>
  )
}

function PaypalButton() {
  return (
    <div className='my-2 d-block'>
      <PayPal style={{ layout: 'horizontal', color: 'blue', fundingicons: false }} />
    </div>
  )
}

export default DonationButtonsPreview
