/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect } from 'react'
import { connect } from 'react-redux'
import { donationActions } from './donation.redux'
import Banner from 'layouts/Site/Layouts/Banner'
import Acl from 'components/Acl/Acl'
import { Link } from 'react-router-dom'

function Menu() {
  return (
    <div>
      <div
        className='contain'
        style={{ display: 'flex', alignItems: 'center', height: '80vh' }}>
        <div className='main clearfix mt-5' style={{ display: 'flex', alignItems: 'center' }}>
          <nav id='menu' className='menu w-100'>
            <ul>
              <Acl module='campaign' action='read'>
                <li>
                  <Link to='/dashboard/donation/paypal/list'>
                    <span className='img'>
                      <i aria-hidden='true' className='icon-paypal' />
                    </span>
                    <span>Paypal</span>
                  </Link>
                </li>
              </Acl>

              <Acl module='admin' action='read'>
                <li>
                  <Link to='/dashboard/donation/card/list'>
                    <span className='img'>
                      <i aria-hidden='true' className='icon-credit-card-alt' />
                    </span>
                    <span>Tarjeta</span>
                  </Link>
                </li>
              </Acl>

              <Acl module='user' action='read'>
                <li>
                  <Link to='/dashboard/donation/oxxo/list'>
                    <span className='img'>
                      <i aria-hidden='true' className='icon-shopping-cart' />
                    </span>
                    <span>OXXO Pay</span>
                  </Link>
                </li>
              </Acl>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  )
}

function DonationList(props) {
  useEffect(() => {
    props.loadAllDonation()
  }, [])

  return (
    <Fragment>
      <Menu />
      <Banner admin></Banner>
    </Fragment>
  )
}

const mapStateToProps = ({ donation }) => ({ donation })

const mapDispatchToProps = {
  loadAllDonation: donationActions.loadAllDonationRequest
}

export default connect(mapStateToProps, mapDispatchToProps)(DonationList)
