/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import VerticalTimeline from 'components/Historical/VerticalTimeline'
import Calendar from 'components/Historical/Calendar'
import Banner from 'layouts/Site/Layouts/Banner'
import { historicalActions } from './historical.redux'
import { connect } from 'react-redux'
import { Row, Col } from 'reactstrap'
import { decorateHistorical } from './historical.helpers'

function HistoricalList({ historical, loadAllHistorical, setDate }) {
  useEffect(() => {
    loadAllHistorical()
  }, [])

  return (
    <div>
      <Banner admin>
        <div className='mx-3 mt-5 pt-4 header-h2 d-flex justify-content-between align-items-center'>
          <h2 className='mb-3 mt-4 text-left'>Histórico de eventos</h2>
        </div>
        <Row>
          <Col md={{ size: 7 }}>
            <div className='overflow-box' style={{ height: 420, overflowY: 'scroll' }}>
              <VerticalTimeline values={historical} />
            </div>
          </Col>
          <Col md={{ size: 5 }}>
            <Calendar onChange={setDate} className='mt-3' />
            <div className='my-4 text-center' style={{ width: '95%' }}>
              <i className='icon-help mr-3' />
              <span>Seleccione un día para monitorear los cambios realizados.</span>
            </div>
            {/* <Button block className='download-excel-button px-3 mt-4' style={{ width: '90%' }}>
              <i className='icon-download mr-2' />
              <span>Descargar eventos del día</span>
            </Button> */}
          </Col>
        </Row>
      </Banner>
    </div>
  )
}

const mapStateToProps = ({ historical }) => ({
  historical: decorateHistorical(historical.filtered)
})

const mapDispatchToProps = {
  loadAllHistorical: historicalActions.loadAllHistoricalRequest,
  setDate: historicalActions.setDateHistorical
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)
export default withConnect(HistoricalList)
