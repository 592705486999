import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { FilePond, File, registerPlugin } from 'react-filepond'
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation'
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size'
import FilePondPluginImagePreview from 'filepond-plugin-image-preview'
import FilePondPluginImageCrop from 'filepond-plugin-image-crop'
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css'
import 'filepond/dist/filepond.min.css'

registerPlugin(
  FilePondPluginImageExifOrientation,
  FilePondPluginImagePreview,
  FilePondPluginImageCrop,
  FilePondPluginFileValidateSize
)

export class Uploader extends Component {
  state = { files: [] }

  render() {
    const { input, process, general } = this.props

    return (
      <FilePond
        ref={ref => (this.pond = ref)}
        allowFileSizeValidation
        maxFileSize='5MB'
        server={{
          process: {
            url: '/api/files',
            method: 'POST',
            // headers: { Authorization: `Bearer ${localStorage.token}` },
            onload: response => input.onChange(JSON.parse(response)._id),
            ...process
          }
        }}
        onremovefile={() => input.onChange('')}
        {...general}>
        {this.state.files.map(file => (
          <File key={file} src={file} origin='local' />
        ))}
      </FilePond>
    )
  }
}

Uploader.propTypes = {
  input: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.object,
    onChange: PropTypes.func.isRequired
  }),
  process: PropTypes.object,
  general: PropTypes.object
}

Uploader.defaultProps = {
  process: {},
  general: {}
}
