/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { columns } from './institution.helpers'
import { institutionActions } from './institution.redux'
import Banner from 'layouts/Site/Layouts/Banner'
import DataTable from 'components/Table/Table'
import TableContainer from 'components/Container/TableContainer'
import { useExportSelected } from 'hooks/useExportSelected'

function InstitutionList(props) {
  const [selectRow, handleExport] = useExportSelected(
    'Instituciones',
    '/api/institutions/export'
  )

  useEffect(() => {
    props.loadAllInstitution()
  }, [])

  const rowEvents = {
    onClick: (e, row) => {
      props.history.push(`/dashboard/institution/view/${row._id}`)
    }
  }

  return (
    <Banner>
      <TableContainer
        title='Instituciones'
        subtitle='Lista'
        route='/dashboard/institution/create'>
        <DataTable
          {...props}
          data={props.institutions}
          rowEvents={rowEvents}
          structure={columns}
          onExport={handleExport}
          selectRow={selectRow}
          selected={selectRow.selected}
        />
      </TableContainer>
    </Banner>
  )
}

const mapStateToProps = ({ institution }) => ({ institutions: institution.list })
const mapDispatchToProps = {
  loadAllInstitution: institutionActions.loadAllInstitutionRequest,
  toggleInstitution: institutionActions.toggleInstitutionRequest
}

export default connect(mapStateToProps, mapDispatchToProps)(InstitutionList)
