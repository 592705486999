import axios from 'axios'
import { all, takeLatest, call, put } from 'redux-saga/effects'
import { historicalActions, historicalTypes } from './historical.redux'
import { isEmpty } from './../../util/crud'
import { alertActions } from 'modules/Alert/alert.redux'

/**
 * Display the categories list
 * @returns {iterator}
 */
export function* loadAllHistorical() {
  try {
    const url = '/api/historical/'
    const { data: categories } = yield call(axios.get, url)
    if (isEmpty(categories)) {
      yield put(
        alertActions.alertMessageEmptyGoBack('historical', '/dashboard/historical/create')
      )
    }
    yield put(historicalActions.loadAllHistoricalSuccess(categories))
  } catch (error) {
    yield put(historicalActions.loadAllHistoricalFailure(error))
    console.log(error)
  }
}

/**
 * Display a single historical record
 * @returns {iterator}
 */
export function* loadHistoricalByUser({ payload } = {}) {
  try {
    const { id } = payload
    const url = `/api/historical/user/${id}`
    const { data: historical } = yield call(axios.get, url)
    yield put(historicalActions.loadHistoricalByUserSuccess(historical))
  } catch (error) {
    yield put(historicalActions.loadHistoricalByUserFailure(error))
    console.log(error)
  }
}

export function* historicalSagas() {
  yield all([
    takeLatest(historicalTypes.LOAD_ALL_HISTORICAL_REQUEST, loadAllHistorical),
    takeLatest(historicalTypes.LOAD_HISTORICAL_BY_USER_REQUEST, loadHistoricalByUser)
  ])
}
