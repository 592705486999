/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { columns, donationLineAndPieChart } from './card.helpers'
import { loadCountersCardRequest, loadAllPaginationCardRequest } from './card.redux'
import Banner from 'layouts/Site/Layouts/Banner'
import TableContainer from 'components/Container/TableContainer'
import DataTable from 'components/Table/TablePagination'
import CardCharts from './CardCharts'
import './Card.css'

function CardlList(props) {
  const { listCard, info } = props

  const data = listCard?.docs || []
  const totalDocs = listCard?.totalDocs
  const pageAct = listCard?.page
  const limit = listCard?.limit

  useEffect(() => {
    props.loadCountersCardRequest()
    props.loadAllPaginationCardRequest(props.filters)
  }, [props.loadCountersCardRequest, props.loadAllPaginationCardRequest])

  const rowEvents = {
    onClick: (e, row) => {
      props.history.push(`/dashboard/donation/card/view/${row._id}`)
    }
  }

  const handleTableChange = (type, { page, sizePerPage, filter }) => {
    props.filters.page = page
    if (filter) props.filters.search = filter ? filter : undefined
    props.loadAllPaginationCardRequest(props.filters)
  }

  const { donations, list } = props.donation
  const chart = donationLineAndPieChart(list, donations)

  return (
    <Banner admin>
      <div className='mx-3 mt-2 header-h2'>
        <h2 className='mb-3 text-left header-title'>Donaciones con Tarjeta</h2>
      </div>
      <div className='mx-3 mt-5 pt-4 row'></div>
      <div className='box-container'>
        <div className='information-box'>
          <label className='information-label'>Total</label>
          <label>${info.total}</label>
        </div>
        <div className='information-box'>
          <label className='information-label'>Numero de donadores</label>
          <label>{info.donors}</label>
        </div>
      </div>
      <CardCharts {...chart} />
      <TableContainer showButtons={false} subtitle='Lista' module='Card'>
        <DataTable
          {...props}
          data={data}
          totalDocs={totalDocs}
          pageAct={pageAct}
          limit={limit}
          structure={columns}
          rowEvents={rowEvents}
          filter={props.filters}
          onTableChange={handleTableChange}
        />
      </TableContainer>
    </Banner>
  )
}

const mapStateToProps = ({ donation, card }) => ({
  donation,
  listCard: card.list,
  info: card.info,
  filters: card.filters
})

const mapDispatchToProps = {
  loadCountersCardRequest,
  loadAllPaginationCardRequest
}

export default connect(mapStateToProps, mapDispatchToProps)(CardlList)
