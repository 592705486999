import React from 'react'
import FormWrapper from '../FormWrapper'
import { Button, Row } from 'reactstrap'
import { reduxForm } from 'redux-form'
import { RFInput, RFUploader } from 'components/Inputs/RFFields'
import { required, isEmail, validPassword, substring } from 'util/form'

const isPasswordMatch = (value, values) =>
  value === values.user.password ? undefined : `Las contraseñas no coinciden`

const limit30 = substring(0, 30)

function FormUser({ handleSubmit, previousPage, label }) {
  return (
    <FormWrapper title={label}>
      <Row style={{ minHeight: 287 }}>
        <RFInput name='user.name' label='Nombre' validate={[required]} normalize={limit30} />
        <RFInput
          name='user.lastName'
          label='Apellidos'
          validate={[required]}
          normalize={limit30}
        />
        <RFInput
          name='user.email'
          type='email'
          label='Correo electrónico'
          validate={[required, isEmail]}
        />
        <RFInput
          name='user.password'
          type='password'
          label='Contraseña'
          validate={[required, validPassword]}
        />
        <RFInput
          name='user.confirmation'
          type='password'
          label='Confirmar contraseña'
          validate={[required, isPasswordMatch]}
        />
        <RFUploader name='user.thumbnail' label='Foto de perfil' />
      </Row>
      <Button color='secondary' id='prev-button' onClick={previousPage}>
        Atrás
      </Button>
      <Button color='primary' id='next-button' onClick={handleSubmit}>
        Continuar
      </Button>
    </FormWrapper>
  )
}

export default reduxForm({
  form: 'initial-configuration',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true
})(FormUser)
