import React from 'react'
import { Form } from 'reactstrap'
import { reduxForm } from 'redux-form'
import { RFInput, RFHTMLEditor } from 'components/Inputs/RFFields'
import FormContainer from 'components/Container/FormContainer'
import { withForm } from 'util/withForm'
import { required } from './../../util/form'

function PagesForm(props) {
  const { handleSubmit, onCancel, label, button, option, ...others } = props
  const { pristine, valid, submitting } = others

  return (
    <FormContainer
      title='Páginas'
      label={label}
      button={button}
      onCancel={onCancel}
      onClick={handleSubmit}
      status={{ pristine, valid, submitting }}>
      <Form>
        <RFInput name='title' label='Título' disabled />
        <RFHTMLEditor name='description' validate={[required]} />
      </Form>
    </FormContainer>
  )
}

export default withForm(
  reduxForm({
    form: 'pages'
  })(PagesForm)
)
