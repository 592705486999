import React, { Component } from 'react'
import { connect } from 'react-redux'
import Banner from 'layouts/Site/Layouts/Banner'
import { isEmpty } from './../../util/crud'
import { configurationActions } from './configuration.redux'
import ConfigurationForm from './ConfigurationForm'

class ConfigurationEdit extends Component {
  componentDidMount() {
    this.props.editConfigurationRequest()
  }

  componentWillUnmount() {
    this.props.editConfigurationSuccess()
  }

  submit = values => {
    this.props.updateConfiguration({ values })
  }

  render() {
    const values = this.props.configuration.edit

    return (
      <Banner>
        {!isEmpty(values) && (
          <ConfigurationForm
            {...this.props}
            label='Actualizar'
            button='edit'
            onSubmit={this.submit}
            initialValues={values}
          />
        )}
      </Banner>
    )
  }
}

const mapStateToProps = ({ configuration }) => ({ configuration })
const mapDispatchToProps = {
  updateConfiguration: configurationActions.updateConfigurationRequest,
  editConfigurationRequest: configurationActions.editConfigurationRequest,
  editConfigurationSuccess: configurationActions.editConfigurationSuccess
}

export default connect(mapStateToProps, mapDispatchToProps)(ConfigurationEdit)
