import axios from 'axios'
import { push } from 'connected-react-router'
import { all, takeLatest, call, put, take, select } from 'redux-saga/effects'
import { alertActions, alertTypes } from './../Alert/alert.redux'
import {
  loadSearchPaginationCampaignSuccess,
  publicScrollPaginationCampaignSuccess,
  publicScrollPaginationCampaignInformationSuccess,
  loadSearchPaginationCampaignInformationSuccess,
  publicScrollPaginationCampaignFailure,
  loadSearchPaginationCampaignFailure,
  LOAD_ALL_CAMPAIGN_PAGINATION_REQUEST,
  LOAD_SEARCH_CAMPAIGN_PAGINATION_REQUEST
} from './campaignPagination.redux'
import { campaignActions, campaignTypes } from './campaign.redux'
import { isEmpty } from './../../util/crud'
import { ymdHTML } from 'util/dates'

/**
 * Shows campaign search results
 * @returns {iterator}
 */
export function* SearchPublicScrollCampaignPagination({ payload = 1, query }) {
  try {
    const url = `/api/public/campaigns/search/campaigns?page=${payload}&title=${query}`
    const { data } = yield call(axios.get, url)

    yield all([
      put(
        loadSearchPaginationCampaignInformationSuccess({
          docs: {
            totalDocs: data.totalDocs,
            limit: data.limit,
            totalPages: data.totalPages,
            page: data.page,
            nextPage: data.nextPage,
            searchValue: query
          }
        })
      ),
      put(
        loadSearchPaginationCampaignSuccess({
          docs: {
            data: data.docs
          }
        })
      )
    ])
  } catch (error) {
    yield put(loadSearchPaginationCampaignFailure(error.message))
  }
}

/**
 * Display the campaigns' public gallery
 * @returns {iterator}
 */
export function* publicScrollPagination({ payload = 1 }) {
  try {
    const url = `/api/public/campaigns/scroll/campaigns?page=${payload}`
    const { data } = yield call(axios.get, url)

    yield all([
      put(
        publicScrollPaginationCampaignInformationSuccess({
          docs: {
            totalDocs: data.totalDocs,
            limit: data.limit,
            totalPages: data.totalPages,
            page: data.page,
            nextPage: data.nextPage
          }
        })
      ),
      put(
        publicScrollPaginationCampaignSuccess({
          docs: {
            data: data.docs
          }
        })
      )
    ])
  } catch (error) {
    yield put(publicScrollPaginationCampaignFailure(error.message))
  }
}

/**
 * Display the campaigns' public gallery
 * @returns {iterator}
 */
export function* publicLoadAllCampaign() {
  try {
    const url = '/api/public/campaigns/'
    const { data: campaigns } = yield call(axios.get, url)
    yield put(campaignActions.publicLoadAllCampaignSuccess(campaigns))
  } catch (error) {
    yield put(campaignActions.publicLoadAllCampaignFailure(error))
    console.log(error)
  }
}

/**
 * Display the campaigns' public banner
 * @returns {iterator}
 */
export function* publicLoadAllBannerCampaign() {
  try {
    const url = '/api/public/campaigns/banner'
    const { data: campaigns } = yield call(axios.get, url)
    yield put(campaignActions.publicLoadAllBannerCampaignSuccess(campaigns))
  } catch (error) {
    yield put(campaignActions.publicLoadAllBannerCampaignFailure(error))
    console.log(error)
  }
}

/**
 * Display a campaign
 * @returns {iterator}
 */
export function* publicLoadCampaign({ payload: slug }) {
  try {
    const url = '/api/public/campaigns/' + slug
    const { data: campaigns } = yield call(axios.get, url)
    yield put(campaignActions.publicLoadCampaignSuccess(campaigns))
  } catch (error) {
    yield put(campaignActions.publicLoadCampaignFailure(error))
    console.log(error)
  }
}
/**
 * Display the campaigns list
 * @returns {iterator}
 */
export function* loadAllCampaign() {
  try {
    const url = '/api/campaigns/'
    const { data: campaigns } = yield call(axios.get, url)
    if (isEmpty(campaigns)) {
      yield put(alertActions.alertMessageEmpty('campaign', '/dashboard/campaign/create'))
    }
    yield put(campaignActions.loadAllCampaignSuccess(campaigns))
  } catch (error) {
    yield put(campaignActions.loadAllCampaignFailure(error))
    console.log(error)
  }
}

/**
 * Display a single campaign record
 * @param   {object}   action.payload Data to take the id of the requested campaign
 * @returns {iterator}
 */
export function* loadCampaign({ payload } = {}) {
  try {
    const { id } = payload
    const url = `/api/campaigns/${id}`
    const { data: campaign } = yield call(axios.get, url)
    yield put(campaignActions.loadCampaignSuccess(campaign))
  } catch (error) {
    yield put(campaignActions.loadCampaignFailure(error))
    console.log(error)
  }
}

/**
 * Create an campaign record
 * @param   {object}   action.payload Data to create an campaign record
 * @returns {iterator}
 */
export function* createCampaign({ payload }) {
  try {
    const url = '/api/campaigns/'
    const load = formatCampaign(payload)
    const { data } = yield call(axios.post, url, load)
    // Add new document to the list
    yield put(campaignActions.createCampaignSuccess(data))
    // Show notification
    yield put(alertActions.alertMessageSuccess('Registro guardado'))
    // Return the user to the list
    yield put(push('/dashboard/campaign/list'))
  } catch (error) {
    yield put(campaignActions.createCampaignFailure(error))
    console.log(error)
  }
}

/**
 * Load the information of a single campaign record to edit it
 * @param   {object}   action.payload Data to take the id of the requested campaign
 * @returns {iterator}
 */
export function* editCampaign({ payload } = {}) {
  try {
    const formatOptions = key => option => ({ value: option._id, label: option[key] })

    yield call(loadCampaingOptions)

    const { id } = payload
    const url = `/api/campaigns/${id}`
    const { data: campaign } = yield call(axios.get, url)
    const response = {
      ...campaign,
      category: campaign.category.map(formatOptions('title')),
      thumbnail: campaign.thumbnail._id,
      date: ymdHTML(campaign.date)
    }

    if (campaign.institution)
      response.institution = formatOptions('name')(campaign.institution)

    yield put(campaignActions.editCampaignSuccess(response))
  } catch (error) {
    yield put(campaignActions.editCampaignFailure(error))
    console.log(error)
  }
}

/**
 * Update an campaign record
 * @param   {object}   action.payload Data to update an campaign record
 * @returns {iterator}
 */
export function* updateCampaign({ payload }) {
  try {
    yield put(alertActions.alertPromptShow())
    const prompt = yield take(alertTypes.ALERT_PROMPT_HIDE)

    if (isEmpty(prompt.payload)) return

    const { id, values } = payload
    const url = `/api/campaigns/${id}`

    const historical = { cause: 'Actualización', description: prompt.payload }
    const load = formatCampaign(values)
    const data = { payload: load, historical }
    const { data: updatedCampaign } = yield call(axios.put, url, data)
    yield put(campaignActions.updateCampaignSuccess(updatedCampaign))
    // Success notification and return the user to the list
    yield put(alertActions.alertMessageSuccess('Registro actualizado'))
    yield put(push('/dashboard/campaign/list'))
  } catch (error) {
    yield put(campaignActions.updateCampaignFailure(error))
    console.log(error)
  }
}

/**
 * Toggle the active property of an campaign record
 * @param   {object}   action.payload Data to update an campaign record
 * @returns {iterator}
 */
export function* toggleCampaign({ payload }) {
  try {
    yield put(alertActions.alertPromptShow())
    const prompt = yield take(alertTypes.ALERT_PROMPT_HIDE)
    const description = prompt.payload

    // The prompt was closed, stop the flow
    if (isEmpty(description)) return

    const { _id, active } = payload
    const url = `/api/campaigns/${_id}`

    // HTTP PUT request
    const historical = { cause: active ? 'Desactivación' : 'Activación', description }
    const { data: updatedCampaign } = yield call(axios.put, url, {
      payload: { active: !active },
      historical
    })
    yield put(campaignActions.toggleCampaignSuccess(updatedCampaign))

    // Show success notification
    const result = updatedCampaign.active ? 'activado' : 'desactivado'
    yield put(alertActions.alertMessageSuccess(`Registro ${result}`))
    yield put(push('/dashboard/campaign/list'))
  } catch (error) {
    yield put(campaignActions.toggleCampaignFailure(error))
    console.log(error)
  }
}

/**
 * Validate an campaign record
 * @param     values
 * @returns
 */
export async function validateCampaign(values) {
  const url = '/api/campaigns/validate'
  const { data: result } = await axios.post(url, values)
  if (!isEmpty(result.errors)) throw result.errors
}

export function* loadCampaingOptions() {
  try {
    const { data: categories } = yield call(axios.get, '/api/options/categories')
    const { data: institutions } = yield call(axios.get, '/api/options/institutions')
    const payload = { categories, institutions }
    yield put(campaignActions.loadCampaignOptionsSuccess(payload))
  } catch (error) {
    yield put(campaignActions.loadCampaignOptionsFailure(error))
    console.log(error)
  }
}

export function* loadCampaignComments({ payload: campaignId } = {}) {
  try {
    const url = `/api/public/campaigns/${campaignId}/comments`
    const { data: comments } = yield call(axios.get, url)
    yield put(campaignActions.publicLoadAllCampaignCommentsSuccess(comments))
  } catch (error) {
    yield put(campaignActions.publicLoadAllCampaignCommentsFailure(error))
    console.log(error)
  }
}

export function* createCampaignComment({ payload }) {
  try {
    const campaign = yield select(state => state.campaign.campaign)
    const url = `/api/campaigns/${campaign._id}/comments`

    const { data } = yield call(axios.post, url, { ...payload, campaign: campaign._id })
    yield put(campaignActions.createCampaignCommentSuccess(data))
    yield put(alertActions.alertMessageSuccess('Comentario publicado'))
    yield put(campaignActions.publicLoadAllCampaignCommentsRequest(campaign.slug))
  } catch (error) {
    yield put(campaignActions.createCampaignCommentFailure(error))
    console.log(error)
  }
}

export function* deleteCampaignComment({ payload: id }) {
  try {
    const campaign = yield select(state => state.campaign.campaign)

    const url = `/api/comments/${id}`
    const { data } = yield call(axios.put, url)
    yield put(campaignActions.deleteCampaignCommentSuccess(data))
    yield put(alertActions.alertMessageSuccess('Comentario eliminado'))
    yield put(campaignActions.publicLoadAllCampaignCommentsRequest(campaign.slug))
  } catch (error) {
    yield put(campaignActions.deleteCampaignCommentFailure(error))
    console.log(error)
  }
}

function formatCampaign(payload) {
  return {
    ...payload,
    category: payload.category.map(item => item.value),
    institution: payload.institution?.value
  }
}

export function* incrementCampaignVisits({ slug }) {
  try {
    const url = `/api/public/campaigns/${slug}/visit`
    const { data: visits } = yield call(axios.post, url)
    yield put(campaignActions.incrementCampaignVisitsSuccess(visits.total))
  } catch (error) {
    yield put(campaignActions.incrementCampaignVisitsFailure(error))
    console.log(error)
  }
}

export function* incrementCampaignLikes({ slug }) {
  try {
    const url = `/api/public/campaigns/${slug}/like`
    const { data } = yield call(axios.post, url)
    yield put(campaignActions.incrementCampaignLikesSuccess(data.likes))
  } catch (error) {
    yield put(campaignActions.incrementCampaignLikesFailure(error))
    console.log(error)
  }
}

export function* campaignSagas() {
  yield all([
    takeLatest(LOAD_SEARCH_CAMPAIGN_PAGINATION_REQUEST, SearchPublicScrollCampaignPagination),
    takeLatest(LOAD_ALL_CAMPAIGN_PAGINATION_REQUEST, publicScrollPagination),
    takeLatest(campaignTypes.PUBLIC_LOAD_ALL_CAMPAIGN_REQUEST, publicLoadAllCampaign),
    takeLatest(campaignTypes.PUBLIC_LOAD_CAMPAIGN_REQUEST, publicLoadCampaign),
    takeLatest(campaignTypes.LOAD_ALL_CAMPAIGN_REQUEST, loadAllCampaign),
    takeLatest(campaignTypes.LOAD_CAMPAIGN_REQUEST, loadCampaign),
    takeLatest(campaignTypes.CREATE_CAMPAIGN_REQUEST, createCampaign),
    takeLatest(campaignTypes.EDIT_CAMPAIGN_REQUEST, editCampaign),
    takeLatest(campaignTypes.UPDATE_CAMPAIGN_REQUEST, updateCampaign),
    takeLatest(campaignTypes.TOGGLE_CAMPAIGN_REQUEST, toggleCampaign),
    takeLatest(campaignTypes.LOAD_CAMPAIGN_OPTIONS_REQUEST, loadCampaingOptions),
    takeLatest(campaignTypes.INCREMENT_CAMPAIGN_VISITS_REQUEST, incrementCampaignVisits),
    takeLatest(campaignTypes.INCREMENT_CAMPAIGN_LIKES_REQUEST, incrementCampaignLikes),
    takeLatest(
      campaignTypes.PUBLIC_LOAD_ALL_BANNER_CAMPAIGN_REQUEST,
      publicLoadAllBannerCampaign
    ),
    // * CAMPAIGN'S COMMENTS *
    takeLatest(campaignTypes.CREATE_CAMPAIGN_COMMENT_REQUEST, createCampaignComment),
    takeLatest(campaignTypes.DELETE_CAMPAIGN_COMMENT_REQUEST, deleteCampaignComment),
    takeLatest(campaignTypes.PUBLIC_LOAD_ALL_CAMPAIGN_COMMENTS_REQUEST, loadCampaignComments)
  ])
}
