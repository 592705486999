import React from 'react'
import { mdy } from 'util/dates'
import { generateActiveIcon } from 'components/Table/helpers'
import Img from 'react-cool-img'

export const columns = [
  {
    dataField: 'thumbnail.path',
    formatter: cell => <Img src={cell} alt='' width='100' />,
    sort: true
  },
  { dataField: 'title', text: 'Título', sort: true },
  // { dataField: 'institution.name', text: 'Institución', sort: true },
  {
    dataField: 'duration',
    text: 'Duración',
    formatter: cell => `${cell} días`,
    sort: true
  },
  {
    dataField: 'goal',
    text: 'Meta',
    formatter: (cell = 0) => `$${cell.toFixed(2)}`,
    sort: true
  },
  {
    dataField: 'updatedAt',
    text: 'Última modificación',
    sort: true,
    formatter: mdy
  },
  { dataField: 'createdAt', text: 'Creado', sort: true, formatter: mdy },
  {
    dataField: 'active',
    text: 'Activo',
    formatter: cell => generateActiveIcon(cell),
    sort: true
  }
]
