import React, { useState, useEffect } from 'react'
import moment from 'moment'
import axios from 'axios'
import { mdy } from './../../../util/dates'

function HeaderPreview({ thumbnail, description, title, subtitle, createdAt }) {
  const [image, setImage] = useState('')

  useEffect(() => {
    async function init() {
      if (thumbnail) {
        const { data } = await axios.get('/api/files/' + thumbnail)
        setImage(data.path)
      }
    }
    init()
  }, [thumbnail])

  return (
    <div
      className='hero-wrap hero-wrap-2 background-hero'
      data-stellar-background-ratio='0.5'
      style={{ backgroundImage: `url(${image})` }}>
      <div className='overlay' />
      <div className='container'>
        <div className='row no-gutters slider-text align-items-end justify-content-start'>
          <div className='col-md-8 text-center text-md-left mb-5'>
            <span className='subheading'>{subtitle}</span>
            <h1 className='mb-3'>
              <span>{title}</span>
            </h1>
            <small style={{ fontSize: 14 }}>
              {mdy(createdAt)} - {moment(createdAt).fromNow()}
            </small>
            <p style={{ fontSize: 16 }}>{description}</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default HeaderPreview
