import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import styles from './Loading.module.css'

export const Loading = ({ loading }) => {
  return (
    <Fragment>
      {loading && (
        <div className={styles['loading-page']}>
          <div className={styles['loader']} />
        </div>
      )}
    </Fragment>
  )
}

Loading.propTypes = {
  prop: PropTypes.object
}

const mapStateToProps = ({ loading }) => ({ loading })
export default connect(mapStateToProps)(Loading)
