import axios from 'axios'
import Swal from 'sweetalert2'
import { all, takeLatest, call, put, select } from 'redux-saga/effects'
import { donationActions, donationTypes } from './donation.redux'
import { campaignActions } from './../Campaign/campaign.redux'
import heart from 'assets/images/heart.png'
import { isEmpty } from './../../util/crud'
import { alertActions } from 'modules/Alert/alert.redux'

/**
 * Display the categories list
 * @returns {iterator}
 */
export function* loadAllDonation() {
  try {
    const url = '/api/donations/'
    const { data: categories } = yield call(axios.get, url)
    if (isEmpty(categories)) {
      yield put(alertActions.alertMessageEmptyGoBack('donation', '/dashboard/donation/create'))
    }
    yield put(donationActions.loadAllDonationSuccess(categories))
  } catch (error) {
    yield put(donationActions.loadAllDonationFailure(error))
    console.log(error)
  }
}

/**
 * Display a single donation record
 * @param   {object}   action.payload Data to take the id of the requested donation
 * @returns {iterator}
 */
export function* loadDonation({ payload } = {}) {
  try {
    const { id } = payload
    const url = `/api/donations/${id}`
    const { data: donation } = yield call(axios.get, url)
    yield put(donationActions.loadDonationSuccess(donation))
  } catch (error) {
    yield put(donationActions.loadDonationFailure(error))
    console.log(error)
  }
}

/**
 * Display a single donation record
 * @param   {object}   action.payload Data to take the id of the requested donation
 * @returns {iterator}
 */
export function* loadPaypalDonation({ payload } = {}) {
  try {
    const { id } = payload
    const url = `/api/donations/paypal/${id}`
    const { data: donation } = yield call(axios.get, url)
    yield put(donationActions.loadPaypalDonationSuccess(donation))
  } catch (error) {
    yield put(donationActions.loadPaypalDonationFailure(error))
    console.log(error)
  }
}

// Create a donation record
export function* createPaypalDonation({ payload }) {
  try {
    const url = '/api/donations/paypal'
    const user = yield select(state => state.auth.user._id)
    const { data } = yield call(axios.post, url, { ...payload, user })
    yield put(donationActions.createPaypalDonationSuccess(data))

    const campaign = yield select(state => state.campaign.campaign.slug)
    yield put(campaignActions.publicLoadCampaignRequest(campaign))

    yield Swal.fire({
      title: `¡Gracias ${payload.paypal.details.payer.name.given_name}!`,
      text: 'Tu apoyo es vital para este proyecto',
      imageUrl: heart,
      imageHeight: 90
    })
  } catch (error) {
    yield put(donationActions.createPaypalDonationFailure(error))
    console.log(error)
  }
}

export function* createConektaDonation({ payload, cb }) {
  try {
    const url = '/api/donations/conekta'
    const user = yield select(state => state.auth.user._id)
    const { data } = yield call(axios.post, url, { ...payload, user })
    yield put(donationActions.createConektaDonationSuccess(data))

    const campaign = yield select(state => state.campaign.campaign.slug)
    yield put(campaignActions.publicLoadCampaignRequest(campaign))

    yield call(cb)

    yield Swal.fire({
      title: `¡Gracias ${data.conekta.customer_info.name}!`,
      text: 'Tu apoyo es vital para este proyecto',
      imageUrl: heart,
      imageHeight: 90
    })
  } catch (error) {
    if (error.response && error.response.status !== 402)
      yield Swal.fire({
        title: 'Información inválida',
        text: 'Verifique los datos proporcionados',
        icon: 'error'
      })
    yield put(donationActions.createConektaDonationFailure(error))
    console.log(error)
  }
}

export function* requestOxxoPayDonation({ payload, cb }) {
  try {
    const url = '/api/donations/oxxopay/request'
    const user = yield select(state => state.auth.user._id)
    const { data } = yield call(axios.post, url, { ...payload, user })
    yield put(donationActions.requestOxxoPayDonationSuccess(data))

    yield call(cb, data)
  } catch (error) {
    yield Swal.fire({
      title: 'Información inválida',
      text: 'Verifique los datos proporcionados',
      icon: 'error'
    })
    yield put(donationActions.requestOxxoPayDonationFailure(error))
    console.log(error)
  }
}

export function* donationSagas() {
  yield all([
    takeLatest(donationTypes.LOAD_ALL_DONATION_REQUEST, loadAllDonation),
    takeLatest(donationTypes.LOAD_DONATION_REQUEST, loadDonation),
    takeLatest(donationTypes.LOAD_PAYPAL_DONATION_REQUEST, loadPaypalDonation),
    takeLatest(donationTypes.CREATE_PAYPAL_DONATION_REQUEST, createPaypalDonation),
    takeLatest(donationTypes.CREATE_CONEKTA_DONATION_REQUEST, createConektaDonation),
    takeLatest(donationTypes.REQUEST_OXXO_PAY_DONATION_REQUEST, requestOxxoPayDonation)
  ])
}
