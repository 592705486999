/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { campaignActions } from 'modules/Campaign/campaign.redux'
import Carousel from './../Carousel/Carousel'
import CarouselSlides from './../Carousel/CarouselSlides'

export const HeaderSlide = props => {
  useEffect(() => {
    props.publicLoadAllBanner()
  }, [props.list])

  const target = 'about-carousel'
  const numberOfSlides = props.show || props.slides.length

  return (
    <Carousel target={target} numberOfSlides={numberOfSlides}>
      <CarouselSlides slides={props.slides} />
    </Carousel>
  )
}

const mapStateToProps = state => ({
  list: state.campaign.list,
  slides: state.campaign.banner
})

const mapDispatchToProps = {
  publicLoadAllBanner: campaignActions.publicLoadAllBannerCampaignRequest
}

HeaderSlide.propTypes = {
  slides: PropTypes.array,
  show: PropTypes.number
}

export default connect(mapStateToProps, mapDispatchToProps)(HeaderSlide)
