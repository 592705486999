import React from 'react'
import { PayPalButton as PayPal } from 'react-paypal-button-v2'
import { donationActions } from 'modules/Donation/donation.redux'
import { connect } from 'react-redux'

function PaypalButton({ amount, campaign, currency, clientId, style, createPaypalDonation }) {
  const handleOnSucess = (details, data) => {
    const payload = { campaign, orderID: data.orderID, paypal: { data, details } }
    createPaypalDonation(payload)
  }

  if (clientId === null) return <p>No disponible</p>

  return (
    <div className='my-2 d-block'>
      <PayPal
        amount={amount}
        style={style}
        options={{ currency, clientId }}
        onSuccess={handleOnSucess}
      />
    </div>
  )
}

PaypalButton.defaultProps = {
  amount: '100',
  currency: 'MXN',
  style: { layout: 'horizontal', tagline: 'false' }
}

const mapStateToProps = ({ configuration }) => ({
  clientId: configuration.public.clients.paypal
})

const mapDispatchToProps = {
  createPaypalDonation: donationActions.createPaypalDonationRequest
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)
export default withConnect(PaypalButton)
