import React from 'react'
import { Form } from 'reactstrap'
import { reduxForm } from 'redux-form'
import FormContainer from 'components/Container/FormContainer'
import { withForm } from 'util/withForm'
import { RFInput } from 'components/Inputs/RFFields'
import { required, substring } from 'util/form'

const limit40 = substring(0, 40)
const limit200 = substring(0, 200)

function CategoryForm(props) {
  const { handleSubmit, onCancel, label, button, option, ...others } = props
  const { pristine, valid, submitting } = others

  return (
    <FormContainer
      title='Categoría'
      label={label}
      button={button}
      onCancel={onCancel}
      onClick={handleSubmit}
      status={{ pristine, valid, submitting }}>
      <Form>
        <RFInput
          name='title'
          type='text'
          label='Titulo'
          normalize={limit40}
          validate={[required]}
        />
        <RFInput
          name='description'
          type='textarea'
          label='Descripción'
          style={{ width: '100%' }}
          normalize={limit200}
          validate={[required]}
        />
      </Form>
    </FormContainer>
  )
}

export default withForm(reduxForm({ form: 'category' })(CategoryForm))
