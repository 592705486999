import React, { Component } from 'react'
import { connect } from 'react-redux'
import Banner from 'layouts/Site/Layouts/Banner'
import { isEmpty } from './../../util/crud'
import { actions as authActions } from './../Auth/auth.redux'
import { userActions } from './user.redux'
import ProfileForm from './ProfileForm'

class ProfileEdit extends Component {
  componentDidMount() {
    const id = this.props.auth.user._id
    this.props.editUserRequest({ id })
  }

  componentWillUnmount() {
    this.props.editUserSuccess()
  }

  onCancel = () => {
    this.props.history.push('/dashboard/user/list')
  }

  submit = values => {
    this.props.updateProfile({ values })
  }

  render() {
    const values = this.props.user.edit

    return (
      <Banner>
        {!isEmpty(values) && (
          <ProfileForm
            {...this.props}
            label='Actualizar'
            button='edit'
            onCancel={this.onCancel}
            onSubmit={this.submit}
            initialValues={values}
          />
        )}
      </Banner>
    )
  }
}

const mapStateToProps = ({ user, auth }) => ({ user, auth })
const mapDispatchToProps = {
  updateProfile: authActions.updateProfileRequest,
  editUserRequest: userActions.editUserRequest,
  editUserSuccess: userActions.editUserSuccess
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfileEdit)
