import { createActions, createReducer } from 'reduxsauce'

const { Types, Creators } = createActions(
  {
    loadAllCounterRequest: ['payload'],
    loadAllCounterSuccess: ['payload'],
    loadAllCounterFailure: ['payload'],

    incrementCounterRequest: ['counter'],
    incrementCounterSuccess: ['counter', 'value'],
    incrementCounterFailure: ['payload']
  },
  {}
)

const initialState = {
  visits: 0,
  donations: 0,
  donors: 0
}

// map our action Types to our reducer functions
export const HANDLERS = {
  [Types.LOAD_ALL_COUNTER_SUCCESS]: function (state, action) {
    return { ...state, ...action.payload }
  },
  [Types.INCREMENT_COUNTER_SUCCESS]: function (state, action) {
    return { ...state, [action.counter]: action.value }
  }
}

export const counterTypes = Types
export const counterActions = Creators
export const counter = createReducer(initialState, HANDLERS)
