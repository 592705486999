import React from 'react'
import { reduxForm, formValueSelector } from 'redux-form'
import { withForm } from 'util/withForm'
import { Form, Button } from 'reactstrap'
import { connect } from 'react-redux'
import CampaignViewPreview from './../CampaignViewPreview/CampaignViewPreview'

function CampaignPreviewForm({ button, previousPage, handleSubmit, ...props }) {
  return (
    // <FormContainer>
    <Form className='mt-5 pt-5'>
      {/* </FormContainer> */}
      <CampaignViewPreview {...props} />
      <div className='mb-5'>
        <Button onClick={previousPage}>Anterior</Button>
        <Button color='primary' className='float-right' onClick={handleSubmit}>
          Guardar
        </Button>
      </div>
    </Form>
    // <br></br>
  )
}

const selector = formValueSelector('campaign')

export default connect(state => ({
  campaign: selector(
    state,
    'title',
    'description',
    'content',
    'category',
    'visibility',
    'thumbnail',
    'goal',
    'duration',
    'date'
  )
}))(
  withForm(
    reduxForm({
      form: 'campaign',
      destroyOnUnmount: false,
      forceUnregisterOnUnmount: true
    })(CampaignPreviewForm)
  )
)
