import React from 'react'

export const ListItem = props => {
  const { strong = true, label, value } = props
  const icon = value ? 'icon-check-square' : 'icon-times'
  const color = value ? '#43d087' : '#ef6462'

  if (typeof value === 'boolean') {
    return (
      <li className='list-unstyled' style={{ padding: '.2rem' }}>
        <i className={`${icon} mr-2`} style={{ fontSize: '1.35rem', color }} />
        <strong>{label}</strong>
      </li>
    )
  }

  return (
    <li className='list-unstyled' style={{ padding: '.2rem' }}>
      {strong ? <strong>{label}:</strong> : label} {value || 'N/A'}
    </li>
  )
}
