import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { isEmpty } from './../../../util/helpers'

export default class CarouselSlides extends Component {
  render() {
    const { slides } = this.props

    if (isEmpty(slides))
      return <div className='hero-wrap js-fullheight' style={{ background: 'gray' }} />

    return slides.map(({ thumbnail, description, slug, title, subtitle }, i) => {
      return (
        <div
          className={`carousel-item hero-wrap background-hero hero-wrap-2 ${
            i === 0 && 'active'
          } `}
          style={{ backgroundImage: `url(${thumbnail.path})` }}
          data-stellar-background-ratio='0.5'
          key={`carouselSlides-${i}`}>
          <div className='hero-wrap js-fullheight'>
            <div className='overlay' />
            <div className='container-fluid px-0'>
              <div className='row d-md-flex no-gutters slider-text align-items-end js-fullheight justify-content-end'>
                <div className='one-forth d-flex align-items-end js-fullheight'>
                  <div className='text mt-2' style={{ minWidth: 800 }}>
                    <span className='subheading'>{subtitle}</span>
                    <h1 className='mb-3'>
                      <span>{title}</span>
                    </h1>
                    <p style={{ fontSize: 18 }}>{description}</p>
                    <p>
                      <Link className='btn btn-primary px-4 py-3' to={`/campaigns/${slug}`}>
                        Ver campaña
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    })
  }
}
