import React, { useState } from 'react'
import { connect } from 'react-redux'
import { actions } from 'modules/Auth/auth.redux'
import Img from 'react-cool-img'
import userThumbnail from 'assets/images/user-thumbnail.png'
import { HashLink as Link } from 'react-router-hash-link'
import { shortTitle } from 'util/helpers'
import { Input, Form, Row, Col } from 'reactstrap'
import {
  clearStateCampaign,
  publicScrollPaginationCampaignRequest,
  loadSearchPaginationCampaignRequest
} from './../../../modules/Campaign/campaignPagination.redux'
import { useHistory } from 'react-router-dom'

export const Navbar = props => {
  const {
    logout,
    admin = false,
    configuration,
    auth: { isAuthenticated, user }
  } = props
  const history = useHistory()
  const name = configuration.settings.name
  const splitName = shortTitle(name)
  const [title, setTitle] = useState('')

  const onChange = event => {
    event.preventDefault()
    setTitle(event.target.value)
  }

  const handleSumbit = e => {
    e.preventDefault()
    if (title.length >= 1) {
      onSubmitSearch({
        title
      })
    } else {
      onSubmitSearchNull()
    }
  }

  const onSubmitSearch = async form => {
    props.clearStateCampaign()
    props.loadSearchPaginationCampaignRequest(1, form.title)
    history.push('/campaigns#content')
    window.scroll({
      top: 740,
      left: 0,
      behavior: 'smooth'
    })
  }

  const onSubmitSearchNull = async () => {
    props.clearStateCampaign()
    props.publicScrollPaginationCampaignRequest()
  }

  return (
    <nav
      className={`navbar navbar-expand-lg navbar-dark  ftco-navbar-light bg-dark p-0 ${
        !admin ? 'ftco_navbar' : 'awake scrolled admin'
      }`}
      id='ftco-navbar'>
      <div className='container'>
        <Link className='nav-link navbar-brand' to='/'>
          <span>{splitName}</span>
          <Img src={configuration.settings.logo} height='50' />
        </Link>
        <button
          className='navbar-toggler'
          type='button'
          data-toggle='collapse'
          data-target='#ftco-nav'
          aria-controls='ftco-nav'
          aria-expanded='false'
          aria-label='Toggle navigation'>
          <span className='oi oi-menu' /> Menú
        </button>

        <div className='collapse navbar-collapse' id='ftco-nav'>
          <ul className='navbar-nav ml-auto d-flex align-items-center'>
            <li className='nav-item'>
              <CampaignFilters
                handleSumbit={handleSumbit}
                title={title}
                onSubmitSearchNull={onSubmitSearchNull}
                onChange={onChange}
              />
            </li>
            <li className='nav-item'>
              <Link exact className='nav-link' to='/'>
                Inicio
              </Link>
            </li>
            <li className='nav-item'>
              <Link to='/about#content' className='nav-link'>
                Nosotros
              </Link>
            </li>

            <li className='nav-item'>
              <Link className='nav-link' to='/campaigns#content'>
                Campañas
              </Link>
            </li>
            <li className='nav-item cta' style={{ cursor: 'pointer' }}>
              {isAuthenticated ? (
                <AuthNavItem logout={logout} {...user} />
              ) : (
                <Link className='nav-link' to='/login#content'>
                  Iniciar sesión
                </Link>
              )}
            </li>
          </ul>
        </div>
      </div>
    </nav>
  )
}

function CampaignFilters({ handleSumbit, onChange, title, onSubmitSearchNull }) {
  return (
    <Form onSubmit={handleSumbit} className='container search-bar filters'>
      <Row>
        <Col>
          <Input
            id='input1'
            name='title'
            type='text'
            placeholder={'Título campaña'}
            onChange={onChange}
          />
          <button type='submit' className={`btn grid-btn-search`}>
            <i className='icon-search' />
          </button>
        </Col>
      </Row>
    </Form>
  )
}

const AuthNavItem = ({ logout, name, lastName, thumbnail, picture, role, _id }) => (
  <li className='nav-item dropdown'>
    <a
      href='/'
      className='nav-link dropdown-toggle'
      id='auth-dropdown'
      data-toggle='dropdown'
      style={{ fontSize: 16 }}
      aria-expanded='false'>
      <Img
        className='mr-3 object-fit-cover'
        src={thumbnail?.path || picture || userThumbnail}
        width='30'
        height='30'
        alt=''
      />
      {name} {lastName}
    </a>
    <ul className='dropdown-menu' aria-labelledby='auth-dropdown'>
      {(role === 0 || role === 1) && <DashboardItems role={role} id={_id} />}
      <li>
        <div
          className='dropdown-item'
          onClick={logout}
          onKeyPress={() => {}}
          role='presentation'>
          Cerrar sesión
        </div>
      </li>
    </ul>
  </li>
)

const DashboardItems = ({ id }) => {
  return (
    <li>
      <Link className='dropdown-item' to='/dashboard/menu'>
        Dashboard
      </Link>
      <Link className='dropdown-item' to={`/dashboard/user-admin/profile/${id}`}>
        Mi perfil
      </Link>
    </li>
  )
}

const mapStateToProps = ({ auth, configuration }) => ({
  auth,
  configuration: configuration.public
})

const mapDispatchToProps = {
  logout: actions.logoutRequest,
  clearStateCampaign,
  publicScrollPaginationCampaignRequest,
  loadSearchPaginationCampaignRequest
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)

export default withConnect(Navbar)
