import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { Form, Row, Col, Table } from 'reactstrap'
import { reduxForm, formValueSelector, FieldArray, Field } from 'redux-form'
import { RFInput, RFUploader, RFCheckbox } from 'components/Inputs/RFFields'
import FormContainer from 'components/Container/FormContainer'
import BasicCard from 'components/Cards/BasicCard'
import { withForm } from 'util/withForm'

import { userActions } from './user.redux'
import { isEmpty } from './../../util/helpers'
import { required, substring, isEmail } from './../../util/form'
import { Input } from 'components/Inputs/RFInput'

const limit25 = substring(0, 25)

const RenderPermissions = ({ fields, options }) => {
  const actions = ['read', 'create', 'update']

  return (
    <Table className='matrix-permissions'>
      <thead>
        <tr className='text-center'>
          <th>Módulo</th>
          <th>Consultar</th>
          <th>Crear</th>
          <th>Actualizar</th>
        </tr>
      </thead>
      <tbody>
        {fields.map(p => {
          return (
            <tr>
              <td className='text-center'>
                <Field
                  disabled
                  component={Input}
                  name={`${p}.module`}
                  type='select'
                  parentClassName='list-inline-item text-left'
                  validate={[required]}>
                  {!isEmpty(options) &&
                    options.map(({ _id, label }) => (
                      <option key={_id} value={_id}>
                        {label}
                      </option>
                    ))}
                </Field>
              </td>
              {actions.map(action => (
                <td className='text-center'>
                  <RFCheckbox name={`${p}.${action}`} defaultValue={false} />
                </td>
              ))}
            </tr>
          )
        })}
      </tbody>
    </Table>
  )
}

function ProfileForm({ handleSubmit, option, pristine, valid, submitting, ...props }) {
  const status = { pristine, valid, submitting }

  return (
    <Fragment>
      <FormContainer {...props} title='Usuarios' onClick={handleSubmit} status={status}>
        <Form>
          <Row>
            <Col md='12'>
              <RFUploader name='thumbnail' label='Foto de perfil' validate={[required]} />
            </Col>
          </Row>
          <Row>
            <Col md='6'>
              <RFInput name='name' label='Nombre' normalize={limit25} validate={[required]} />
            </Col>
            <Col md='6'>
              <RFInput
                name='lastName'
                label='Apellidos'
                normalize={limit25}
                validate={[required]}
              />
            </Col>
          </Row>
          <Row>
            <Col md='6'>
              <RFInput
                name='email'
                type='email'
                label='Correo electrónico'
                validate={[required, isEmail]}
              />
            </Col>
          </Row>
        </Form>
      </FormContainer>
      {/* eslint-disable-next-line eqeqeq */}
      {props.role == 1 && !isEmpty(props.modules) && (
        <div className='w-100' key={props.permissions.length}>
          <BasicCard header='Matriz de permisos' className='px-3'>
            <FieldArray
              name='permissions'
              component={RenderPermissions}
              options={props.modules}
            />
          </BasicCard>
        </div>
      )}
    </Fragment>
  )
}

const defaultPermissions = module => {
  return {
    module: module._id,
    read: false,
    create: false,
    update: false,
    delete: false,
    isNew: true
  }
}

const mergePermissions = (modules = [], permissions = []) => {
  return modules.map(item => {
    const permissionIdx = permissions.findIndex(p => p.module === item._id)
    return permissionIdx === -1 ? defaultPermissions(item) : permissions[permissionIdx]
  })
}

const mapStateToProps = (state, { initialValues }) => {
  const selector = formValueSelector('user')
  const values = !isEmpty(initialValues) ? initialValues : {}
  const { modules } = state.user.options
  const permissions = mergePermissions(modules, values.permissions)
  const role = selector(state, 'role')

  const result = {
    initialValues: { ...values, permissions },
    permissions: selector(state, 'permissions'),
    role: role ? role.value : null,
    user: state.auth.user,
    modules
  }

  return result
}

const withConnect = connect(mapStateToProps, userActions)
export default withConnect(withForm(reduxForm({ form: 'user' })(ProfileForm)))
