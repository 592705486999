/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { columns, donationLineAndPieChart } from './oxxoPay.helpers'
import { loadCountersOxxoPayRequest, loadAllPaginationOxxoPayRequest } from './oxxoPay.redux'
import Banner from 'layouts/Site/Layouts/Banner'
import TableContainer from 'components/Container/TableContainer'
import DataTable from 'components/Table/TablePagination'
import OxxoPayCharts from './OxxoPayCharts'
import './OxxoPay.css'

function OxxoPayList(props) {
  const { listOxxoPay, info } = props

  const { docs } = listOxxoPay
  const data = docs?.data || []
  const totalDocs = docs?.totalDocs
  const pageAct = docs?.page
  const limit = docs?.limit

  useEffect(() => {
    props.loadCountersOxxoPayRequest()
    props.loadAllPaginationOxxoPayRequest(props.filters)
  }, [props.loadCountersOxxoRequest, props.loadAllPaginationOxxoRequest])

  const rowEvents = {
    onClick: (e, row) => {
      props.history.push(`/dashboard/donation/oxxo/view/${row._id}`)
    }
  }

  const handleTableChange = (type, { page, sizePerPage, filter }) => {
    props.filters.page = page
    if (filter) props.filters.search = filter ? filter : undefined
    props.loadAllPaginationOxxoPayRequest(props.filters)
  }

  const { donations, list } = props.donation
  const chart = donationLineAndPieChart(list, donations)

  return (
    <Banner admin>
      <div className='mx-3 mt-2 header-h2'>
        <h2 className='mb-3 text-left header-title'>Donaciones con OXXO Pay</h2>
      </div>
      <div className='mx-3 mt-5 pt-4 row'></div>
      <div className='box-container'>
        <div className='information-box'>
          <label className='information-label'>Total</label>
          <label>${info.total}</label>
        </div>
        <div className='information-box'>
          <label className='information-label'>Numero de donadores</label>
          <label>{info.donors}</label>
        </div>
      </div>
      <OxxoPayCharts {...chart} />
      <TableContainer
        showButtons={false}
        subtitle='Lista'
        route='/dashboard/donation/create'
        module='Donaciones'>
        <DataTable
          {...props}
          data={data}
          totalDocs={totalDocs}
          pageAct={pageAct}
          limit={limit}
          structure={columns}
          rowEvents={rowEvents}
          filter={props.filters}
          onTableChange={handleTableChange}
        />
      </TableContainer>
    </Banner>
  )
}

const mapStateToProps = ({ donation, oxxo }) => ({
  donation,
  listOxxoPay: oxxo.list,
  info: oxxo.info,
  filters: oxxo.filters
})

const mapDispatchToProps = {
  loadCountersOxxoPayRequest,
  loadAllPaginationOxxoPayRequest
}

export default connect(mapStateToProps, mapDispatchToProps)(OxxoPayList)
