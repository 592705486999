import React, { Component } from 'react'
import { connect } from 'react-redux'
import { userActions } from './user.redux'
import Banner from 'layouts/Site/Layouts/Banner'
import UserForm from './UserForm'

class UserCreate extends Component {
  submit = values => {
    this.props.createUser(values)
  }

  render() {
    return (
      <Banner>
        <UserForm {...this.props} label='Agregar' button='add' onSubmit={this.submit} />
      </Banner>
    )
  }
}

const mapStateToProps = ({ user }) => ({ user })
const mapDispatchToProps = {
  createUser: userActions.createUserRequest
}
export default connect(mapStateToProps, mapDispatchToProps)(UserCreate)
