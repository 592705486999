import React, { Fragment } from 'react'
import DonationButtons from './DonationButtons'
import './DonationPanel.css'

export function DonationPanel(props) {
  const donors = props.donations.length

  return (
    <center className='donation-card'>
      {props.visibility.counter && (
        <DonationPanelProgress goal={props.goal} donations={props.donations} />
      )}
      {props.hours > 0 ? (
        <DonationPanelContent
          hours={props.hours}
          days={props.days}
          donors={donors}
          {...props}
        />
      ) : (
        <GratitudeMessage donors={donors} />
      )}
    </center>
  )
}

function DonationPanelProgress({ donations = [], goal }) {
  const donated = donations
    .filter(donation => donation.type !== 'failed')
    .reduce((accum, donation) => accum + donation.amount, 0)
  const progress = (donated * 100) / goal
  return (
    <Fragment>
      <div className='donations-needed'>
        <p>
          <b>${donated}</b> recaudados de <b>${goal}</b>
        </p>
      </div>
      <div className='indicator-wrap'>
        <div id='indicator' style={{ width: `${progress}%` }} />
      </div>
    </Fragment>
  )
}

function DonationPanelContent({ days, hours, donors, visibility }) {
  const countDonors = donors > 0 && donors
  return (
    <div className='card'>
      {visibility.countdown && days > 0 && (
        <p className='text-center'>
          <span className='orange-text'>Quedan {days} días</span> para donar.
        </p>
      )}
      {visibility.countdown && days <= 0 && hours > 0 && (
        <p className='text-center'>
          <span className='orange-text'>Quedan {hours} horas</span> para donar.
        </p>
      )}
      <p>
        Únete a los otros <b>{countDonors}</b> donadores que ya han apoyado esta campaña.
      </p>
      <DonationButtons />
    </div>
  )
}

function GratitudeMessage({ donors }) {
  return (
    <div className='card'>
      <strong>Esta campaña ha finalizado.</strong>
      <p className='mt-2'>
        Agradecemos de corazón a los <span className='orange-text'>{donors} donadores</span>{' '}
        que nos brindaron su apoyo y confianza.
      </p>
    </div>
  )
}

DonationPanel.defaultProps = {
  goal: 0,
  donors: 0,
  visibility: {}
}
