import axios from 'axios'
import { all, takeLatest, call, put } from 'redux-saga/effects'
import {
  LOAD_COUNTERS_OXXO_PAY_REQUEST,
  LOAD_ALL_DONATION_OXXO_PAY_PAGINATION_REQUEST,
  LOAD_ONE_DONATION_OXXO_PAY_REQUEST,
  loadCountersOxxoPaySuccess,
  loadCountersOxxoPayFailure,
  loadAllPaginationOxxoPaySuccess,
  loadAllPaginationOxxoPayFailure,
  donationOxxoPayGetOneSuccess,
  donationOxxoPayGetOneFailure
} from './oxxoPay.redux'

/**
 * Show donation information, all and how many
 */
export function* oxxoPayCounters() {
  try {
    const url = '/api/donations/conekta/oxxo/counters/info'
    const { data } = yield call(axios.get, url)

    yield put(loadCountersOxxoPaySuccess(data))
  } catch (error) {
    yield put(loadCountersOxxoPayFailure(error))
  }
}

/**
 * Help function to filter data
 * @param   {object}   data returns page number for pagination
 */
function encodeFiltersData(data) {
  return Object.keys(data)
    .filter(f => data[f] !== '' && data[f] !== null)
    .map(f => encodeURIComponent(f) + '=' + encodeURIComponent(data[f]))
    .join('&')
}

/**
 * Show paged OxxoPay donations
 * @returns {iterator}
 */
export function* oxxoPayDonationPagination({ filters }) {
  try {
    const filterString = encodeFiltersData(filters)
    const url = `/api/donations/conekta/oxxo?${filterString}`
    const { data } = yield call(axios.get, url)

    yield put(loadAllPaginationOxxoPaySuccess(data))
  } catch (error) {
    yield put(loadAllPaginationOxxoPayFailure(error.message))
  }
}

/**
 * Display a single donation record
 * @param   {object}   action.payload Data to take the id of the requested institution
 * @returns {iterator}
 */
export function* oxxoPayDonationOne({ id } = {}) {
  try {
    const url = `/api/donations/${id}`
    const { data } = yield call(axios.get, url)
    yield put(donationOxxoPayGetOneSuccess(data))
  } catch (error) {
    yield put(donationOxxoPayGetOneFailure(error.message))
  }
}

export function* oxxoPaySagas() {
  yield all([
    takeLatest(LOAD_COUNTERS_OXXO_PAY_REQUEST, oxxoPayCounters),
    takeLatest(LOAD_ALL_DONATION_OXXO_PAY_PAGINATION_REQUEST, oxxoPayDonationPagination),
    takeLatest(LOAD_ONE_DONATION_OXXO_PAY_REQUEST, oxxoPayDonationOne)
  ])
}
