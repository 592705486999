/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { Banner } from 'layouts/Site/Layouts/Banner'
import TableContainer from 'components/Container/TableContainer'
import { ListItem } from 'components/Information/ListItem'
import { pagesActions } from './pages.redux'
import { isEmpty } from './../../util/crud'
import Acl from 'components/Acl/Acl'

function PagesView(props) {
  useEffect(() => {
    const { id } = props.match.params
    props.loadPagesRequest({ id })
    return () => {
      props.loadPagesSuccess()
    }
  }, [])

  const { view } = props.pages

  return (
    <Banner>
      {!isEmpty(view) && (
        <TableContainer
          title='Páginas'
          subtitle='Información del registro'
          buttonAdd={false}
          toggle={props.togglePagesRequest}
          view={view}
          actionButton={
            <Acl action='update'>
              <EditButton id={view._id} />
              <ToggleButton view={view} toggle={props.togglePagesRequest} />
            </Acl>
          }>
          <ul>
            <ListItem label='Título' value={view.title} />
            <div
              className='campaign-content'
              dangerouslySetInnerHTML={{ __html: view.description }}
            />
          </ul>
        </TableContainer>
      )}
    </Banner>
  )
}

const EditButton = ({ id }) => {
  return (
    <Link className='no-style mr-2' to={`/dashboard/pages/edit/${id}`}>
      <button className='btn btn-info'>
        <i className='icon-edit mr-2' style={{ verticalAlign: 'text-bottom' }} />
        Editar
      </button>
    </Link>
  )
}

const ToggleButton = ({ view, toggle }) => {
  return (
    <button
      className={`btn btn-${view.active ? 'danger' : 'success'} mr-2`}
      onClick={() => toggle(view)}>
      <i className='icon-power-off mr-2' style={{ verticalAlign: 'text-bottom' }} />
      {view.active ? 'Desactivación' : 'Activación'}
    </button>
  )
}

const mapStateToProps = ({ pages }) => ({ pages })
export default connect(mapStateToProps, pagesActions)(PagesView)
