import React from 'react'
import PropTypes from 'prop-types'
import { Input as ReactstrapInput } from 'reactstrap'

export const Input = ({ input, ...props }) => {
  return (
    <div>
      <ReactstrapInput {...props} {...input} />
    </div>
  )
}

Input.propTypes = {
  type: PropTypes.string,
  input: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired
  })
}

Input.defaultProps = {
  type: 'text'
}
