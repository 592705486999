import React, { Component } from 'react'
import { Container } from 'reactstrap'
import HeaderPreview from './HeaderPreview'
import ProgressBar from 'components/ProgressBar/ProgressBar'
import Loading from 'components/Loading/Loading'

class BaseViewPreview extends Component {
  render() {
    const { children } = this.props
    return (
      <Container fluid className='px-0'>
        <Loading />
        <ProgressBar />
        <HeaderPreview {...this.props.campaign} />
        {children}
      </Container>
    )
  }
}

export default BaseViewPreview
