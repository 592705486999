import React from 'react'
import Navbar from 'layouts/Site/Navbar/Navbar'
import Donadora from './donadora'

function DonadoraView() {
  return (
    <div>
      <Navbar admin />
      <Donadora></Donadora>
    </div>
  )
}

export default DonadoraView
