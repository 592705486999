import React, { useState, useEffect } from 'react'
import ReactSelect from 'react-select'
import PropTypes from 'prop-types'
import axios from 'axios'

// Select (Native), requires options and formatter function
export const Select = ({ input, defaultLabel, ...props }) => {
  const options =
    typeof props.formatter === 'function' ? props.options.map(props.formatter) : props.options
  return (
    <ReactSelect
      {...props}
      {...input}
      onBlur={event => event.preventDefault()}
      options={options}
    />
  )
}

// Asynchronous select, caution with re-render
export const SelectAsync = props => {
  const [options, setOptions] = useState([])
  useEffect(() => {
    axios
      .get(props.url)
      .then(result => result.data.map(props.formattter))
      .then(setOptions)
      .catch(console.log)
  }, [props.formattter, props.url])

  return <Select {...props} options={options} />
}

Select.propTypes = {
  defaultLabel: PropTypes.string,
  formatter: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired
    })
  )
}

SelectAsync.propTypes = {
  url: PropTypes.string.isRequired,
  formatter: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired
    })
  )
}

Select.defaultProps = {
  options: [],
  defaultLabel: ''
}

SelectAsync.defaultProps = {
  options: []
}
