import React, { Component } from 'react'
import { connect } from 'react-redux'
import { reset, initialize } from 'redux-form'
import Banner from 'layouts/Site/Layouts/Banner'
import CampaignForm from './CampaignForm'
import { campaignActions } from './campaign.redux'

class CampaignCreate extends Component {
  componentDidMount() {
    this.props.loadCampaignOptions()
    this.props.initialize('campaign', {})
  }

  componentWillUnmount() {
    this.props.resetForm('campaign')
  }

  submit = values => {
    this.props.createCampaign(values)
  }

  render() {
    return (
      <Banner>
        <CampaignForm {...this.props} label='Agregar' button='add' onSubmit={this.submit} />
      </Banner>
    )
  }
}

const mapStateToProps = ({ campaign }) => ({ campaign })
const mapDispatchToProps = {
  createCampaign: campaignActions.createCampaignRequest,
  loadCampaignOptions: campaignActions.loadCampaignOptionsRequest,
  resetForm: reset,
  initialize
}

export default connect(mapStateToProps, mapDispatchToProps)(CampaignCreate)
