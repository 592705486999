import React, { Component } from 'react'
import { connect } from 'react-redux'
import { campaignActions } from './campaign.redux'
import { Banner } from 'layouts/Site/Layouts/Banner'
import { Row, Col } from 'reactstrap'
import { mdy } from './../../util/dates'
import { isEmpty } from './../../util/crud'
import TableContainer from 'components/Container/TableContainer'
import { ListItem } from 'components/Information/ListItem'

class CampaignView extends Component {
  componentDidMount() {
    const { id } = this.props.match.params
    this.props.loadCampaignRequest({ id })
  }

  componentWillUnmount() {
    this.props.loadCampaignSuccess()
  }

  render() {
    const { view } = this.props.campaign
    return (
      <Banner>
        <TableContainer
          title='Campaña'
          subtitle='Información del registro'
          toggle={this.props.toggleCampaignRequest}
          view={view}>
          {!isEmpty(view) && (
            <Row id='view-campaign'>
              <Col md='6'>
                <ul>
                  <ListItem label='Título' value={view.title} />
                  <ListItem label='Descripción' value={view.description} />
                  {view.goal > 0 && (
                    <ListItem label='Meta' value={`$${Number(view.goal).toFixed(2)} MXN`} />
                  )}
                  <ListItem
                    label='Categorías'
                    value={view.category.map(item => item.title).join(', ')}
                  />
                  {view.institution && (
                    <ListItem label='Institución' value={view.institution.name} />
                  )}
                  <ListItem label='Duración' value={`${view.duration} días`} />
                  <ListItem label='Fecha de publicación' value={mdy(view.date)} />
                </ul>
              </Col>
              <Col md='6'>
                <ul>
                  {view.goal > 0 && (
                    <ListItem label='Mostrar contador' value={view.visibility.counter} />
                  )}
                  {view.goal > 0 && (
                    <ListItem
                      label='Mostrar cuenta regresiva'
                      value={view.visibility.countdown}
                    />
                  )}
                  <ListItem label='Mostrar comentarios' value={view.visibility.comments} />
                  <ListItem label='Activo' value={view.active} />
                  <ListItem label='Creado' value={mdy(view.createdAt)} />
                  <ListItem label='Última modificación' value={mdy(view.updatedAt)} />
                </ul>
              </Col>
              <hr />
              {view.gratitude && view.goal > 0 && (
                <Col md='12'>
                  <div style={{ padding: '10px 40px' }}>
                    <strong>Mensaje de agradecimiento por donación</strong>
                    <p>{view.gratitude}</p>
                  </div>
                </Col>
              )}
            </Row>
          )}
        </TableContainer>
      </Banner>
    )
  }
}

const mapStateToProps = ({ campaign }) => ({ campaign })
export default connect(mapStateToProps, campaignActions)(CampaignView)
