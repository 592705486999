import React, { Component } from 'react'
import { pick } from './helpers'

/**
 * HOC to wrap a redux form's HOC wrapped component,
 * this will filter the 'values' to only consider the ones wich match with
 * the registeres fields (the inputs fields on the form)
 * @author  https://stackoverflow.com/questions/54014157/redux-forms-ignore-initialvalues-with-no-registered-field
 */
export function withForm(WrappedForm, fields = []) {
  return class extends Component {
    submissionFilter(submitHandler) {
      return function (values, dispatch, props) {
        // Only let registered fields through
        if (props.registeredFields) {
          values = pick(values, [...Object.keys(props.registeredFields), ...fields])
        }

        return submitHandler(values, dispatch, props)
      }
    }

    render() {
      let submitHandler = this.props.onSubmit
      if (submitHandler) {
        submitHandler = this.submissionFilter(submitHandler)
      }
      if (this.props.button === 'add') {
        return <WrappedForm {...this.props} />
      }
      return <WrappedForm {...this.props} onSubmit={submitHandler} />
    }
  }
}
