import React from 'react'
import FormWrapper from '../FormWrapper'
import { Button, Row } from 'reactstrap'
import { reduxForm } from 'redux-form'
import { RFInput } from 'components/Inputs/RFFields'
import { required, substring } from 'util/form'

const limit40 = substring(0, 40)
const limit200 = substring(0, 200)

function FormCategory({ handleSubmit, previousPage, label }) {
  return (
    <FormWrapper title={label}>
      <p>Agrega una primera categoría para la creación de tus campañas.</p>
      <Row style={{ minHeight: 287 }}>
        <RFInput
          name='category.title'
          label='Título de la categoría'
          placeholder='Ejemplo: Comunidad'
          normalize={limit40}
          validate={[required]}
        />
        <RFInput
          type='textarea'
          name='category.description'
          label='Descripción de la categoría'
          normalize={limit200}
          validate={[required]}
        />
      </Row>
      <Button color='secondary' id='prev-button' onClick={previousPage}>
        Atrás
      </Button>
      <Button color='primary' id='next-button' onClick={handleSubmit}>
        Guardar
      </Button>
    </FormWrapper>
  )
}

export default reduxForm({
  form: 'initial-configuration',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true
})(FormCategory)
