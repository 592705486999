import React, { useState } from 'react'
import { connect } from 'react-redux'
import './SearchBar.css'

const SearchBar = props => {
  const { filter, limit, onTableChange } = props

  const [amount, setAmount] = useState(filter.amount)
  const [campaign, setCampaign] = useState(filter.campaign)
  const [name, setName] = useState(filter.name)
  const [email, setEmail] = useState(filter.email)
  const [startDate, setStartDate] = useState(filter.startDate)
  const [endDate, setEndDate] = useState(filter.endDate)

  let input
  let input2
  let input3
  let input4
  let input5
  let input6

  const handleClick = () => {
    filter.search = ''
    onTableChange(amount, {
      page: 1,
      sizePerPage: limit,
      filter: { amount, campaign, name, email, startDate, endDate }
    })
  }

  return (
    <div className='form-container'>
      <div className='form-container-inputs-1'>
        <input
          key='SearchEditor1'
          id='SearchEditor1'
          className='search-bar w-200 form-control rounded'
          placeholder='Monto'
          ref={n => (input = n)}
          onChange={() => setAmount(input.value)}
          type='number'
        />
        <input
          key='SearchEditor2'
          id='SearchEditor2'
          className='search-bar w-200 form-control rounded'
          placeholder='Campaña'
          ref={n => (input2 = n)}
          onChange={() => setCampaign(input2.value)}
          type='text'
        />
        <input
          key='SearchEditor3'
          id='SearchEditor3'
          className='search-bar w-200 form-control rounded'
          placeholder='Donador'
          ref={n => (input3 = n)}
          onChange={() => setName(input3.value)}
          type='text'
        />
        <input
          key='SearchEditor4'
          id='SearchEditor4'
          className='search-bar w-200 form-control rounded'
          placeholder='Correo'
          ref={n => (input4 = n)}
          onChange={() => setEmail(input4.value)}
          type='email'
        />
      </div>
      <div className='form-container-inputs-2'>
        <input
          key='SearchEditor5'
          id='SearchEditor5'
          className='search-bar w-200 form-control rounded'
          placeholder='Fecha'
          ref={n => (input5 = n)}
          onChange={() => setStartDate(input5.value)}
          type='date'
        />
        <input
          key='SearchEditor5'
          id='SearchEditor5'
          className='search-bar w-200 form-control rounded'
          placeholder='Fecha'
          ref={n => (input6 = n)}
          onChange={() => setEndDate(input6.value)}
          type='date'
        />
        <button
          className='btn btn-primary'
          style={{ height: '40px', width: '50%', margin: '0 0 0 10px' }}
          onClick={handleClick}>
          <span style={{ margin: '0 0 0 0' }}>Buscar</span>
        </button>
      </div>
    </div>
  )
}

const mapStateToProps = ({ donation, paypal }) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(SearchBar)
