import React, { Fragment } from 'react'
import './oxxo-pay-stub.css'
import oxxopay from 'assets/images/oxxopay_brand.png'
import { Button } from 'reactstrap'
import { jsPDF } from 'jspdf'
import html2canvas from 'html2canvas'
import { useSelector } from 'react-redux'
import Img from 'react-cool-img'

export default function OxxoPayStub({ amount, reference, barcode }) {
  const name = useSelector(state => state.configuration.public.settings.name)
  const handleSave = event => {
    event.preventDefault()

    window.scrollTo(0, 0)
    const element = document.getElementById('oxxo-pay-stub')
    html2canvas(element).then(canvas => {
      const imgData = canvas.toDataURL('image/png')
      const pdf = new jsPDF('p', 'mm', 'letter')
      const width = 130 || pdf.internal.pageSize.getWidth()
      const ratio = canvas.width / canvas.height
      const height = width / ratio

      const pageWidth = pdf.internal.pageSize.getWidth()
      const pageHeight = pdf.internal.pageSize.getHeight()

      const marginX = (pageWidth - width) / 2
      const marginY = (pageHeight - height) / 2

      pdf.addImage(imgData, 'PNG', marginX, marginY, width, height)
      pdf.save('oxxo-pay.pdf')
    })
  }

  return (
    <Fragment>
      <div className='opps' id='oxxo-pay-stub'>
        <div className='opps-header'>
          <div className='opps-reminder'>Ficha digital. No es necesario imprimir.</div>
          <div className='opps-info'>
            <div className='opps-brand'>
              <Img src={oxxopay} alt='OXXOPay' />
            </div>
            <div className='opps-ammount'>
              <h3>Monto a pagar</h3>
              <h2>
                $ {Number(amount).toFixed(2)} <sup>MXN</sup>
              </h2>
              <p>OXXO cobrará una comisión adicional al momento de realizar el pago.</p>
            </div>
          </div>
          <div className='opps-reference'>
            <h3>Referencia</h3>
            <h1>{reference}</h1>
          </div>
        </div>
        <div className='opps-instructions'>
          <h3>Instrucciones</h3>
          <ol>
            <li>
              Acude a la tienda OXXO más cercana.{' '}
              <a
                href='https://www.google.com.mx/maps/search/oxxo/'
                target='_blank'
                rel='noopener noreferrer'>
                Encuéntrala aquí
              </a>
              .
            </li>
            <li>
              Indica en caja que quieres realizar un pago de <strong>OXXOPay</strong>.
            </li>
            <li>
              Dicta al cajero el número de referencia en esta ficha para que tecleé directamete
              en la pantalla de venta.
            </li>
            <li>Realiza el pago correspondiente con dinero en efectivo.</li>
            <li>
              Al confirmar tu pago, el cajero te entregará un comprobante impreso.{' '}
              <strong>En el podrás verificar que se haya realizado correctamente.</strong>{' '}
              Conserva este comprobante de pago.
            </li>
          </ol>
          <div className='opps-footnote'>
            Al completar estos pasos recibirás un correo de <strong>{name}</strong> confirmando
            tu pago.
          </div>
          <Img
            src={barcode}
            alt='barcode'
            className='d-block mb-0'
            style={{ margin: '20px auto', width: 130 }}
          />
        </div>
      </div>

      <Button color='primary' block onClick={handleSave}>
        Guardar PDF
      </Button>
    </Fragment>
  )
}

OxxoPayStub.defaultProps = {
  amount: 100,
  reference: '0000-0000-0000-00'
}
