/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import VerticalTimeline from 'components/Historical/VerticalTimeline'
import Banner from 'layouts/Site/Layouts/Banner'
import { historicalActions } from './historical.redux'
import { connect } from 'react-redux'
import { Row, Col } from 'reactstrap'
import { decorateHistorical } from './historical.helpers'
import { ListItem } from 'components/Information/ListItem'
import { mdy } from 'util/dates'
import thumbnail from 'assets/images/user-thumbnail.png'

function HistoricalByUser({ historical, loadHistoricalByUser, user, ...props }) {
  useEffect(() => {
    const { id } = props.match.params
    loadHistoricalByUser({ id })
  }, [])
  const roles = ['Administrador total', 'Administrador restringido', 'Usuario']

  return (
    <div>
      <Banner admin>
        <div className='mx-3 mt-5 pt-4 header-h2 d-flex justify-content-between align-items-center'>
          <h2 className='mb-3 mt-4 text-left'>Histórico de eventos por usuario</h2>
        </div>
        <Row>
          <Col md={{ size: 7 }}>
            <div className='overflow-box' style={{ height: 420, overflowY: 'scroll' }}>
              <VerticalTimeline values={historical} />
            </div>
          </Col>
          <Col md={{ size: 5 }}>
            <Row style={{ background: 'whitesmoke', padding: 20 }}>
              <Col md='2'>
                <img
                  alt={user.name}
                  src={user.thumbnail ? user.thumbnail.path : thumbnail}
                  height='100'
                  width='100'
                  className='d-block mx-auto'
                  border='5'
                  style={{
                    padding: '0.4rem',
                    objectFit: 'cover',
                    float: 'left',
                    paddingRight: 10
                  }}
                />
                <ListItem label='Activo' value={user.active} />
              </Col>
              <Col md='10'>
                <ul>
                  <ListItem label='Nombre' value={user.name} />
                  <ListItem label='Apellidos' value={user.lastName} />
                  <ListItem label='Correo' value={user.email} />
                  <ListItem label='Rol' value={roles[user.role]} />
                  <ListItem label='Creado' value={mdy(user.createdAt)} />
                </ul>
              </Col>
            </Row>
          </Col>
        </Row>
      </Banner>
    </div>
  )
}

const mapStateToProps = ({ historical }) => ({
  historical: decorateHistorical(historical.filtered),
  user: historical.user
})

const mapDispatchToProps = {
  loadHistoricalByUser: historicalActions.loadHistoricalByUserRequest
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)
export default withConnect(HistoricalByUser)
