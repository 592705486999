import axios from 'axios'
import { all, takeLatest, call, put } from 'redux-saga/effects'
import { counterActions, counterTypes } from './counter.redux'

export function* loadAllCounter() {
  try {
    const url = '/api/counters/'
    const { data: donations } = yield call(axios.get, url + 'donations')
    const { data: donors } = yield call(axios.get, url + 'donors')
    const { data: visits } = yield call(axios.get, url + 'visits')
    const counters = { donations: donations.total, donors: donors.total, visits: visits.total }
    yield put(counterActions.loadAllCounterSuccess(counters))
  } catch (error) {
    yield put(counterActions.loadAllCounterFailure(error))
    console.log(error)
  }
}

export function* incrementCounter({ counter = 'visits' }) {
  try {
    const url = '/api/counters/' + counter
    const { data } = yield call(axios.post, url)
    yield put(counterActions.incrementCounterSuccess(counter, data.total))
  } catch (error) {
    yield put(counterActions.incrementCounterFailure(error))
    console.log(error)
  }
}

export function* counterSagas() {
  yield all([
    takeLatest(counterTypes.LOAD_ALL_COUNTER_REQUEST, loadAllCounter),
    takeLatest(counterTypes.INCREMENT_COUNTER_REQUEST, incrementCounter)
  ])
}
