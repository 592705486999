import React from 'react'
import { connect } from 'react-redux'
import { userActions } from 'modules/Users/user.redux'
import profile from 'assets/images/user-thumbnail.png'
import { Link } from 'react-router-dom'
import Img from 'react-cool-img'
import './userCard.css'

function UserCard(props) {
  const CardItem = ({ className, title, href, label }) => {
    return (
      <li className={className}>
        <a title={title} href={href}>
          {label}
        </a>
      </li>
    )
  }

  return (
    <div>
      {props.users.map(user => (
        <div className='contact-card'>
          <div className='contact-card__header-image'>
            <Img
              className='contact-card__avatar'
              src={user.picture ? user.picture : profile}
            />
          </div>
          <p className='contact-card__name'>{user.fullName}</p>
          <ul className=' p-1 m-1'>
            <CardItem
              className='contact-card__link contact-card__link--email'
              title='Email Callum Brown'
              label={user.email}
            />
          </ul>
          <ul className='contact-card__footer p-1 m-1'>
            <Link to={'./view/' + user._id}>
              <button className='btn btn-prymary'>VER</button>
            </Link>
          </ul>
        </div>
      ))}
    </div>
  )
}

const mapStateToProps = ({ user }) => ({ users: user.list })
export default connect(mapStateToProps, userActions)(UserCard)
