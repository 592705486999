import { createActions, createReducer } from 'reduxsauce'
import * as handlers from './../../redux/helpers/handlers'

const { Types, Creators } = createActions(
  {
    loadAllInstitutionRequest: ['payload'],
    loadAllInstitutionSuccess: ['payload'],
    loadAllInstitutionFailure: ['payload'],

    loadInstitutionRequest: ['payload'],
    loadInstitutionSuccess: ['payload'],
    loadInstitutionFailure: ['payload'],

    createInstitutionRequest: ['payload'],
    createInstitutionSuccess: ['payload'],
    createInstitutionFailure: ['payload'],

    editInstitutionRequest: ['payload'],
    editInstitutionSuccess: ['payload'],
    editInstitutionFailure: ['payload'],

    updateInstitutionRequest: ['payload'],
    updateInstitutionSuccess: ['payload'],
    updateInstitutionFailure: ['payload'],

    toggleInstitutionRequest: ['payload'],
    toggleInstitutionSuccess: ['payload'],
    toggleInstitutionFailure: ['payload'],

    deleteInstitutionRequest: ['payload'],
    deleteInstitutionSuccess: ['payload'],
    deleteInstitutionFailure: ['payload']
  },
  {}
)

const initialState = {
  list: [],
  view: {},
  edit: {}
}

// map our action Types to our reducer functions
export const HANDLERS = {
  [Types.LOAD_ALL_INSTITUTION_SUCCESS]: handlers.loadAllSuccess,
  [Types.LOAD_INSTITUTION_SUCCESS]: handlers.loadSuccess,
  [Types.CREATE_INSTITUTION_SUCCESS]: handlers.createSuccess,
  [Types.EDIT_INSTITUTION_SUCCESS]: handlers.editSuccess,
  [Types.UPDATE_INSTITUTION_SUCCESS]: handlers.updateSuccess,
  [Types.TOGGLE_INSTITUTION_SUCCESS]: handlers.toggleSuccess,
  [Types.DELETE_INSTITUTION_SUCCESS]: handlers.deleteSuccess
}

export const institutionTypes = Types
export const institutionActions = Creators
export const institution = createReducer(initialState, HANDLERS)
