import React from 'react'
import './ViewsButton.css'

export default function ViewButton({ count }) {
  return (
    <button className='views__btn animated'>
      <i className='views__icon icon-eye'></i>
      <span className='views__number'>{count}</span>
    </button>
  )
}

ViewButton.defaultProps = {
  count: 0
}
