import { isEmpty } from './../../util/helpers'

export const isLoading = type => {
  if (
    !isEmpty(type) &&
    (type.includes('LOAD') ||
      type.includes('SELECT') ||
      type.includes('EDIT') ||
      type.includes('LOGIN')) &&
    type.substring(type.length - 8) === '_REQUEST'
  ) {
    return true
  }
  return false
}

export const loading = (state = false, action) => isLoading(action.type)
