import moment from 'moment'
import { isEmpty } from './crud'

// GENERAL

// Regular Expressions
const regexEmail = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i
const regexURL = /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i
const regexTag = /^([0-9]){10,13}$/
const regexPhone = /^(\d+-?)+\d+$/

// Password of 8 characters, an uppercase char, a lower case one,
// numbers and  non-aplha numeric character
export const passwordRegex = /^(?:(?=.*[a-z])(?:(?=.*[A-Z])(?=.*[\d\W])|(?=.*\W)(?=.*\d))|(?=.*\W)(?=.*[A-Z])(?=.*\d)).{8,}$/

export const required = value => (!isEmpty(value) ? undefined : 'Campo requerido')
export const substring = (min, max) => value => value.substring(min, max)

export const minLength = (minimum = 8) => value =>
  value && value.length < minimum ? `El mínimo de caracteres es de ${minimum}` : undefined

export const maxLength = (maximum = 35) => value =>
  value && value.length > maximum ? `El máximo de caracteres es de ${maximum}` : undefined

export const isEmail = value =>
  regexEmail.test(value) ? undefined : 'Correo electrónico inválido'

export const isPhone = value => (regexPhone.test(value) ? undefined : 'Teléfono inválido')

export const isURL = value =>
  regexURL.test(value) ? undefined : "URL no válida (no olvides agregar 'http://' al comienzo)"

export const isTag = (tags = []) => {
  return tags.every(item => {
    const isMatch = regexTag.test(item)
    return isMatch
  })
    ? undefined
    : 'ISBN no válido - Número de caracteres de 10 a 13'
}

export const isMatch = (compare, label) => (value, values) => {
  return value === values[compare] ? undefined : `${label} no coinciden`
}

export const validPassword = value => {
  return value && passwordRegex.test(value) ? undefined : 'Contraseña poco segura'
}

export const dateIsGreatherOrEqual = (field, message) => (value, values) =>
  moment(value).isSameOrAfter(values[field]) ? undefined : message

// NUMERIC

export const isBetween = (min, max, message) => value =>
  min <= value && value <= max ? undefined : message

export const isGreaterThan = (field, message) => (value, values) =>
  Number(value) > Number(values[field]) ? undefined : message

export const isGreaterOrEqualThan = (field, message) => (value, values) =>
  Number(value) >= Number(values[field]) ? undefined : message

export const isLessThan = (maximum = 18, message) => value =>
  Number(value) <= Number(maximum) ? undefined : message

export const minimumValueIs = (minimum = 4, message) => value =>
  Number(value) >= Number(minimum) ? undefined : message

export const isGreaterHour = (field, message) => (value, values) =>
  moment(value, 'HH:mm').isAfter(moment(values[field], 'HH:mm')) ? undefined : message

export const trim = value => value && value.trim()
