import React from 'react'
import { Button } from 'reactstrap'
import { connect } from 'react-redux'
import Img from 'react-cool-img'
import { Form, reduxForm, reset } from 'redux-form'
import { RFInput } from 'components/Inputs/RFFields'
import { campaignActions } from 'modules/Campaign/campaign.redux'
import { useRouteMatch } from 'react-router-dom'
import { required, maxLength, substring } from 'util/form'

const maxLength280 = maxLength(280)
const limit280 = substring(0, 280)

function CommentBox({ handleSubmit, createComment, submitting, pristine, valid, ...props }) {
  const match = useRouteMatch()
  const campaign = match.params.id

  const onSubmit = ({ comment }) => {
    createComment({ comment, campaign })
    props.reset()
  }

  return (
    <div className='comment-form-wrap pt-3'>
      <Form onSubmit={handleSubmit(onSubmit)} className='p-5 bg-light'>
        <UserCardConnected />
        <div className='form-group mt-2'>
          <RFInput
            label='Comentario'
            name='comment'
            type='textarea'
            validate={[required, maxLength280]}
            normalize={limit280}
          />
        </div>
        <div className='form-group'>
          <Button color='primary' block disabled={submitting || pristine || !valid}>
            Publicar comentario
          </Button>
        </div>
      </Form>
    </div>
  )
}

const UserCard = ({ auth }) => (
  <div>
    <Img className='mr-3' src={auth.user.picture} width='30' height='30' alt='' />
    <span>
      {auth.user.name} {auth.user.lastName}
    </span>
  </div>
)

const authConnected = connect(({ auth }) => ({ auth }))
const UserCardConnected = authConnected(UserCard)

export default connect(null, {
  reset,
  createComment: campaignActions.createCampaignCommentRequest
})(reduxForm({ form: 'login', enableReinitialize: true })(CommentBox))
