import React from 'react'
import { connect } from 'react-redux'
import { reduxForm } from 'redux-form'
import { Form, Button, Row, Col } from 'reactstrap'
import { withForm } from 'util/withForm'
import { required, substring, minimumValueIs, isLessThan } from 'util/form'
import FormContainer from 'components/Container/FormContainer'
import { RFInput, RFUploader, RFSelect } from 'components/Inputs/RFFields'

const limit40 = substring(0, 40)
const limit100 = substring(0, 100)
const minDonations = minimumValueIs(0, 'Los números negativos no son válidos')
const maxDonations = isLessThan(10000000, 'Ha sobrepasado el máximo de donaciones')
const minDays = minimumValueIs(1, 'El mínimo de días es 1')
const maxDays = isLessThan(200, 'El máximo de días es 200')

function CampaignInformationForm({ button, handleSubmit, options, config, ...props }) {
  const formatOptions = key => option => ({ value: option._id, label: option[key] })
  const canDonate = config.providers.conekta || config.providers.paypal
  const institutionOptions = [{ _id: null, name: 'N/A' }, ...options.institutions]

  return (
    <FormContainer {...props}>
      <Form>
        <RFInput name='title' label='Título' validate={[required]} normalize={limit40} />
        <RFInput
          name='description'
          type='textarea'
          label='Descripción'
          rows='2'
          validate={[required]}
          normalize={limit100}
        />
        {canDonate && (
          <RFInput
            type='number'
            name='goal'
            label='Meta de donaciones (MXN)'
            validate={[required, minDonations, maxDonations]}
            min='0'
          />
        )}
        <RFUploader name='thumbnail' label='Imagen' validate={[required]} />
        <Row>
          <Col md='6'>
            <RFSelect
              name='category'
              label='Categoría'
              options={options.categories}
              validate={[required]}
              formatter={formatOptions('title')}
              isMulti
            />
          </Col>
          <Col md='6'>
            <RFSelect
              name='institution'
              label='Institución'
              options={institutionOptions}
              formatter={formatOptions('name')}
            />
          </Col>
        </Row>
        <Row>
          <Col md='6'>
            <RFInput
              name='duration'
              type='number'
              label='Duración en días'
              min='1'
              validate={[required, minDays, maxDays]}
            />
          </Col>
          <Col md='6'>
            <RFInput
              name='date'
              type='date'
              label='Fecha de publicación'
              validate={[required]}
            />
          </Col>
        </Row>
      </Form>
      <Button color='primary' className='float-right' onClick={handleSubmit}>
        Siguiente
      </Button>
    </FormContainer>
  )
}

const mapStateToProps = ({ campaign, configuration }) => ({
  options: campaign.options,
  config: configuration.public
})
const withConnect = connect(mapStateToProps)

export default withConnect(
  withForm(
    reduxForm({
      form: 'campaign',
      destroyOnUnmount: false,
      forceUnregisterOnUnmount: true
    })(CampaignInformationForm)
  )
)
