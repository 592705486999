import { createActions, createReducer } from 'reduxsauce'
import * as handlers from './../../redux/helpers/handlers'

const { Types, Creators } = createActions(
  {
    loadConfigurationRequest: ['payload'],
    loadConfigurationSuccess: ['payload'],
    loadConfigurationFailure: ['payload'],

    publicLoadConfigurationRequest: ['payload'],
    publicLoadConfigurationSuccess: ['payload'],
    publicLoadConfigurationFailure: ['payload'],

    editConfigurationRequest: ['payload'],
    editConfigurationSuccess: ['payload'],
    editConfigurationFailure: ['payload'],

    createConfigurationRequest: ['payload'],
    createConfigurationSuccess: ['payload'],
    createConfigurationFailure: ['payload'],

    updateConfigurationRequest: ['payload'],
    updateConfigurationSuccess: ['payload'],
    updateConfigurationFailure: ['payload']
  },
  {}
)

const initialState = {
  list: [],
  view: {},
  edit: {},
  public: {
    configured: null,
    settings: {
      name: '',
      logo: null
    },
    home: {
      showCounters: true,
      content: null,
      image: null
    },
    clients: {
      paypal: null,
      conekta: null
    },
    contact: {
      email: '',
      address: '',
      phone: ''
    },
    providers: {
      google: false,
      facebook: false,
      twitter: false,
      conekta: false,
      paypal: false
    },
    urls: {
      facebook: null,
      twitter: null,
      instagram: null
    }
  }
}

export const HANDLERS = {
  [Types.LOAD_CONFIGURATION_SUCCESS]: handlers.loadSuccess,
  [Types.EDIT_CONFIGURATION_SUCCESS]: handlers.editSuccess,
  [Types.UPDATE_CONFIGURATION_SUCCESS]: handlers.updateSuccess,
  [Types.PUBLIC_LOAD_CONFIGURATION_SUCCESS]: function (state, action) {
    return { ...state, public: action.payload }
  }
}

export const configurationTypes = Types
export const configurationActions = Creators
export const configuration = createReducer(initialState, HANDLERS)
